import React from "react";
import 'react-toastify/dist/ReactToastify.css'; // import first
import Comming from "../../components/customCore/comingZoon";
import Layout from "../../components/layout/Navbar";

function ExtendSearch() {
    return (

        <Layout title="Extend Search">
            <Comming />
        </Layout >

    )


}
export default ExtendSearch;
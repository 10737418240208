import React, { useState, useEffect } from "react";
import axios from "../../axios";
import "./AddRider.css";
import CloseIcon from "@mui/icons-material/Close";

import FileUploader from "../../components/buttons/FileUploader";
import { useToasts } from "react-toast-notifications";
import { Avatar } from "@mui/material";

function RiderImage({ editImageId, closeModel }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();
  const [UserPic, setUserPic] = useState("");

  const handleUpdateApi = () => {
    const data = new FormData();
    data.append("image", UserPic);
    axios
      .put("/rider/" + editImageId.id, data)
      .then((result) => {
        addToast("Successfully Edited", {
          appearance: "success",
          // autoDismiss: "true",
          // autoDismissTimeout: 2000,
        });
        window.location.href = "/rider";
      })
      .catch((error) => {
        addToast("Something Went Wrong", {
          appearance: "error",
          autoDismiss: "true",
          autoDismissTimeout: 2000,
        });
      });
  };

  //   console.log(editImageId.image, axios.defaults.baseURL)

  const Auth = localStorage.getItem("userInfor");
  const message = JSON.parse(Auth);
  const AdminId = message.message.id;

  useEffect(() => {
    axios
      .get(`getEmployeeRolesByAdminid/${AdminId}`)
      .then((res) => {
        setData(res.data);
        setLoading(true);
      })
      .catch((error) => {
        setLoading(true);
      });
  }, []);

  function handleUpload(event) {
    setUserPic(event.target.files[0]);
  }

  return (
    <div className="card-background1">
      <div className="Addrider-card1">
        <div className="card-head" style={{ display: "block" }}>
          <div className="icon-div" onClick={() => closeModel(false)}>
            <CloseIcon style={{ color: "#501ABF" }} />
          </div>
        </div>
        <div className="d-flex mt-4" style={{ marginLeft: "-100px" }}>
          <img
            style={{
              width: "80px",
              height: "80px",
              textTransform: "capitalize",
              textAlign: "center",
              margin: "0 auto",
            }}
            src={axios.defaults.baseURL + "/images/" + editImageId.image}
            alt="user"
          />
          <div className="mt-4">
            <div className="upload-pic1">
              <FileUploader
                onChange={handleUpload}
                placeholder={UserPic.name || "Edit"}
                type="file"
                id="upload"
                className="uploadbtn1"
              />
            </div>
          </div>
        </div>
        <button
          style={{
            boxSizing: "border-box",
            width: "80%",
            height: "40px",
            borderRadius: "35px",
            marginTop: "30px",
            marginLeft:"20px",
            backgroundColor: "#501ABF",
            color: "#ffffff",
            fontSize: "16px",
            border:"none"
          }}
          onClick={handleUpdateApi}
        >
          Update
        </button>
      </div>
    </div>
  );
}

export default RiderImage;

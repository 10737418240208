import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import axios from "../../../axios";

import { Box, CircularProgress, LinearProgress } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import ReactPaginate from "react-paginate";
import FilterListIcon from "@mui/icons-material/FilterList";
import moment from "moment";
import Datapicker from "../../../components/customCore/datapicker";
import "./dailyReport.css";
import * as XLSX from "xlsx";

const useStyles = makeStyles((theme) => ({
  Avatar: {
    cursor: "pointer",
  },
  Progress: {
    margin: "9px",
    width: "24px !important",
    height: "24px !important",
  },
  update: {
    backgroundColor: "#32c232",
    padding: "6px",
    borderRadius: "12px",
    color: "#fff",
    width: "120px",
  },

  notupdate: {
    backgroundColor: "#e21717",
    padding: "6px",
    borderRadius: "12px",
    color: "#fff",
    width: "120px",
  },

  formMain: {
    display: "flex",
    justifyContent: "space-around",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  select1: {
    minWidth: "300px",
    margin: "12px",
  },
  subDiv: {
    display: "flex",
    maxWidth: "800px",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      width: "300px",
      margin: "0 auto",
    },
  },
  label: {
    paddingLeft: "40px",
    fontSize: "16px",
    color: "#00000A",
    opacity: "0.6",
    fontWeight: 600,
    paddingBottom: "4px",
  },
  filter: {
    width: "140px",
    margin: "12px",

    border: "1px solid #ccc",
    height: "45px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "16px",
    fontFamily: "Poppins",
    borderRadius: "50px",
    marginTop: "35px",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      marginTop: "100px",
    },
    backgroundColor: "#501abf",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#fff",
      color: "#501abf",
      border: "2px solid #501abf",
      fontWeight: "600",
      "& span": {
        color: "#501abf !important",
      },
    },
    "& span": {
      color: "#fff !important",
    },
  },
}));
function RiderDailyReport() {
  const classes = useStyles();

  const [Category, setCategory] = React.useState([]);
  const [UserSelectCategory, setUserSelectCategory] = React.useState("");
  const [IfVacant, setIfVacant] = React.useState(false);
  const [Option, setOption] = useState("");
  const [Disabled, setDisabled] = React.useState(false);
  const [filterByJobnameAndCategorydata, setfilterByJobnameAndCategoryData] =
    useState([]);

  // paggnination
  const itemsPerPage = 10;

  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const [Loading, setLoading] = useState(false);
  const [fromDate, setfromDate] = React.useState(new Date());
  const [toDate, setToDate] = React.useState(new Date());

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(
      filterByJobnameAndCategorydata.slice(itemOffset, endOffset)
    );
    setPageCount(
      Math.ceil(filterByJobnameAndCategorydata.length / itemsPerPage)
    );
  }, [itemOffset, itemsPerPage, filterByJobnameAndCategorydata]);

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % filterByJobnameAndCategorydata.length;
    setItemOffset(newOffset);
  };
  const handleFiltering = () => {
    setLoading(false);
    setfilterByJobnameAndCategoryData([]);
    setDisabled(true);
    const Auth = localStorage.getItem("userInfor");
    const message = JSON.parse(Auth);
    const AdminId = message.message.id;

    const Fromdate = moment(fromDate).format("YYYY-MM-DD");
    const Todate = moment(toDate).format("YYYY-MM-DD");

    axios
      .get(`/riderDailyAverageReport/${AdminId}/${Fromdate}/${Todate}`)
      .then((res) => {
        setDisabled(false);
        setfilterByJobnameAndCategoryData(res.data[0]);
        setLoading(true);
      })
      .catch((err) => {
        setCategory([]);
        setLoading(true);
      });
  };

  const handleExcel = () => {
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(filterByJobnameAndCategorydata);

    var today = new Date();
    var todate = today.toLocaleDateString() + " " + today.toLocaleTimeString();
    var fileName = "Rider_Daily_Report " + todate + ".xlsx";

    XLSX.utils.book_append_sheet(wb, ws, "Rider_Daily_Report");
    XLSX.writeFile(wb, fileName);
  };

  return (
    <div>
      <div>
        <div>Rider Daily Average Report</div>
      </div>
      <br />
      <div className={classes.subDiv}>
        <div className={classes.select1}>
          <Datapicker
            name="fromDate"
            value={fromDate}
            onChange={(e) => setfromDate(e)}
            required
          />
        </div>
        <div className={classes.select1}>
          <Datapicker
            name="toDate"
            value={toDate}
            onChange={(e) => setToDate(e)}
            required
          />
        </div>
        <button
          type="submit"
          onClick={handleFiltering}
          className={classes.filter}
          disabled={Disabled}
        >
          {Disabled ? (
            <CircularProgress className={classes.Progress} />
          ) : (
            <div>
              <FilterListIcon /> Filter
            </div>
          )}
        </button>
      </div>
      <div
        style={{
          overflowX: "auto",
          marginTop: "2rem",
        }}
        className="table-telecaller"
      >
        <button className="excel mb-3" onClick={handleExcel}>
          Excel
        </button>
        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            borderSpacing: 0,
          }}
        >
          <tbody>
            <tr>
              <th>
                <span variant="body">Date</span>
              </th>
              <th>
                <span variant="body">Start Time</span>
              </th>
              <th>
                <span variant="body">End Time</span>
              </th>
              <th>
                <span variant="body">Duration</span>
              </th>
              <th>
                <span variant="body">Full Name</span>
              </th>
              <th>
                <span variant="body">Bill</span>
              </th>
              <th>
                <span variant="body">Job Type</span>
              </th>
            </tr>
          </tbody>
          <tbody>
            {!Loading && Disabled && (
              <tr
                style={{
                  borderCollapse: "collapse",
                  padding: "0px !important",
                }}
              >
                <td
                  style={{
                    textAlign: "center",
                    verticalAlign: "middle",
                    padding: "0px !important",
                  }}
                  colSpan="12"
                >
                  <Box sx={{ width: "100%" }}>
                    <LinearProgress />
                  </Box>
                </td>
              </tr>
            )}
          </tbody>
          <tbody>
            {currentItems &&
              currentItems.length === 0 &&
              !(!Loading && Disabled) && (
                <tr
                  style={{
                    borderCollapse: "collapse",
                    padding: "0px !important",
                  }}
                >
                  <td
                    style={{
                      textAlign: "center",
                      verticalAlign: "middle",
                      padding: "0px !important",
                    }}
                    colSpan="12"
                  >
                    <Box sx={{ width: "100%" }}>No Data</Box>
                  </td>
                </tr>
              )}
          </tbody>
          <tbody>
            {currentItems &&
              currentItems.length > 0 &&
              currentItems.map((data, i) => (
                <tr key={i}>
                  <td
                    style={{
                      textAlign: "left !important",
                      paddingLeft: "10px !important",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        textAlign: "left",
                        paddingLeft: "20px",
                      }}
                      variant="body"
                    >
                      {moment(data.Date).format("DD/MM/YYYY")}
                    </div>
                  </td>
                  <td
                    style={{
                      textAlign: "left !important",
                      paddingLeft: "10px !important",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        textAlign: "left",
                        paddingLeft: "20px",
                      }}
                      variant="body"
                    >
                      {moment(data.StartTime).format("HH:mm")}
                    </div>
                  </td>
                  <td>
                    <div
                      style={{
                        width: "100%",
                        textAlign: "left",
                        paddingLeft: "20px",
                      }}
                      variant="body"
                    >
                      {moment(data.EndTime).format("HH:mm")}
                    </div>
                  </td>
                  <td
                    style={{
                      textAlign: "left !important",
                      paddingLeft: "10px !important",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        textAlign: "left",
                        paddingLeft: "20px",
                      }}
                      variant="body"
                    >
                      {data.Duration}
                    </div>
                  </td>
                  <td
                    style={{
                      textAlign: "left !important",
                      paddingLeft: "10px !important",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        textAlign: "left",
                        paddingLeft: "20px",
                      }}
                      variant="body"
                    >
                      {data.FullName}
                    </div>
                  </td>
                  <td
                    style={{
                      textAlign: "left !important",
                      paddingLeft: "10px !important",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        textAlign: "left",
                        paddingLeft: "20px",
                      }}
                      variant="body"
                    >
                      {data.Bill}
                    </div>
                  </td>
                  <td
                    style={{
                      textAlign: "left !important",
                      paddingLeft: "10px !important",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        textAlign: "left",
                        paddingLeft: "20px",
                      }}
                      variant="body"
                    >
                      {data.JobType}
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <br />
        <ReactPaginate
          breakLabel="..."
          nextLabel="next >"
          onPageChange={handlePageClick}
          pageCount={pageCount}
          previousLabel="< previous"
          renderOnZeroPageCount={null}
          pageRangeDisplayed={3}
          marginPagesDisplayed={3}
          containerClassName={"pagination justify-content-end"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          activeClassName={"active"}
        />
      </div>
    </div>
  );
}
export default RiderDailyReport;

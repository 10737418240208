import React, { useState, useEffect } from "react";
import axios from "../../axios";
import "./login.css";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { CircularProgress } from "@mui/material";

function EmployeeLogin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [Disabled, setDisabled] = useState(false);
  //   const [data, setData] = useState([]);
  //   const [loading, setLoading] = useState(false)
  // add toster hook
  const { addToast } = useToasts();

  //   const Auth = localStorage.getItem("userInfor");
  //   const message = JSON.parse(Auth);
  //   const AdminId = message.message.id;
  //   useEffect(() => {
  //     axios
  //       .get("/rider/getByAdminId/" + AdminId)
  //       .then((res) => {
  //         setData(res.data);
  //         console.log(res.data)
  //         setLoading(true);
  //       })
  //       .catch((error) => {
  //         setLoading(true);
  //       });
  //   }, []);

  //save user in local
  const saveTokenLocalStorage = (tokenDetails) => {
    localStorage.setItem("userInfor", JSON.stringify(tokenDetails));
    localStorage.setItem("auth", JSON.stringify(tokenDetails));
  };

  //====================
  const handleEmail = (e) => {
    setEmail(e.target.value);
  };
  const handlePassword = (e) => {
    setPassword(e.target.value);
  };
  const handleApi = (e) => {
    setDisabled(true);
    e.preventDefault();

    const json = {
      email: email,
      password: password,
    };
    axios
      .post("rider/login", json)
      .then((result) => {
        if (result.data.message.position == "Field Visit Staff") {
          addToast("You have not access", {
            appearance: "error",
            autoDismiss: "true",
            autoDismissTimeout: 2000,
          });
          setDisabled(false);
        } else {
          saveTokenLocalStorage(result.data);
          addToast("Successfully Login", {
            appearance: "success",
            autoDismiss: "true",
            autoDismissTimeout: 2000,
          });
          setTimeout(() => {
            window.location = "/dashboard";
          }, 1000);
        }
      })
      .catch((error) => {
        addToast("username or password is incorrcet", {
          appearance: "error",
          autoDismiss: "true",
          autoDismissTimeout: 2000,
        });
        setDisabled(false);

        // setEmail("");
        // setPassword("");
      });
  };

  return (
    <div className="login-card">
      <h1>Employee Login</h1>
      <form onSubmit={handleApi} className="frm">
        <input
          type="email"
          className="input"
          placeholder="Email"
          value={email}
          onChange={handleEmail}
          required
        />
        <input
          type="password"
          className="input"
          placeholder="Password"
          value={password}
          onChange={handlePassword}
          required
        />
        <button className="login-submit" type="submit" disabled={Disabled}>
          {Disabled ? (
            <CircularProgress className="login-submit-loader" />
          ) : (
            "Login"
          )}
        </button>
      </form>
      <div className="reset">
        <Link to="/login" className="reset-a">
          Click Here for Admin Login
        </Link>
      </div>
    </div>
  );
}
export default EmployeeLogin;

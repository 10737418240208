import { Tooltip } from "@mui/material";
import React from "react";
export default function FileUploader(props) {
  return (
    <div
      style={{ position: "relative", height: "50px", display: "block ruby" }}
    >
      <label htmlFor={props.id}>
        <div
          className="uploadbtn"
          style={{
            border: "2px solid #501ABF",
            opacity: "0.5",
            display: "flex",
            alignItems: "center",
            borderRadius: "44px",
            justifyContent: "center",
            color: "#501ABF",
            overflow: "hidden",
            padding: "2px",
          }}
          variant="contained"
          component="span"
        >
          <Tooltip title={props.placeholder} sx={{ margin: "0px" }} arrow>
            <div> {props.placeholder}</div>
          </Tooltip>
        </div>
      </label>
      <input
        style={{
          display: "none",
          background: "red",
          height: "50px",
          paddingTop: "22px",
          position: "absolute",
          left: 0,
          opacity: 0,
          top: "0px",
          width: "157px",
        }}
        accept={props.accept}
        id={props.id}
        multiple
        type="file"
        onChange={props.onChange}
        name={props.name}
        value={props.value}
      />
    </div>
  );
}

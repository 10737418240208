import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css"; // import first
import { useToasts } from "react-toast-notifications";
import axios from "../../axios";
// import "./importManger.css"
import Layout from "../../components/layout/Navbar";
import moment from "moment";
import { makeStyles } from "@material-ui/core";
import { CircularProgress, Switch } from "@mui/material";
import Datapicker from "../../components/customCore/datapicker";

const useStyles = makeStyles((theme) => ({
  Progress: {
    // margin: "9px",
    width: "24px !important",
    height: "24px !important",
  },
  formMain: {
    display: "flex",
    justifyContent: "center",

    // backgroundColor: "red",
    [theme.breakpoints.down("md")]: {
      // backgroundColor: "green",
      // flexDirection: "column",
    },
  },
  formMainLeft: {
    // display: "flex",
    // justifyContent: "space-between",
    maxWidth: "500px",
    width: "100%",
    // backgroundColor: "red",
    [theme.breakpoints.up("lg")]: {
      // backgroundColor: "green",
      // flexDirection: "column"
      maxWidth: "350px",
      width: "100%",
    },
  },
  label: {
    paddingLeft: "20px",
    fontSize: "16px",
    color: "#00000A",
    opacity: "0.6",
    fontWeight: 600,
    paddingBottom: "4px",
  },
  lnds: {
    // display: "flex"
    maxWidth: "500px",
  },
  btn: {
    height: "45px",
    marginTop: "35px",
    background: "#5016BF 0% 0 % no - repeat padding- box",
    backgroundColor: "#5016BF",
    borderRadius: "44px",
    font: "normal 600 30px Poppins",
    color: "#FFFFFF",
    fontWeight: "500 !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "2px solid #501abf !important",
    outline: "none",
    fontSize: "16px !important",
    width: "300px  !important",
    "&:hover": {
      color: "#501abf !important",
      fontWeight: "600 !important",

      "& svg": {
        color: "#501abf !important",
      },
      background: "#fff !important",
    },
    "& svg": {
      color: "#fff !important",
    },
  },
}));
function WaterMark() {
  const { addToast } = useToasts();
  const classes = useStyles();

  const [checked, setChecked] = React.useState(true);

  const handleChange = (event) => {
    setChecked(event.target.checked);
    handleSubmit();
  };

  //upload file
  const [RiderId, setRiderId] = React.useState("");
  const [RiderData, setRiderData] = useState([]);
  const [LeaveTypeName, setLeaveTypeName] = useState("");
  const [Disabled, setDisabled] = React.useState(false);
  const [fromDate, setfromDate] = React.useState(new Date());

  useEffect(() => {
    setDisabled(true);
    axios
      .get("/rider")
      .then((res) => {
        setRiderData(res.data);
        setDisabled(false);
      })
      .catch((err) => {
        setDisabled(false);
      });
  }, []);

  // Handles file upload event and updates state

  //end-upload file

  let handleSubmit = (event) => {
    setDisabled(true);
    event.preventDefault();
    // alert(JSON.stringify(formValues));
    const AdminId = JSON.parse(localStorage.getItem("auth")).message.id || "";

    const jsonData = {
      checked: checked,
      riderId: RiderId,
      adminId: AdminId,
    };
    axios
      // detailIndex
      .post(`/attendances`, jsonData, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        addToast("Leave add Successfully", {
          appearance: "success",
          autoDismiss: "true",
          autoDismissTimeout: 2000,
        });
        setDisabled(false);
      })
      .catch((error) => {
        addToast("Please select correct feild", {
          appearance: "error",
          autoDismiss: "true",
          autoDismissTimeout: 2000,
        });
        setDisabled(false);
      });
  };

  return (
    <div>
      {/* ========== form ==========*/}
      <div className="newFile2  form">
        <div className="rider-info">Water Mark</div>
        <form onSubmit={handleSubmit}>
          <Switch
            checked={checked}
            onChange={handleChange}
            inputProps={{ "aria-label": "controlled" }}
          />
          {/* <div style={{ display: "flex", justifyContent: "center" }}>
            <button type="submit" className={classes.btn} disabled={Disabled}>
              {" "}
              {Disabled ? (
                <CircularProgress className={classes.Progress} />
              ) : (
                "Submit"
              )}
            </button> */}
          {/* </div> */}
        </form>
      </div>
      {/* ========== form ==========*/}
    </div>
  );
}
export default WaterMark;

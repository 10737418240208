import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { createStyles } from "@material-ui/core";
import { BorderLinearProgress } from "../../components/customCore/LinearProgress";
import axios from "../../axios";
import { Skeleton } from "@mui/material";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      Main: {
        marginRight: "4px",
        width: "60%",
        minWidth: "300px",
      },
      TotalRecordDiv: {
        padding: "20px",
        background: "#FEFEFE 0% 0% no-repeat padding-box",
        boxShadow: "0px 10px 20px #4A4A4A66",
        borderRadius: "20px",
        marginBottom: "1rem",
      },
      "@media (max-width: 1050px)": {
        TotalRecordDiv: {
          width: "95%",
        },
      },
      "@media (max-width: 600px)": {
        TotalRecordDiv: {
          width: "80%",
        },
      },
      TotalRecordDivHeading: {
        fontSize: "16px",
        margin: "0px",
        color: "black",
      },
      TotalRecordDivHeadingNormal: {
        color: "#5016BF",
      },
      TotalRecordDivNo: {
        color: "#000000",
        fontSize: "24px",
        margin: "0px",
        letterSpacing: "1px",
        fontWeight: 600,
      },
      TotalRecordTable: {
        width: "100%",
        margin: "0.2rem 0rem",
        display: "flex",
        justifyContent: "space-between",
        [theme.breakpoints.down("xs")]: {
          flexDirection: "column",
          marginTop: "1rem",
        },
      },
      TotalRecordTableTr: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        maxWidth: "170px",
        width: "100%",

        "& p": {
          margin: "4px",
        },
      },
      TotalRecordTableTrText: {
        color: "#5016BF",
      },

      TotalRecordTableTrNo: {
        color: "#000000",
        fontWeight: "600",
        letterSpacing: "1px",
        marginTop: "0.5rem",
      },
      TotalRecordProgressText: {
        color: "#00000",
        fontWeight: "600",
        textAlign: "right",
        fontFamily: "Poppins",
      },
    }),
  { withTheme: true }
);

export default function TotalRecord() {
  const classes = useStyles();
  const [AllTotal, setAllTotal] = useState(0);
  const [AllComplete, setAllComplete] = useState(0);
  const [Loading, setLoading] = useState(false);

  const Auth = localStorage.getItem("userInfor");
  const message = JSON.parse(Auth);
  const AdminId = message.message.id;

  useEffect(() => {
    setTimeout(() => {
      if (AdminId) {
        axios
          .get("/getDashboardDetails/" + AdminId)
          .then((res) => {
            let total = 0;
            let complete = 0;
            res.data[1].map((item) => {
              total = total + item.total;
              complete = complete + item.completed;
            });
            setAllTotal(total);
            setAllComplete(complete);
            setLoading(true);
          })
          .catch((error) => {
            setLoading(true);
          });
      }
    }, 1000);
  }, [AdminId]);
  return (
    <div className={classes.Main}>
      <div className={classes.TotalRecordDiv}>
        <div className={classes.TotalRecordDivHeadingNormal}>
          Total records &nbsp;
          <span className={classes.TotalRecordDivHeading}>- current jobs</span>
        </div>
        {!Loading ? (
          <div style={{ width: 20, height: 20 }}>
            <Skeleton animation="wave" height={34} />
          </div>
        ) : (
          <p className={classes.TotalRecordDivNo}>
            {Number(AllTotal).toLocaleString()}
          </p>
        )}
        <div className={classes.TotalRecordProgressText}>
          <br />
          {Loading
            ? parseFloat(
                (AllComplete / (AllTotal === 0 ? 1 : AllTotal)) * 100
              ).toFixed(2) + "%"
            : ""}
          completed
        </div>
        {!Loading ? (
          <div style={{ width: "100%" }}>
            <Skeleton animation="wave" />
          </div>
        ) : (
          <BorderLinearProgress
            variant="determinate"
            value={parseFloat(
              (AllComplete / (AllTotal === 0 ? 1 : AllTotal)) * 100
            )}
          />
        )}
        <div className={classes.TotalRecordTable}>
          <div className={classes.TotalRecordTableTr}>
            <p className={classes.TotalRecordTableTrText}>Completed</p>
            <p className={classes.TotalRecordTableTrNo}>{AllComplete}</p>
          </div>
          <div className={classes.TotalRecordTableTr}>
            <p className={classes.TotalRecordTableTrText}>pending</p>
            <p className={classes.TotalRecordTableTrNo}>
              {Number(AllTotal - AllComplete).toLocaleString()}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

import Layout from "../../components/layout/Navbar";
import { makeStyles } from "@material-ui/core";
import { createStyles } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import CsvDownloader from "react-csv-downloader";
import axios from "../../axios";
import CircularProgress from "@mui/material/CircularProgress";
import BasicSelect from "../../components/customCore/select";
import { categoryType, categoryTypeAll } from "../../api/detailsApi";
import BasicSelect2 from "../../components/customCore/jobNameDroupDown";
import ExcelSheetFooter from "./sample";
import moment from "moment";
import { useToasts } from "react-toast-notifications";
import {
  CommericalCsvDataHeader1,
  LndCsvDataHeader1,
} from "./landedExportArray";
import ExportAsExcelSheet from "./exportExcel";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      dataTable: {
        display: "flex",
        maxWidth: "500px",
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: "wrap",
      },
      dataTableMain: {
        paddingTop: "2rem",
        paddingLeft: "2rem",
        [theme.breakpoints.down("sm")]: {
          paddingLeft: "1rem",
          fontSize: "1rem !important",
        },
      },
      btn: {
        backgroundColor: "#5016BF",
        border: "1px solid #ccc",
        color: "#fff",
        borderRadius: "24px",
        width: "150px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "16px 32px",
      },

      subDiv: {
        display: "flex",
        justifyContent: "space-between",
        maxWidth: "930px",
        [theme.breakpoints.down("md")]: {
          flexDirection: "column",
          width: "300px",
          margin: "0 auto",
        },
      },
      select1: {
        minWidth: "300px",
        margin: "12px",
      },
      select2: {
        minWidth: "300px",
        margin: "12px",
        display: "flex",
      },
      filter: {
        width: "120px",
        margin: "12px",
        border: "1px solid #ccc",
        height: "45px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "16px",
        fontFamily: "Poppins",
        borderRadius: "50px",
        [theme.breakpoints.down("md")]: {
          width: "300px",
          flexDirection: "column",
        },
        backgroundColor: "#501abf",
        color: "#fff",
        "&:hover": {
          backgroundColor: "#fff",
          color: "#501abf",
          border: "2px solid #501abf",
          fontWeight: "600",
          "& span": {
            color: "#501abf !important",
          },
        },
        "& span": {
          color: "#fff !important",
        },
      },
      inputDiv: {
        position: "relative",
        width: "100%",
        marginTop: "17px",
        marginBottom: "17px",
      },
      InputDivInput: {
        width: "100%",
        height: "45px",
        border: "1px solid #ccc",
        borderRadius: "100px",
        paddingLeft: "22px",
        paddingRight: "22px",
        lineHeight: 1,
        boxSizing: "border-box",
        outline: "none",
        letterSpacing: "0px",
        color: " #afafaf",
        textAlign: "left",
        font: "normal 30px Poppins",
        fontSize: "14px !important",
        maxWidth: "100px",
        marginLeft: "12px",
      },
    }),
  { withTheme: true }
);

const LndCsvDataHeader = [
  {
    id: "id",
    displayName: "id",
  },
  {
    id: "Exclude",
    displayName: "Exclude",
  },
  {
    id: "IWKSpecialInstruction",
    displayName: "IWK's Special Instruction",
  },
  //
  {
    id: "DCAFiletype",
    displayName: "DCA File type",
  },
  {
    id: "DCAName",
    displayName: "DCA Name",
  },
  {
    id: "DCACode",
    displayName: "DCA Code",
  },
  {
    id: "State",
    displayName: "State",
  },
  {
    id: "Costcode",
    displayName: "Cost Code",
  },
  {
    id: "UO",
    displayName: "UO",
  },
  {
    id: "LAName",
    displayName: "LA Name",
  },
  {
    id: "billno",
    displayName: "bill no",
  },
  {
    id: "SAN",
    displayName: "SAN",
  },
  //checking
  {
    id: "Owner1",
    displayName: "Owner1",
  },
  {
    id: "Owner2",
    displayName: "Owner2",
  },

  {
    id: "status",
    displayName: "Property Status",
  },
  {
    id: "PropAddr1",
    displayName: "Prop Addr1",
  },
  {
    id: "PropAddr2",
    displayName: "Prop Addr2",
  },
  {
    id: "PropAddr3",
    displayName: "Prop Addr3",
  },
  {
    id: "PropAddr4",
    displayName: "Prop Addr4",
  },
  {
    id: "PropAddr4",
    displayName: "Prop Addr4",
  },

  {
    id: "PropAddr5",
    displayName: "Prop Addr5",
  },
  {
    id: "Building",
    displayName: "Building",
  },
  {
    id: "BuildingID",
    displayName: "Building ID",
  },

  {
    id: "Roadname",
    displayName: "Road Name",
  },
  {
    id: "Taman",
    displayName: "Taman",
  },
  {
    id: "PostCode",
    displayName: "Post Code",
  },
  {
    id: "Suburb",
    displayName: "Suburb",
  },
  {
    id: "MailName1",
    displayName: "Mail Name1",
  },
  {
    id: "MailName2",
    displayName: "Mail Name2",
  },
  {
    id: "MailAdd1Withoutcoma",
    displayName: "Mail Add1",
  },
  {
    id: "MailAdd2Withoutcoma",
    displayName: "Mail Add2",
  },
  {
    id: "MailAdd3Withoutcoma",
    displayName: "Mail Add3",
  },
  {
    id: "MailAdd4Withoutcoma",
    displayName: "Mail Add4",
  },
  // start again
  {
    id: "Class",
    displayName: "Class",
  },
  {
    id: "Range",
    displayName: "Range",
  },
  {
    id: "Arrears",
    displayName: "Arrears",
  },
  {
    id: "CurrentBalance",
    displayName: "Current Balance",
  },
  {
    id: "CurrentBalance1",
    displayName: "Balance as per copy bill",
  },
  {
    id: "Diff",
    displayName: "Diff between bal as per copy bill & bal@05/03/2022",
  },
  {
    id: "Owner1NRIC",
    displayName: "Owner1 NRIC",
  },
  {
    id: "Owner2NRIC",
    displayName: "Owner2 NRIC",
  },

  // xtzrt
  {
    id: "CustomerTelephoneNo",
    displayName: "Customer's Telephone No",
  },
  {
    id: "CustomerMobileNo",
    displayName: "Customer's Mobile No",
  },
  {
    id: "CustomerEmail",
    displayName: "Customer's Email No",
  },

  {
    id: "ICNOs",
    displayName: "ICNOs",
  },
  {
    id: "ArrPhone",
    displayName: "ArrPhone",
  },

  // dfdfdfdfdf
  {
    id: "OwnerTelephoneNo1",
    displayName: "Owner Telephone No",
  },
  {
    id: "OwnerMobileNo",
    displayName: "Owner Mobile No",
  },
  {
    id: "TenantTelephoneNo",
    displayName: "Tenant Telephone No",
  },
  {
    id: "TenantMobileNo",
    displayName: "Tenant Mobile No",
  },
  {
    id: "CustomerContactDetail",
    displayName: "Customer Contact Detail",
  },
  //////
  {
    id: "EbillName",
    displayName: "Ebill Name",
  },
  {
    id: "EbillICNo",
    displayName: "Ebill IC No",
  },
  {
    id: "EbillMobileNo",
    displayName: "Ebill Mobile No",
  },
  {
    id: "EbillLandlineNo",
    displayName: "Ebill Land Line No",
  },
  {
    id: "EbillEmail",
    displayName: "Ebill Email",
  },
  {
    id: "LA_Name",
    displayName: "LA_Name",
  },
  {
    id: "LA_New_IC",
    displayName: "LA_New_IC",
  },
  {
    id: "LA_Old_IC",
    displayName: "LA_Old_IC",
  },
  {
    id: "LA_Mobile",
    displayName: "LA_Mobile",
  },
  {
    id: "LA_Other_No",
    displayName: "LA_Other_No",
  },
  {
    id: "LA_Email_Add",
    displayName: "LA_Email_Add",
  },
  {
    id: "Water_Name",
    displayName: "Water_Name",
  },
  {
    id: "Water_New_IC",
    displayName: "Water_New_IC",
  },
  {
    id: "Water_Old_IC",
    displayName: "Water_Old_IC",
  },
  {
    id: "Water_Mobile",
    displayName: "Water_Mobile",
  },
  {
    id: "Water_Other_No1",
    displayName: "Water_Other_No1",
  },
  {
    id: "Water_Other_No2",
    displayName: "Water_Other_No2",
  },
  {
    id: "Water_Email_Add",
    displayName: "Water_Email_Add",
  },
  //////////////

  // teteterete
  // Ebilll 5 feild missing

  {
    id: "BATCH1",
    displayName: "1st visit-Date of Bill & Notice/LOD was served",
  },
  {
    id: "AgentName",
    displayName: "Agent's Name",
  },

  {
    id: "BATCH2",
    displayName: "F/UP-Date of Reminder Notice/LOD was served",
  },
  {
    id: "Occupier",
    displayName: "Occupier (Owner/Tenant)",
  },
  {
    id: "OwnernameCorrect",
    displayName: "Ownername correct (Yes/No)",
  },
  {
    id: "specifyCorrectOwnername",
    displayName: "Please specify correct ownername",
  },

  {
    id: "OwnerTelephoneNo",
    displayName: "Owner Telephone No",
  },
  {
    id: "OwnerMobileNo1",
    displayName: "Owner Mobile No",
  },
  {
    id: "Ownerfaxno",
    displayName: "Owner's fax no",
  },
  {
    id: "OwnerEmail",
    displayName: "Owner's email",
  },

  {
    id: "TenantName",
    displayName: "Tenant's name",
  },
  {
    id: "TenantTelephoneNo1",
    displayName: "Tenant's Telephone no",
  },
  {
    id: "TenantMobileNo1",
    displayName: "Tenant's mobile no",
  },
  {
    id: "TenantFaxNo",
    displayName: "Tenant's fax no",
  },
  {
    id: "TenantEmail",
    displayName: "Tenant's email",
  },

  {
    id: "OccupierNationality",
    displayName: "OccupierNationality",
  },
  {
    id: "numberOfVisit",
    displayName: "Number of visitation",
  },

  {
    id: "Numberoffollowupcalls",
    displayName: "Number of follow-up calls",
  },
  {
    id: "PropertyUsage",
    displayName: "Property Usage",
  },
  {
    id: "PropertyType",
    displayName: "Property Type",
  },
  {
    id: "NameofShopOrCompany",
    displayName: "Name of shop/company",
  },
  {
    id: "NatureOfBusiness",
    displayName: "Nature of business",
  },

  {
    id: "DRCode",
    displayName: "DRCode",
  },
  {
    id: "ReasonCustomerRefuseToPayIWKbill",
    displayName: "Reason customer refuse to pay IWK bill",
  },
  {
    id: "Remarks",
    displayName: "Remarks",
  },

  // rrrr

  {
    id: "ScanviaDCA",
    displayName: "Scan via DCA Tracking System (Y/N)",
  },
  {
    id: "ReasonifcopybillnotscanviaDCA",
    displayName: "Reason if copy bill not scan via DCA Tracking system",
  },

  {
    id: "ThirdPartySearch",
    displayName: "Third Party Search",
  },
  {
    id: "SourceofTPS",
    displayName: "Source of TPS",
  },
  {
    id: "TPSOutcomeOrContactNumber",
    displayName: "TPS Outcome/Contact Number",
  },
  {
    id: "RemarksTPS",
    displayName: "Remarks TPS",
  },
  {
    id: "meterImage",
    displayName: "Meter Image",
  },
  {
    id: "test",
    displayName: "image 01",
  },
  {
    id: "multiI2",
    displayName: "image 02",
  },
  {
    id: "multiI3",
    displayName: "image 03",
  },
  {
    id: "multiI4",
    displayName: "image 04",
  },
  {
    id: "multiI5",
    displayName: "image 05",
  },
];
const VacantsArrayOptions = [
  { id: "SEWACC", displayName: "SEWACC" },
  { id: "OWNER_NAME", displayName: "OWNER NAME" },
  { id: "PROP_ADD", displayName: "PROP ADD" },
  { id: "CURRENT_CLASS", displayName: "CURRENT CLASS" },

  { id: "OccupiedOrVacant", displayName: "OccupiedOrVacant" },
  { id: "OccupiedOrVacantOthers", displayName: "OccupiedOrVacantOthers" },
  { id: "CommercialOrDomestic", displayName: "CommercialOrDomestic" },
  {
    id: "CommercialOrDomesticOthers",
    displayName: "CommercialOrDomesticOthers",
  },
  { id: "MeterConnectedOrNot", displayName: "MeterConnectedOrNot" },
  { id: "PropertyAddress", displayName: "PropertyAddress" },
  { id: "CommentbyFO", displayName: "CommentbyFO" },
  { id: "image", displayName: "image" },
  { id: "WatermeterNumber", displayName: "WatermeterNumber" },
  { id: "jobType", displayName: "jobType" },
  { id: "numberOfVisit", displayName: "numberOfVisit" },
  { id: "updateStatus", displayName: "updateStatus" },
  { id: "updatedTime", displayName: "updatedTime" },
  { id: "riderId", displayName: "riderId" },
  { id: "multiImage", displayName: "multiImage" },
];

function Dashboard() {
  const { addToast } = useToasts();

  const style = useStyles();
  const [Loading, setLoading] = useState(false);

  const [LoadingAfterJoin, setLoadingAfterJoin] = useState(false);
  const [data, setData] = useState([]);
  // setUserSelectCategoryName
  const [UserSelectCategoryName, setUserSelectCategoryName] =
    React.useState("");
  const [UserSelectCategory, setUserSelectCategory] = React.useState("");
  const [Option, setOption] = useState("");
  const [To, setTo] = useState("");
  const [From, setFrom] = useState("");

  const [Disabled, setDisabled] = React.useState(false);
  const [Category, setCategory] = React.useState([]);
  const [AllAdminData, setAllAdminData] = React.useState([]);

  const [hideImage, setHideImage] = React.useState(false);
  const [hideLonLat, setHideLonLat] = React.useState(false);

  const AdminId = JSON.parse(localStorage.getItem("auth")).message.id || "";
  useEffect(() => {
    axios
      .get("/admindetails/getByAdminId/" + AdminId)
      .then((res) => {
        setAllAdminData(res.data);
        setLoading(true);
      })
      .catch((err) => {
        setLoading(true);
      });
  }, [AdminId]);

  useEffect(() => {
    setDisabled(true);
    if (Option) {
      axios
        .get(`/jobname/${Option}/${AdminId}`)
        .then((res) => {
          setDisabled(false);
          setCategory(res.data);
        })
        .catch((err) => {
          setDisabled(false);
          setCategory([]);
        });
    }
  }, [Option, AdminId]);

  const removeZ = (str) => {
    // var b = "e";
    // var removeLastChar = "";
    // removeLastChar = str.slice(0, str.length - 1);
    // return removeLastChar;
    const updatedAt = new Date(str);
    updatedAt.setMinutes(updatedAt.getMinutes());
    const formattedDate = `${updatedAt.getFullYear()}-${(
      updatedAt.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${updatedAt
      .getDate()
      .toString()
      .padStart(2, "0")} ${updatedAt.toLocaleTimeString()}`;
    return formattedDate;
  };
  const getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };

  const GetData = () => {
    if (Option != "" && UserSelectCategory != "" && To != "" && From != "") {
      if (Number(From) > Number(To)) {
        addToast("From is less than To", {
          appearance: "error",
          autoDismiss: "true",
          autoDismissTimeout: 2000,
        });
      } else {
        GetDatas();
      }
    } else {
      addToast("Please Select All Field", {
        appearance: "error",
        autoDismiss: "true",
        autoDismissTimeout: 2000,
      });
    }
  };
  const GetDatas = () => {
    // Disabled
    setLoading(false);
    setData([]);
    setDisabled(true);
    setLoadingAfterJoin(true);

    axios
      .get(
        `riderdata/filterByJobnameAndCategoryExport/${Option}/${UserSelectCategory}/${From}/${To}`
      )
      .then((res) => {
        console.log(res)
        setDisabled(false);
        setLoadingAfterJoin(false);
        var dataFilterImage = [];
        dataFilterImage = res.data;
        if (dataFilterImage.length > 0) {
          dataFilterImage.map((item, i) => {
            if (item.updatedTime) {
              let time = item.updatedTime
                ? moment(removeZ(item.updatedTime)).format(
                    "DD/MM/YYYY HH:mm:ss"
                  )
                : "";
              dataFilterImage[i]["updatedTimes"] = time;
            } else {
              dataFilterImage[i]["updatedTimes"] = "-----";
            }
            if (item.multiImage != null) {
              var multiImage = item.multiImage;
              multiImage = multiImage.split(",");

              multiImage.map((item, index) => {
                var ximagetest =
                  axios.defaults.baseURL +
                  "/" +
                  (Option === "vacants"
                    ? "vacant"
                    : Option === "lnds"
                    ? "lnd"
                    : Option === "commercials"
                    ? "Commercial"
                    : Option === "highrises"
                    ? "highrise"
                    : Option) +
                  "/" +
                  item;
                getBase64FromUrl(ximagetest).then((res) => {
                  dataFilterImage[i][`multiImages${index + 1}`] = res;
                });
                dataFilterImage[i][`multiI${index + 1}`] =
                  axios.defaults.baseURL +
                  "/" +
                  (Option === "vacants"
                    ? "vacant"
                    : Option === "lnds"
                    ? "lnd"
                    : Option === "commercials"
                    ? "Commercial"
                    : Option === "highrises"
                    ? "highrise"
                    : Option) +
                  "/" +
                  item;
              });
            }

            if (item.image) {
              let multiImg = item.image;
              multiImg = multiImg.split(",");

              multiImg.map((it, index) => {
                var ximagetest =
                  axios.defaults.baseURL +
                  "/" +
                  (Option === "vacants"
                    ? "vacant"
                    : Option === "lnds"
                    ? "lnd"
                    : Option === "commercials"
                    ? "Commercial"
                    : Option === "highrises"
                    ? "highrise"
                    : Option) +
                  "/" +
                  it;
                getBase64FromUrl(ximagetest).then((res) => {
                  dataFilterImage[i][`multiImgs${index + 1}`] = res;
                });
                dataFilterImage[i][`multiI${index + 1}`] =
                  axios.defaults.baseURL +
                  "/" +
                  (Option === "vacants"
                    ? "vacant"
                    : Option === "lnds"
                    ? "lnd"
                    : Option === "commercials"
                    ? "Commercial"
                    : Option === "highrises"
                    ? "highrise"
                    : Option) +
                  "/" +
                  item;
              });
            }
            if (item.meterImage) {
              let multiImg = item.meterImage;
              multiImg = multiImg.split(",");

              multiImg.map((item, index) => {
              var ximagetest =
              axios.defaults.baseURL +
              "/" +
              (Option === "vacants"
                ? "vacant"
                : Option === "lnds"
                ? "lnd"
                : Option === "commercials"
                ? "Commercial"
                : Option === "highrises"
                ? "highrise"
                : Option) +
              "/" +
              item;
              getBase64FromUrl(ximagetest).then((res) => {
                dataFilterImage[i][`meterImage`] = res;
              });
              dataFilterImage[i][`meterImage`] =
                axios.defaults.baseURL +
                "/" +
                (Option === "vacants"
                  ? "vacant"
                  : Option === "lnds"
                  ? "lnd"
                  : Option === "commercials"
                  ? "Commercial"
                  : Option === "highrises"
                  ? "highrise"
                  : Option) +
                "/" +
                item;
            })
            }
            // meterImage
            // OccupiedOrVacant
            if (item.OccupiedOrVacant === "DIDUDUKI (OCCUPIED)") {
              dataFilterImage[i]["OccupiedOnly"] = "√";
              dataFilterImage[i]["OccupiedOrVacant"] = "";
            } else if (item.OccupiedOrVacant == "KOSONG (VACANT )") {
              dataFilterImage[i]["VacantOnly"] = "√";
              dataFilterImage[i]["OccupiedOrVacant"] = "";
            } else if (item.OccupiedOrVacant == "OTHERS") {
              if (dataFilterImage[i]["OccupiedOrVacantOthers"]) {
                dataFilterImage[i]["OccupiedOrVacant"] =
                  item.OccupiedOrVacantOthers;
              } else {
                dataFilterImage[i]["OccupiedOrVacant"] = item.OccupiedOrVacant;
              }
            }
            // CommercialOrDomestic

            if (
              item.CommercialOrDomestic ===
              "PENGGUNAAN KOMERSIL (COMMERCIAL USAGE )"
            ) {
              dataFilterImage[i]["CommercialOnly"] = "√";
              dataFilterImage[i]["CommercialOrDomestic"] = "";
            } else if (
              item.CommercialOrDomestic ==
              "Penggunaan Domestik (DOMESTIC USAGE)"
            ) {
              dataFilterImage[i]["DomesticOnly"] = "√";
              dataFilterImage[i]["CommercialOrDomestic"] = "";
            } else if (item.CommercialOrDomestic == "OTHERS") {
              dataFilterImage[i]["CommercialOrDomestic"] =
                item.CommercialOrDomesticOthers;
            } else {
              dataFilterImage[i]["CommercialOrDomestic"] =
                item.CommercialOrDomestic;
            }

            // MeterConnectedOrNot
            if (
              item.MeterConnectedOrNot === "Ya - Bersambung (Yes - Connected)"
            ) {
              dataFilterImage[i]["MeterConnectedOrNotYes"] = "√";
              dataFilterImage[i]["MeterConnectedOrNot"] = "";
            } else if (
              item.MeterConnectedOrNot ===
              "Tidak - Terputus sambungan (No - Disconnected)"
            ) {
              dataFilterImage[i]["MeterConnectedOrNotNo"] = "√";
              dataFilterImage[i]["MeterConnectedOrNot"] = "";
            } else {
              dataFilterImage[i]["MeterConnectedOrNotNo"] = "";
              dataFilterImage[i]["MeterConnectedOrNot"] = "";
            }

            // PropertyAddress
            if (item.PropertyAddress) {
              dataFilterImage[i]["PropertyAddress"] = item.PropertyAddress;
            } else {
              dataFilterImage[i]["PropertyAddress"] = item.PropertyAddress;
            }

            if (item.updatedTime) {
              let time = item.updatedTime
                ? moment(removeZ(item.updatedTime)).format(
                    "DD/MM/YYYY HH:mm:ss"
                  )
                : "";
              dataFilterImage[i]["updatedTimes"] = time;
            } else {
              dataFilterImage[i]["updatedTimes"] = "-----";
            }

            // MailAdd1
            if (item.MailAdd1) {
              let x = item.MailAdd1;
              let y = x.replace(/,/g, " ");

              dataFilterImage[i]["MailAdd1Withoutcoma"] = y;
            }
            // MailAdd2
            if (item.MailAdd2) {
              let x = item.MailAdd2;
              let y = x.replace(/,/g, " ");

              dataFilterImage[i]["MailAdd2Withoutcoma"] = y;
            }
            // MailAdd3
            if (item.MailAdd3) {
              let x = item.MailAdd3;
              let y = x.replace(/,/g, " ");

              dataFilterImage[i]["MailAdd3Withoutcoma"] = y;
            }
            // MailAdd4
            if (item.MailAdd4) {
              let x = item.MailAdd4;
              let y = x.replace(/,/g, " ");

              dataFilterImage[i]["MailAdd4Withoutcoma"] = y;
            }
            // MailAdd5
            if (item.MailAdd5) {
              let x = item.MailAdd5;
              let y = x.replace(/,/g, " ");
              dataFilterImage[i]["MailAdd5Withoutcoma"] = y;
            }
            // fullName
            if (item.fullName) {
              dataFilterImage[i]["fullName"] = item.fullName;
            } else {
              dataFilterImage[i]["fullName"] = "-----";
            }
            //long and lat
            if(item.longitude && item.latitude){
              dataFilterImage[i]["longAndLat"] = item.latitude + " , " + item.longitude;
            }
          });
          setData(dataFilterImage);
          setLoading(true);
        }
      })
      .catch((err) => {
        setDisabled(false);
        setLoadingAfterJoin(false);

        setCategory([]);
        setLoading(true);
      });
  };
  const onSelectValue = (value, event) => {
    setOption(value);
  };
  const onSelectValue2 = (value, event) => {
    setUserSelectCategory(value);
  };
  const onSelectName = (value, event) => {
    setUserSelectCategoryName(value);
  };
  return (
    <Layout title="Export">
      <div>
        <div className="rider-info">Export Manager</div>
      </div>
      <br />
      <br />
      <br />
      <div className={style.subDiv}>
        <div className={style.select1}>
          <BasicSelect
            options={categoryTypeAll}
            value
            onSelectValue={onSelectValue}
            width="250"
          />
        </div>
        <div className={style.select1}>
          <BasicSelect2
            options={Category}
            value
            onSelectValue={onSelectValue2}
            onSelectName={onSelectName}
          />
        </div>

        <div className={style.select2}>
          <div className={style.inputiv}>
            <input
              type="number"
              className={style.InputDivInput}
              placeholder="From"
              value={From}
              onChange={(e) => setFrom(e.target.value)}
            />
          </div>
          <div className={style.inputiv}>
            <input
              type="number"
              className={style.InputDivInput}
              placeholder="To"
              value={To}
              onChange={(e) => setTo(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          columnGap:"20px",
          marginTop: "20px",
          marginLeft: "20px",
          marginBottom: "20px",
        }}
      >
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            value=""
            id="flexCheckDefault"
            style={{ cursor: "pointer" }}
            checked={hideImage}
            onChange={() => setHideImage(!hideImage)}
          />
          <label className="form-check-label" style={{ width: "100px" }}>
            Hide image
          </label>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            value=""
            id="flexCheckDefault"
            style={{ cursor: "pointer" }}
            checked={hideLonLat}
            onChange={() => setHideLonLat(!hideLonLat)}
          />
          <label className="form-check-label" style={{ width: "300px" }}>
            Hide longtitude & latitude
          </label>
        </div>
      </div>
      <button
        type="submit"
        onClick={GetData}
        className={style.filter}
        disabled={Disabled}
      >
        {" "}
        {LoadingAfterJoin ? (
          <CircularProgress
            className={style.Progress}
            style={{ width: "24px", height: "24px" }}
          />
        ) : (
          "Search"
        )}
      </button>
      {/* {Loading &&
        Option &&
        Option != "vacants" &&
        AllAdminData &&
        data &&
        data.length > 0 && (
          <CsvDownloader
            filename={
              UserSelectCategoryName.jobName +
                "_" +
                AllAdminData.CompanyShortName +
                "_" || Option
            }
            extension=".csv"
            columns={
              Option === "commercials"
                ? CommericalCsvDataHeader1
                : LndCsvDataHeader1
            }
            datas={data}
            text="DOWNLOAD"
            suffix
          >
            <button className={style.filter}>
              {Loading ? (
                "Download"
              ) : (
                <CircularProgress
                  color="inherit"
                  style={{ width: "24px", height: "24px" }}
                />
              )}
            </button>
          </CsvDownloader>
        )} */}

      {data && data.length > 0 && Option === "vacants" && (
        <div
          className="tabe-rider"
          style={{
            overflowX: "auto",
            maxWidth: "2979px !important",
            margin: "2rem auto",
          }}
        >
          <ExcelSheetFooter
            data={data}
            fileDowloadName={
              UserSelectCategoryName.jobName +
                "_" +
                AllAdminData.CompanyShortName +
                "_" || Option
            }
            jobName={UserSelectCategoryName.jobName || "JOB NAME"}
            hideImage={hideImage}
            hideLogLat = {hideLonLat}
          />
        </div>
      )}
      {data && data.length > 0 && Option != "vacants" && (
        <div
          className="tabe-rider"
          style={{
            overflowX: "auto",
            maxWidth: "2979px !important",
            margin: "2rem auto",
          }}
        >
          <ExportAsExcelSheet
            data={data}
            fileDowloadName={
              UserSelectCategoryName.jobName +
                "_" +
                AllAdminData.CompanyShortName +
                "_" || Option
            }
            jobName={UserSelectCategoryName.jobName || "JOB NAME"}
            hideImage={hideImage}
            hideLogLat = {hideLonLat}
          />
        </div>
      )}
    </Layout>
  );
}
export default Dashboard;

import React from "react";
import "react-toastify/dist/ReactToastify.css"; // import first
import makeAnimated from "react-select/animated";
import MySelect from "../../components/select/select";
import { components } from "react-select";
import { useToasts } from "react-toast-notifications";
import Datapicker from "../../components/customCore/datapicker";
import UploadIcon from "../../assets/upload.png";
import axios from "../../axios";
import "./changepassword.css";
import Layout from "../../components/layout/Navbar";
import moment from "moment";
import { makeStyles } from "@material-ui/core";
import { CircularProgress } from "@mui/material";

const animatedComponents = makeAnimated();
const useStyles = makeStyles((theme) => ({
  alert: {
    display: "flex",
    color: "rgb(30, 70, 32)",
    background: "rgb(237, 247, 237)",
    maxWidth: "400px",
    width: "100%",
    padding: " 10px 24px 0px 24px",
    flexDirection: "column",
    borderRadius: "10px",
  },
  Progress: {
    width: "24px !important",
    height: "24px !important",
  },
  formMain: {
    display: "flex",
    justifyContent: "center",
    maxWidth: "750px",
    paddingLeft: "250px",
    marginTop: "5%",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  label: {
    paddingLeft: "22px",
    fontSize: "16px",
    color: "#00000A",
    opacity: "0.6",
    fontWeight: 600,
    paddingBottom: "4px",

    [theme.breakpoints.down("lg")]: {
      fontSize: "14px !important",
    },
  },
  lnds: {
    maxWidth: "750px",
    minWidth: "350px",
    [theme.breakpoints.down("md")]: {
      maxWidth: "1500px",
      minWidth: "250px",
    },
  },
  btn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "16px !important",
    fontWeight: "500 !important",
    width: "200px  !important",
    margin: "auto",
    "&:hover": {
      color: "#501abf !important",
      fontWeight: "600 !important",

      "& svg": {
        color: "#501abf !important",
      },
      background: "#fff !important",
    },
    "& svg": {
      color: "#fff !important",
    },
    border: "2px solid #501abf !important",
  },
  formMainDiv: {
    width: "350px",
    [theme.breakpoints.down("md")]: {
      width: "auto !important",
    },
  },
  MySelectDiv: {
    justifyContent: "space-between",
    maxWidth: "750px",
    with: "100%",
    paddingLeft: "50px",
  },
}));
function ChangePassword() {
  const { addToast } = useToasts();
  const classes = useStyles();

  //upload file
  const [file, setFile] = React.useState("");
  const [PFile, setPFile] = React.useState("");
  const [Response, setResponse] = React.useState(false);

  const [optionSelected, setOptionSelected] = React.useState(null);
  const [optionSelectedOr, setOptionSelectedOr] = React.useState(null);
  const [Disabled, setDisabled] = React.useState(false);
  const [oldPassword, setOldpassword] = React.useState("");
  const [newPassword, setNewpassword] = React.useState("");
  const [confirmPassword, setConfirmpassword] = React.useState("");
  const [error, setError] = React.useState("");

  const adminEmail = JSON.parse(localStorage.getItem("auth")).message.email;

  let handleSubmit = (e) => {
    e.preventDefault();
    if (newPassword === confirmPassword) {
      // passwords match, do something
      e.preventDefault();
      setError("");

      const sendData = {
        email: adminEmail,
        password: oldPassword,
        newPassword: newPassword,
      };

      axios
        .post(`/admin/changePassword`, sendData)
        .then((res) => {
          addToast(
            res.data.message.includes("Password updated successfully!")
              ? res.data.message
              : "Old password is wrong",
            {
              appearance: res.data.message.includes("Something Went Wrong!!!")
                ? "error"
                : "success",
              autoDismiss: "true",
              autoDismissTimeout: 2000,
            }
          );
          setDisabled(false);
          setResponse(true);

          //   dialogOpen();
        })
        .catch((error) => {
          //   addToast("please select correct file", {
          //     appearance: "error",
          //     autoDismiss: "true",
          //     autoDismissTimeout: 2000,
          //   });
          setDisabled(false);
        });
        setNewpassword("");
        setOldpassword("");
        setConfirmpassword("");
    } else {
      setError("Passwords do not match!");
    }
   
  };
  return (
    <Layout>
      <div className="newFile2">
        <div className="rider-info">Change your password</div>
        <form onSubmit={handleSubmit}>
          <div className={classes.formMain}>
            <div className={classes.formMainDiv}>
              <label className={classes.label}>{"Old Password"}</label>
              <div className="input-div">
                <input
                  type="password"
                  className="input-div-input"
                  placeholder="Old Password"
                  value={oldPassword}
                  required
                  onChange={(e) => setOldpassword(e.target.value)}
                />
              </div>
              <br />
              <br />
              <label className={classes.label}>{"New Password"}</label>
              <div className="input-div">
                <input
                  type="password"
                  className="input-div-input"
                  placeholder="New Password"
                  value={newPassword}
                  required
                  onChange={(e) => setNewpassword(e.target.value)}
                />
              </div>
              <br />
              <br />
              <label className={classes.label}>{"Confirm Password"}</label>
              <div className="input-div">
                <input
                  type="password"
                  className="input-div-input"
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  required
                  onChange={(e) => setConfirmpassword(e.target.value)}
                />
              </div>
              {error && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    maxWidth: "750px",
                    color: "red",
                  }}
                >
                  {error}
                </div>
              )}
            </div>
          </div>
          <br />

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              maxWidth: "750px",
              paddingLeft: "250px",
            }}
          >
            <button
              type="submit"
              className={`submit3  ${classes.btn} `}
              disabled={Disabled}
            >
              {Disabled ? (
                <CircularProgress className={classes.Progress} />
              ) : (
                "Submit"
              )}
            </button>
            <br />
          </div>
        </form>
      </div>
    </Layout>
  );
}
export default ChangePassword;

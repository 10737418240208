import { makeStyles } from "@material-ui/core";
import { createStyles } from "@material-ui/core";
import { Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "../../axiosHeader";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      Main: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        margin: "10rem 0 0 0 ",
        fontSize: "2rem",
        fontWeight: 500,
        [theme.breakpoints.down("sm")]: {
          fontSize: "1.5rem",
        },
      },
      TotalRecordDiv: {
        maxWidth: "300px",
        padding: "20px",
        background: "#FEFEFE 0% 0% no-repeat padding-box",
        boxShadow: "0px 10px 20px #4A4A4A66",
        borderRadius: "20px",
        width: "100%",
      },
      "@media (max-width: 1050px)": {
        TotalRecordDiv: {
          width: "100%",
          margin: "2rem auto",
        },
      },
      "@media (max-width: 600px)": {
        TotalRecordDiv: {
          margin: "2rem 0rem 0rem 0rem",
        },
      },
      TotalRecordDivHeading: {
        color: "#5016BF",
        fontSize: "13px",
        margin: "0px",
      },
      TotalRecordDivNo: {
        color: "#000000",
        fontSize: "24px",
        margin: "0px",
        letterSpacing: "1px",
        fontWeight: 600,
      },
      TotalRecordTable: {
        maxWidth: "300px",
        width: "100%",
        margin: "1rem 0rem",
      },
      TotalRecordTableTr: {
        fontSize: "14px",

        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        "& p": {
          margin: "4px",
        },
      },
      TotalRecordTableTrMargin: {
        fontSize: "16px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        "& p": {
          margin: "6px",
        },
      },
      TotalRecordTableTrText: {
        color: "#000000",
      },

      TotalRecordTableTrNo: {
        color: "#000000",
        fontWeight: "600",
      },
    }),
  { withTheme: true }
);

export default function HumanResource() {
  const classes = useStyles();

  const [Loading, setLoading] = useState(false);

  const [Directors, setDirector] = useState(0);
  const [CEO_COOs, setCEO_COOs] = useState(0);
  const [Managers, setManagers] = useState(0);
  const [Call_Center_Staffs, setCall_Center_Staffs] = useState(0);
  const [Admins, setAdmins] = useState(0);
  const [Field_Visit_Staffs, setField_Visit_Staffs] = useState(0);

  const Auth = localStorage.getItem("userInfor");
  const message = JSON.parse(Auth);

  const token = message.message.token;
  const AdminId = message.message.id;

  useEffect(() => {
    axios
      .get("/rider/getByAdminId/" + AdminId)
      .then((res) => {
        setLoading(true);
        let Director = 0;
        let CEO_COO = 0;
        let Manager = 0;
        let Call_Center_Staff = 0;
        let Admin = 0;
        let Field_Visit_Staff = 0;
        res.data.map((item) => {
          let position = item.position;
          if (position === "Director") {
            Director = Director + 1;
          } else if (position === "CEO/COO") {
            CEO_COO = CEO_COO + 1;
          } else if (position === "Manager") {
            Manager = Manager + 1;
          } else if (position === "Call Center Staff") {
            Call_Center_Staff = Call_Center_Staff + 1;
          } else if (position === "Admin") {
            Admin = Admin + 1;
          } else if (position === "Field Visit Staff") {
            Field_Visit_Staff = Field_Visit_Staff + 1;
          }
        });
        setDirector(Director);
        setCEO_COOs(CEO_COO);
        setManagers(Manager);
        setCall_Center_Staffs(Call_Center_Staff);
        setAdmins(Admin);
        setField_Visit_Staffs(Field_Visit_Staff);
      })
      .catch((error) => {
        setLoading(true);
      });
  }, [AdminId]);

  return (
    <>
      <div className={classes.TotalRecordDiv}>
        <p className={classes.TotalRecordDivHeading}>Human Resource</p>
        {Loading ? (
          <div className={classes.TotalRecordTable}>
            <div className={classes.TotalRecordTableTr}>
              <p className={classes.TotalRecordTableTrText}>CEO/COO</p>
              <p className={classes.TotalRecordTableTrNo}>{CEO_COOs}</p>
            </div>
            <div className={classes.TotalRecordTableTr}>
              <p className={classes.TotalRecordTableTrText}>Director</p>
              <p className={classes.TotalRecordTableTrNo}>{Directors}</p>
            </div>
            <div className={classes.TotalRecordTableTr}>
              <p className={classes.TotalRecordTableTrText}>Manager</p>
              <p className={classes.TotalRecordTableTrNo}>{Managers}</p>
            </div>
            <div className={classes.TotalRecordTableTr}>
              <p className={classes.TotalRecordTableTrText}>
                Field Visit Staffs
              </p>
              <p className={classes.TotalRecordTableTrNo}>
                {Field_Visit_Staffs}
              </p>
            </div>
            <div className={classes.TotalRecordTableTr}>
              <p className={classes.TotalRecordTableTrText}>Administration</p>
              <p className={classes.TotalRecordTableTrNo}>{Admins}</p>
            </div>
            <div className={classes.TotalRecordTableTr}>
              <p className={classes.TotalRecordTableTrText}>Data Manager</p>
              <p className={classes.TotalRecordTableTrNo}>
                {Call_Center_Staffs}
              </p>
            </div>
            <div className={classes.TotalRecordTableTr}>
              <p className={classes.TotalRecordTableTrText}>Tellecaller</p>
              <p className={classes.TotalRecordTableTrNo}>0</p>
            </div>
          </div>
        ) : (
          <div className={classes.TotalRecordTable}>
            <div className={classes.TotalRecordTableTrMargin}>
              <p className={classes.TotalRecordTableTrText}>
                <Skeleton width={200} animation="wave" />
              </p>
              <p className={classes.TotalRecordTableTrNo}>
                <Skeleton width={16} animation="wave" />
              </p>
            </div>
            <div className={classes.TotalRecordTableTrMargin}>
              <p className={classes.TotalRecordTableTrText}>
                <Skeleton width={200} animation="wave" />
              </p>
              <p className={classes.TotalRecordTableTrNo}>
                <Skeleton width={16} animation="wave" />
              </p>
            </div>
            <div className={classes.TotalRecordTableTrMargin}>
              <p className={classes.TotalRecordTableTrText}>
                <Skeleton width={200} animation="wave" />
              </p>
              <p className={classes.TotalRecordTableTrNo}>
                <Skeleton width={16} animation="wave" />
              </p>
            </div>
            <div className={classes.TotalRecordTableTrMargin}>
              <p className={classes.TotalRecordTableTrText}>
                <Skeleton width={200} animation="wave" />
              </p>
              <p className={classes.TotalRecordTableTrNo}>
                <Skeleton width={16} animation="wave" />
              </p>
            </div>
            <div className={classes.TotalRecordTableTrMargin}>
              <p className={classes.TotalRecordTableTrText}>
                <Skeleton width={200} animation="wave" />
              </p>
              <p className={classes.TotalRecordTableTrNo}>
                <Skeleton width={16} animation="wave" />
              </p>
            </div>
            <div className={classes.TotalRecordTableTrMargin}>
              <p className={classes.TotalRecordTableTrText}>
                <Skeleton width={200} animation="wave" />
              </p>
              <p className={classes.TotalRecordTableTrNo}>
                <Skeleton width={16} animation="wave" />
              </p>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

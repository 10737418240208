import Layout from "../../../components/layout/Navbar";
import { makeStyles } from "@material-ui/core";
import { createStyles } from "@material-ui/core";
import React, { useState } from "react";
import LoadButton from "../../../components/buttons/loaderButton";
import SetupLayout from "./layoutInput";
import axios from "../../../axios";
import { useToasts } from "react-toast-notifications";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      [theme.breakpoints.down("md")]: {
        // backgroundColor: "green",
        flexDirection: "column",
      },
      MainCardDiv: {
        display: "flex",
        flexWrap: "wrap",
      },
      MainContent: {
        fontSize: "2rem",
        color: "#000",
        fontWeight: 600,
      },
      SubContent: {
        fontSize: "1.5rem",
        color: "#501abf",
        fontWeight: 500,
      },
      Container: {
        maxWidth: "1100px",
        width: "100%",
        margin: "0 auto",
        paddingLeft: "30px",
      },
      flexInputDivs: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: "wrap",
      },
      btnDiv: {
        margin: "auto",
        width: "200px",
      },
    }),
  { withTheme: true }
);

function Policy({ AllData }) {
  const classes = useStyles();
  const { addToast } = useToasts();
  const [Disabled, setDisabled] = useState(false);

  const AdminId = JSON.parse(localStorage.getItem("auth")).message.id || "";

  const nullor = (val) => {
    if (val === null || val === undefined) {
      return "";
    } else {
      return val;
    }
  };
  const stateValues = {
    // true mean off false mean on
    Minimumpasswordlength: AllData ? nullor(AllData.Minimumpasswordlength) : "",
    Passwordexpiresinevery: AllData
      ? nullor(AllData.Passwordexpiresinevery)
      : "",
    Gracelogin: AllData ? nullor(AllData.Gracelogin) : "",
    Passwordnotsameaslast: AllData ? nullor(AllData.Passwordnotsameaslast) : "",
    Passwordexpiryreminder: AllData
      ? nullor(AllData.Passwordexpiryreminder)
      : "",
    Disabledormantuser: AllData ? nullor(AllData.Disabledormantuser) : "",
    Requirestrongpassword: AllData
      ? nullor(AllData.Requirestrongpassword) === ""
        ? 1
        : nullor(AllData.Requirestrongpassword)
      : 0,
    Changepasswordfirstsignon: AllData
      ? nullor(AllData.Changepasswordfirstsignon) === ""
        ? 1
        : nullor(AllData.Changepasswordfirstsignon)
      : 0,
    Allowuserchangepassword: AllData
      ? nullor(AllData.Allowuserchangepassword) === ""
        ? 1
        : nullor(AllData.Allowuserchangepassword)
      : 0,
    // ch

    Accountlockoutafter: AllData ? nullor(AllData.Accountlockoutafter) : "",
    SessiontimeoutwarningMinutes: AllData
      ? nullor(AllData.SessiontimeoutwarningMinutes)
      : "",
    Rememberlastuserloginname: AllData
      ? nullor(AllData.Rememberlastuserloginname)
      : "",
    // Rememberlastuserloginname
    SessiontimeoutwarningSeconds: AllData
      ? nullor(AllData.SessiontimeoutwarningSeconds) === ""
        ? 1
        : nullor(AllData.SessiontimeoutwarningSeconds)
      : 0,
    Lockoutpolicy: AllData
      ? nullor(AllData.Lockoutpolicy) === ""
        ? 1
        : nullor(AllData.Lockoutpolicy)
      : 0,
  };
  const [values, setValues] = useState(stateValues);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(value);
    setValues({
      ...values,
      [name]: value,
    });
  };
  const handlToggleChange = (e) => {
    var { name, checked } = e.target;
    if (checked === true) {
      checked = 1;
    } else {
      checked = 0;
    }
    console.log(checked, "checkedd");
    setValues({
      ...values,
      [name]: !checked,
    });
  };
  const post = () => {
    const data = {
      Minimumpasswordlength: values.Minimumpasswordlength,
      Passwordexpiresinevery: values.Passwordexpiresinevery,
      Gracelogin: values.Gracelogin,
      Passwordnotsameaslast: values.Passwordnotsameaslast,
      Passwordexpiryreminder: values.Passwordexpiryreminder,
      Disabledormantuser: values.Disabledormantuser,
      Requirestrongpassword:
        values.Requirestrongpassword === true
          ? 1
          : values.Requirestrongpassword === false
          ? 0
          : values.Requirestrongpassword,
      Changepasswordfirstsignon:
        values.Changepasswordfirstsignon === true
          ? 1
          : values.Changepasswordfirstsignon === false
          ? 0
          : values.Changepasswordfirstsignon,
      Allowuserchangepassword:
        values.Allowuserchangepassword === true
          ? 1
          : values.Allowuserchangepassword === false
          ? 0
          : values.Allowuserchangepassword,
      Accountlockoutafter: values.Accountlockoutafter,
      SessiontimeoutwarningMinutes: values.SessiontimeoutwarningMinutes,
      SessiontimeoutwarningSeconds: values.SessiontimeoutwarningSeconds,
      Lockoutpolicy:
        values.Lockoutpolicy === true
          ? 1
          : values.Lockoutpolicy === false
          ? 0
          : values.Lockoutpolicy,
      Rememberlastuserloginname:
        values.Rememberlastuserloginname === true
          ? 1
          : values.Rememberlastuserloginname === false
          ? 0
          : values.Rememberlastuserloginname,
    };
    axios
      .put(`adminsecuritypolicies/update/${AdminId}`, data)
      .then((result) => {
        setDisabled(false);
        addToast("Successfully Added", {
          appearance: "success",
          autoDismiss: "true",
          autoDismissTimeout: 2000,
        });
        // window.location = "/dashboard";
      })
      .catch((error) => {
        addToast("Something Wrong", {
          appearance: "error",
          autoDismiss: "true",
          autoDismissTimeout: 2000,
        });
        setDisabled(false);

        // setEmail("");
        // setPassword("");
      });
  };
  return (
    <>
      <div>
        <div className={classes.SubContent}>Password Policy</div>
      </div>
      <br />
      <div className={classes.MainCardDiv}>
        <div className={classes.Container}>
          <div className={classes.flexInputDivs}>
            <SetupLayout
              placeholder="Eg : 4"
              onChange={handleInputChange}
              name="Minimumpasswordlength"
              value={values.Minimumpasswordlength}
              heading="Minimum password length "
              para="Min 4, Max12"
              type="number"
            />
            <SetupLayout
              placeholder="Eg : 365"
              onChange={handleInputChange}
              label="Days"
              name="Passwordexpiresinevery"
              value={values.Passwordexpiresinevery}
              heading="Password expires in every"
              para="Number of days"
              type="number"
            />
            <SetupLayout
              placeholder="Eg : 6"
              onChange={handleInputChange}
              label="Times"
              name="Gracelogin"
              value={values.Gracelogin}
              heading="Grace login after password expired"
              para="Number of times"
              type="number"
            />
            <SetupLayout
              placeholder="Eg : 4"
              onChange={handleInputChange}
              label="Password(s)"
              name="Passwordnotsameaslast"
              value={values.Passwordnotsameaslast}
              heading="Password must not be the same as on of the last"
              para="Number of password"
              type="number"
            />
            <SetupLayout
              placeholder="Eg : 4"
              onChange={handleInputChange}
              label="Day before expiry"
              name="Passwordexpiryreminder"
              value={values.Passwordexpiryreminder}
              heading="Password expiry reminder"
              para="Number of days"
              type="number"
            />
            <SetupLayout
              placeholder="Eg : 4"
              onChange={handleInputChange}
              label="Days"
              name="Disabledormantuser"
              value={values.Disabledormantuser}
              heading="Disable dormant user account(s) after a minimum of"
              para="Number of times"
              type="number"
            />
            <SetupLayout
              toggle
              placeholder="Eg : 4"
              onChange={handlToggleChange}
              label="Password(s)"
              name="Requirestrongpassword"
              value={values.Requirestrongpassword}
              heading="Require strong password combination "
              type="number"
            />
            <SetupLayout
              toggle
              placeholder="Eg : 4"
              onChange={handlToggleChange}
              label="Password(s)"
              name="Changepasswordfirstsignon"
              value={values.Changepasswordfirstsignon}
              heading="Change password on first sign-on"
            />
            <SetupLayout
              toggle
              placeholder="Eg : 4"
              onChange={handlToggleChange}
              label="Password(s)"
              name="Allowuserchangepassword"
              value={values.Allowuserchangepassword}
              heading="Allow user change password within the day"
            />
            {/* Password expires in every  */}
          </div>
        </div>
      </div>
      <br />
      <br />
      <div>
        <div className={classes.SubContent}>Lockout Policy</div>
      </div>
      <br />
      <div className={classes.MainCardDiv}>
        <div className={classes.Container}>
          <div className={classes.flexInputDivs}>
            <SetupLayout
              placeholder="Eg : 365"
              onChange={handleInputChange}
              name="Accountlockoutafter"
              value={values.Accountlockoutafter}
              heading="Account lock out after"
              para="Number of days"
              label="invalid login attempts"
              type="number"
            />
            <SetupLayout
              placeholder="Eg : 6"
              onChange={handleInputChange}
              label="Minutes"
              name="SessiontimeoutwarningMinutes"
              value={values.SessiontimeoutwarningMinutes}
              heading="Session timeout warning notification"
              para="Number of session (Min 1seconds)"
              type="number"
            />
            <SetupLayout
              placeholder="Eg : 6"
              onChange={handleInputChange}
              label="Seconds"
              name="SessiontimeoutwarningSeconds"
              value={values.SessiontimeoutwarningSeconds}
              heading="Session timeout warning notification"
              para="Number of times"
              type="number"
            />
            <SetupLayout
              toggle
              placeholder="Eg : 4"
              onChange={handlToggleChange}
              label="Password(s)"
              name="Lockoutpolicy"
              value={values.Lockoutpolicy}
              heading="Lockout policy"
              type="number"
            />
            <SetupLayout
              toggle
              placeholder="Eg : 4"
              onChange={handlToggleChange}
              label="Password(s)"
              name="Rememberlastuserloginname"
              value={values.Rememberlastuserloginname}
              heading="Remember last user login name"
              type="number"
            />

            {/* Password expires in every  */}
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className={classes.btnDiv}>
        <LoadButton onClick={post} name="Save" />
      </div>
    </>
  );
}
export default Policy;

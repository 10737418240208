import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import { createTheme } from "@mui/material/styles";
import axios from "../../axiosHeader";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import ReactPaginate from "react-paginate";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import { makeStyles } from "@material-ui/core";
import { createStyles } from "@material-ui/core";
import moment from "moment";
const theme = createTheme();

theme.typography.h3 = {
  typography: {
    fontFamily: ["Poppins"].join(","),
  },

  fontSize: "1.8rem",
  fontWeight: 400,
  fontStyle: "normal",

  "@media (max-width:850px)": {
    fontSize: "1.5rem",
  },
  "@media (max-width:400px)": {
    fontSize: "1rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.8rem",
  },
};
theme.typography.h1 = {
  typography: {
    fontFamily: ["Poppins"].join(","),
  },

  fontSize: "2.5rem",
  color: "#5016BF",
  "@media (max-width:800px)": {
    fontSize: "1.5rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "2.4rem",
  },
};

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      tableDiv: {
        overflowX: "auto",
        maxWidth: "1200px",
        [theme.breakpoints.down("sm")]: {
          width: "90vw",
        },
        "& table , th,td ": {
          borderCollapse: "collapse",
        },
        "& table , tr ": {
          borderCollapse: "collapse",
          "&: n+1": {
            background: "red !Important",
          },
        },
      },
      tableMainDiv: {
        marginTop: "2rem",
        marginBottom: "5rem",
        background: "#FEFEFE 0% 0% no-repeat padding-box",
        boxShadow: "0px 10px 20px #4A4A4A66",
        borderRadius: "20px",
        padding: "20px",
      },
      tableMainHeading: {
        fontSize: "24px",
        margin: "0rem 0rem 0.7rem 0rem",
        fontWeight: "600",
      },
    }),
  { withTheme: true }
);
function LocationTable() {
  const classes = useStyles();

  const [Loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const [Jobname, setJobname] = useState([]);
  const [JobType, setJobType] = useState([]);
  const [JobP, setJobP] = useState([]);
  const [Total, setTotal] = useState([]);
  const [Complete, setComplete] = useState([]);
  const [Pending, setPending] = useState([]);

  const [Jobname2, setJobname2] = useState([]);
  const [JobType2, setJobType2] = useState([]);
  const [JobP2, setJobP2] = useState([]);
  const [Total2, setTotal2] = useState([]);
  const [Complete2, setComplete2] = useState([]);
  const [Pending2, setPending2] = useState([]);

  const itemsPerPage = 10;

  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  const Auth = localStorage.getItem("userInfor");
  const message = JSON.parse(Auth);
  const AdminId = message.message.id;

  useEffect(() => {
    if (AdminId) {
      axios
        .get("/getKlanyValley/" + AdminId)
        .then((res) => {
          setData(res.data[0]);
          setLoading(true);
          var datas = res.data[0];
          let jobname = [];
          let jobtype = [];
          let jobperiods = [];
          let total = [];
          let pendings = [];
          let completes = [];

          datas.map((item, i) => {
            jobname[i] = item.jobname;
            jobtype[i] = item.category;
            jobperiods[i] = `${moment(item.createdDate).format("DD/MM/YYYY")} -
            ${moment(item.dueDate).format("DD/MM/YYYY")}`;
            total[i] = item.total;
            pendings[i] = item.total - item.completed;
            completes[i] = item.completed;
          });
          setJobname(jobname);
          setJobType(jobtype);
          setJobP(jobperiods);
          setTotal(total);
          setPending(pendings);
          setComplete(completes);
        })
        .catch((error) => {
          setLoading(true);
        });
      axios
        .get("/getOutStations/" + AdminId)
        .then((res) => {
          setData(res.data[0]);
          setLoading(true);
          var datas = res.data[0];
          let jobname = [];
          let jobtype = [];
          let jobperiods = [];
          let total = [];
          let pendings = [];
          let completes = [];

          datas.map((item, i) => {
            // datas
            jobname[i] = item.jobname;
            jobtype[i] = item.category;
            jobperiods[i] = `${moment(item.createdDate).format("DD/MM/YYYY")} -
            ${moment(item.dueDate).format("DD/MM/YYYY")}`;
            total[i] = item.total;
            pendings[i] = item.total - item.completed;
            completes[i] = item.completed;
          });
          setJobname2(jobname);
          setJobType2(jobtype);
          setJobP2(jobperiods);
          setTotal2(total);
          setPending2(pendings);
          setComplete2(completes);
        })
        .catch((error) => {
          setLoading(true);
        });
    }
  }, [AdminId]);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(data.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, data]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % data.length;
    setItemOffset(newOffset);
  };
  const TbleData = ({ el, i, bg }) => (
    <>
      <table>
        <tbody>
          <tr
            style={{
              border: "none",
              display: "flex",
              flexWrap: "wrap",
              background: bg ? "#fff" : "#d6eeee",
              textAlign: "left !important",
            }}
          >
            <td
              style={{
                textAlign: "left !important",
                border: "none",
                width: "100%",
              }}
            >
              <p
                style={{
                  textAlign: "left",
                  margin: "0px",
                  paddingLeft: "16px",
                }}
              >
                {el}
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
  return (
    <div className={classes.tableMainDiv}>
      <div className={classes.tableMainHeading}>Break down by location</div>
      <br />
      <div className={classes.tableDiv}>
        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            borderSpacing: 0,
          }}
        >
          <tbody>
            <tr>
              <th>
                <Typography variant="body">Location</Typography>{" "}
              </th>
              <th>
                <Typography variant="body">Job Name</Typography>{" "}
              </th>
              <th>
                <Typography variant="body">Job Type</Typography>{" "}
              </th>
              <th>
                <Typography variant="body">Job Period</Typography>{" "}
              </th>
              <th>
                <Typography variant="body">Total</Typography>{" "}
              </th>
              <th>
                <Typography variant="body">Pending</Typography>{" "}
              </th>
              <th>
                <Typography variant="body">Completed</Typography>{" "}
              </th>
            </tr>
          </tbody>

          <tbody>
            {!Loading && (
              <tr
                style={{
                  borderCollapse: "collapse",
                  padding: "0px !important",
                }}
              >
                <td
                  style={{
                    textAlign: "center",
                    verticalAlign: "middle",
                    padding: "0px !important",
                  }}
                  colSpan="12"
                >
                  <Box sx={{ width: "100%" }}>
                    <LinearProgress />
                  </Box>
                </td>
              </tr>
            )}
          </tbody>
          {Loading && (
            <tbody>
              <tr>
                <td style={{ fontWeight: "600" }}> Klang Valley</td>
                <td style={{}}>
                  {Jobname.map((el, i) => (
                    <TbleData el={el} key={i} />
                  ))}
                </td>
                <td style={{}}>
                  {JobType.map((el, i) => (
                    <TbleData el={el} key={i} />
                  ))}
                </td>
                <td style={{}}>
                  {JobP.map((el, i) => (
                    <TbleData el={el} key={i} />
                  ))}
                </td>

                <td style={{}}>
                  {Total.map((el, i) => (
                    <TbleData el={el} key={i} />
                  ))}
                </td>
                <td style={{}}>
                  {Pending.map((el, i) => (
                    <TbleData el={el} key={i} />
                  ))}
                </td>
                <td style={{}}>
                  {Complete.map((el, i) => (
                    <TbleData el={el} key={i} />
                  ))}
                </td>
              </tr>
              <tr>
                <td style={{ fontWeight: "600" }}> Outstation</td>
                <td style={{}}>
                  {Jobname2.map((el, i) => (
                    <TbleData el={el} key={i} bg="#fff" />
                  ))}
                </td>
                <td style={{}}>
                  {JobType2.map((el, i) => (
                    <TbleData el={el} key={i} bg="#fff" />
                  ))}
                </td>
                <td style={{}}>
                  {JobP2.map((el, i) => (
                    <TbleData el={el} key={i} bg="#fff" />
                  ))}
                </td>

                <td style={{}}>
                  {Total2.map((el, i) => (
                    <TbleData el={el} key={i} bg="#fff" />
                  ))}
                </td>
                <td style={{}}>
                  {Pending2.map((el, i) => (
                    <TbleData el={el} key={i} bg="#fff" />
                  ))}
                </td>
                <td style={{}}>
                  {Complete2.map((el, i) => (
                    <TbleData el={el} key={i} bg="#fff" />
                  ))}
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
      <br />
      <br />
      <ReactPaginate
        breakLabel="..."
        nextLabel="next >"
        onPageChange={handlePageClick}
        pageCount={pageCount}
        previousLabel="< previous"
        renderOnZeroPageCount={null}
        pageRangeDisplayed={1}
        marginPagesDisplayed={1}
        containerClassName={"pagination justify-content-end"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextClassName={"page-item"}
        nextLinkClassName={"page-link"}
        breakClassName={"page-item"}
        breakLinkClassName={"page-link"}
        activeClassName={"active"}
      />
    </div>
  );
}

export default LocationTable;

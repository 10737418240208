import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import { createTheme } from "@mui/material/styles";
import axios from "../../../axios";
import AddRider from "../../../components/Modal/AddRiderPopup";
import DeleteIcon from "@mui/icons-material/Delete";
import Layout from "../../../components/layout/Navbar";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import AlertBox from "../../../components/layout/alertBox";

const theme = createTheme();

theme.typography.h3 = {
  typography: {
    fontFamily: ["Poppins"].join(","),
  },

  fontSize: "1.8rem",
  fontWeight: 400,
  fontStyle: "normal",

  "@media (max-width:850px)": {
    fontSize: "1.5rem",
  },
  "@media (max-width:400px)": {
    fontSize: "1rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.8rem",
  },
};
theme.typography.h1 = {
  typography: {
    fontFamily: ["Poppins"].join(","),
  },

  fontSize: "2.5rem",
  color: "#5016BF",
  "@media (max-width:800px)": {
    fontSize: "1.5rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "2.4rem",
  },
};

function Newrider() {
  const [openModel, setOpenModel] = useState(false);
  // openConform
  const [Loading, setLoading] = useState(false);
  const [Loading1, setLoading1] = useState(false);
  const [Loading2, setLoading2] = useState(false);
  const [Loading3, setLoading3] = useState(false);
  const [Loading4, setLoading4] = useState(false);
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [data4, setData4] = useState([]);
  const Auth = localStorage.getItem("userInfor");
  const message = JSON.parse(Auth);
  const token = message.message.token;
  const AdminId = message.message.id;

  const itemsPerPage = 10;

  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  const dummy1 = [
    {
      id: 1349,
      jobNameId: 2,
      jobName: "MFC-COM-01",
    },
    {
      id: 1350,
      jobNameId: 2,
      jobName: "MFC-COM-02",
    },
  ];
  const dummy2 = [
    {
      id: 1349,
      jobNameId: 2,
      jobName: "MFC-COM-01",
    },
    {
      id: 1350,
      jobNameId: 2,
      jobName: "MFC-COM-2",
    },
  ];
  const dummy3 = [
    {
      id: 1349,
      jobNameId: 2,
      jobName: "MFC-COM-01",
    },
    {
      id: 1350,
      jobNameId: 2,
      jobName: "MFC-COM-02",
    },
  ];
  const dummy4 = [
    {
      id: 1349,
      jobNameId: 2,
      jobName: "MFC-COM-01",
    },
    {
      id: 1350,
      jobNameId: 2,
      jobName: "MFC-sdsddsd-02",
    },
  ];
  useEffect(() => {
    if (AdminId) {
      axios
        .get("/findAllpaymentListHighrises/" + AdminId)
        .then((res) => {
          let datas = res.data;
          datas.map((item) => {
            item["category"] = "Highrise";
          });
          setData1(datas);
          setLoading1(true);
        })
        .catch((error) => {
          setLoading1(true);
        });
    }
  }, [AdminId]);
  useEffect(() => {
    if (AdminId) {
      axios
        .get("/findAllpaymentListCommercials/" + AdminId)
        .then((res) => {
          let datas = res.data;
          datas.map((item) => {
            item["category"] = "Commercial";
          });
          setData2(datas);
          setLoading2(true);
        })
        .catch((error) => {
          setLoading2(true);
        });
    }
  }, [AdminId]);
  useEffect(() => {
    if (AdminId) {
      axios
        .get("/findAllpaymentListLandeds/" + AdminId)
        .then((res) => {
          let datas = res.data;
          datas.map((item) => {
            item["category"] = "Landed";
          });
          setData3(datas);
          setLoading3(true);
        })
        .catch((error) => {
          setLoading3(true);
        });
    }
  }, [AdminId]);

  useEffect(() => {
    if (AdminId) {
      axios
        .get("/findAllpaymentListVacants/" + AdminId)
        .then((res) => {
          let datas = res.data;
          datas.map((item) => {
            item["category"] = "Vacant";
          });
          setData4(datas);
          setLoading4(true);
        })
        .catch((error) => {
          setLoading4(true);
        });
    }
  }, [AdminId]);
  useEffect(() => {
    if (AdminId) {
      let allData = data1.concat(data2, data3, data4);
      setData(allData);
      setLoading(true);
    }
  }, [AdminId, data1, data2, data3, data4]);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(data.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, data]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % data.length;

    setItemOffset(newOffset);
  };

  const deleteHandle = (e) => {
    axios
      .delete(`/riderdata/${e.id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        window.location.href = "/assigned-data";
      })
      .catch((error) => {
        setLoading(true);
      });
  };
  const Deleting = (e) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <AlertBox
            onClose={onClose}
            onClick={() => {
              deleteHandle(e);
            }}
          />
        );
      },
    });
  };

  return (
    <Layout title="Rider">
      <div className="rider-tble">
        <div className="rider-head-details">
          <div className="rider-info">Payment List</div>
          <button className="button-cus">
            <Link
              style={{ textDecoration: "none", color: "#501abf" }}
              to="/payment-list"
            >
              Add New
            </Link>
          </button>
        </div>
        <div className="table-rider">
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              borderSpacing: 0,
            }}
          >
            <tbody>
              <tr>
                <th>
                  <Typography variant="body">#</Typography>{" "}
                </th>

                <th>
                  {" "}
                  <Typography variant="body">Category</Typography>{" "}
                </th>
                <th>
                  <Typography variant="body">Jobname</Typography>{" "}
                </th>

                <th>
                  <Typography variant="body">Action</Typography>{" "}
                </th>
              </tr>
            </tbody>
            <tbody>
              {!Loading4 && (
                <tr
                  style={{
                    borderCollapse: "collapse",
                    padding: "0px !important",
                  }}
                >
                  <td
                    style={{
                      textAlign: "center",
                      verticalAlign: "middle",
                      padding: "0px !important",
                    }}
                    colSpan="12"
                  >
                    <Box sx={{ width: "100%" }}>
                      <LinearProgress />
                    </Box>
                  </td>
                </tr>
              )}
            </tbody>

            <tbody>
              {currentItems &&
                currentItems.length > 0 &&
                currentItems.map((data, i) => (
                  <tr key={i}>
                    <td>
                      <Typography variant="body">{i + 1}</Typography>
                    </td>

                    <td>
                      <p
                        style={{
                          textAlign: "left",
                          margin: "0px",
                          paddingLeft: "16px",
                          textTransform: "capitalize",
                        }}
                      >
                        {data.category === "lnds" ? "Landed" : data.category}
                      </p>
                    </td>

                    <td>
                      <p
                        style={{
                          textAlign: "left",
                          margin: "0px",
                          paddingLeft: "16px",
                        }}
                      >
                        {data.jobName}
                      </p>
                    </td>
                    <td onClick={() => Deleting(data)}>
                      <DeleteIcon
                        sx={{
                          color: "red",
                          opacity: "0.8",
                          ": &hover": { opacity: "1" },
                          cursor: "pointer",
                        }}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
            <tbody>
              {data.length === 0 && Loading4 && (
                <tr style={{ borderCollapse: "collapse" }}>
                  <td
                    style={{
                      textAlign: "center",
                      verticalAlign: "middle",
                    }}
                    colSpan="12"
                  >
                    <Typography variant="body">No data</Typography>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <br />
          <ReactPaginate
            breakLabel="..."
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageCount={pageCount}
            previousLabel="< previous"
            renderOnZeroPageCount={null}
            pageRangeDisplayed={1}
            marginPagesDisplayed={1}
            containerClassName={"pagination justify-content-end"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        </div>
      </div>

      {openModel && <AddRider closeModel={setOpenModel} />}
    </Layout>
  );
}

export default Newrider;

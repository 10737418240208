import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import { createTheme } from "@mui/material/styles";
import axios from "../../axiosHeader";
import Layout from "../../components/layout/Navbar";
import "./rider.css";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import ReactPaginate from "react-paginate";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import EditIcon from "@mui/icons-material/Edit";
import AddRoles from "../../components/Modal/AddRoles";
import AddIcon from "@mui/icons-material/Add";
import AddPermission from "../../components/Modal/AddPermission";
const theme = createTheme();

theme.typography.h3 = {
  typography: {
    fontFamily: ["Poppins"].join(","),
  },

  fontSize: "1.8rem",
  fontWeight: 400,
  fontStyle: "normal",

  "@media (max-width:850px)": {
    fontSize: "1.5rem",
  },
  "@media (max-width:400px)": {
    fontSize: "1rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.8rem",
  },
};
theme.typography.h1 = {
  typography: {
    fontFamily: ["Poppins"].join(","),
  },

  fontSize: "2.5rem",
  color: "#5016BF",
  "@media (max-width:800px)": {
    fontSize: "1.5rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "2.4rem",
  },
};

function Roles() {
  const [openModel, setOpenModel] = useState(false);
  const [openPermission, setOpenPermission] = useState(false);
  const [roleId, setRoelId] = useState('');
  const [EditId, setEditId] = useState("");
  const [EditOpenModel, setEditOpenModel] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const Auth = localStorage.getItem("userInfor");
  const message = JSON.parse(Auth);
  // paggnination
  const itemsPerPage = 10;

  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  const token = message.message.token;
  const AdminId = message.message.id;

  useEffect(() => {
    axios
      .get(`getEmployeeRolesByAdminid/${AdminId}`)
      .then((res) => {
        setData(res.data);
        setLoading(true);
      })
      .catch((error) => {
        setLoading(true);
      });
  }, []);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(data.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, data]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % data.length;
    setItemOffset(newOffset);
  };

  const permissionAdd = (e) =>{
    setOpenPermission(true);
    setRoelId(e);
  }

  const Editing = (e) => {
    setEditId(e);
    setEditOpenModel(true);
  };

  return (
    <Layout title="Rider">
      <div className="rider-tble">
        <div className="rider-head-details">
          <div className="rider-info">Roles</div>
          <button
            className="button-cus"
            onClick={() => {
              setOpenModel(true);
            }}
          >
            Add New
          </button>
        </div>
        <div className="table-rider">
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              borderSpacing: 0,
            }}
          >
            <tbody>
              <tr>
                <th>
                  {" "}
                  <Typography variant="body">ID</Typography>{" "}
                </th>
                <th>
                  <Typography variant="body">Roles</Typography>{" "}
                </th>
                <th>
                  <Typography variant="body">Edit Role</Typography>{" "}
                </th>
                <th>
                  <Typography variant="body">Permission</Typography>{" "}
                </th>
              </tr>
            </tbody>

            <tbody>
              {!Loading && (
                <tr
                  style={{
                    borderCollapse: "collapse",
                    padding: "0px !important",
                  }}
                >
                  <td
                    style={{
                      textAlign: "center",
                      verticalAlign: "middle",
                      padding: "0px !important",
                    }}
                    colSpan="12"
                  >
                    <Box sx={{ width: "100%" }}>
                      <LinearProgress />
                    </Box>
                  </td>
                </tr>
              )}
            </tbody>

            <tbody>
              {currentItems &&
                currentItems.length > 0 &&
                currentItems.map((data, i) => (
                  <tr key={i}>
                    <td>
                      <Typography variant="body">{data.id}</Typography>
                    </td>

                    <td>
                      <p
                        style={{
                          textAlign: "center",
                          margin: "0px",
                          paddingLeft: "16px",
                        }}
                      >
                        {data.roles}
                      </p>
                    </td>
                    <td>
                      <EditIcon
                        sx={{
                          color: "#008000c2",
                          opacity: "0.8",
                          ": &hover": { opacity: "1" },
                          cursor: "pointer",
                        }}
                        onClick={() => Editing(data)}
                      />
                    </td>
                    <td>
                      <AddIcon
                        sx={{
                          color: "#008000c2",
                          opacity: "0.8",
                          ": &hover": { opacity: "1" },
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          permissionAdd(data);
                        }}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
            <tbody>
              {data.length === 0 && Loading && (
                <tr style={{ borderCollapse: "collapse" }}>
                  <td
                    style={{
                      textAlign: "center",
                      verticalAlign: "middle",
                    }}
                    colSpan="12"
                  >
                    <Typography variant="body">No data</Typography>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <br />

          <ReactPaginate
            breakLabel="..."
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageCount={pageCount}
            previousLabel="< previous"
            renderOnZeroPageCount={null}
            pageRangeDisplayed={1}
            marginPagesDisplayed={1}
            containerClassName={"pagination justify-content-end"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        </div>
      </div>

      {openModel && <AddRoles closeModel={setOpenModel} />}
      {EditOpenModel && (
        <AddRoles closeModel={setEditOpenModel} edit editId={EditId} />
      )}
      {openPermission && <AddPermission closeModel={setOpenPermission} roleId={roleId} />}
    </Layout>
  );
}

export default Roles;

export const Qrcode = [
  { id: 2, name: "DR01 PAYMENT MADE AFTER DCA VISIT" },
  { id: 3, name: "DR02 PROMISED TO PAY" },
  { id: 4, name: "DR03 REFUSE TO PAY" },
  { id: 5, name: "DR04 UNABLE TO PAY" },
  { id: 6, name: "DR05 VACANT PREMISE" },
  { id: 7, name: "DR06 VACANT LAND" },
  { id: 8, name: "DR07 ABANDON" },
  { id: 9, name: "DR08 NO TOILET" },
  { id: 10, name: "DR09 DEMOLISHED" },
  { id: 11, name: "DR10 BUILDING BURNT" },
  { id: 12, name: "DR11 UNDER RENOVATION" },
  { id: 13, name: "DR12 PROP CANNOT BE LOCATED" },
  { id: 14, name: "DR13 UNDER LIQUIDATION" },
  { id: 15, name: "DR14 FURTHER EXPLANATION " },
  { id: 16, name: "DR15 BUSINESS ONLY OPERATED IN THE EVENING" },
  { id: 17, name: "DR16 REQUESTED FOR INSTALLMENT PLAN" },
  { id: 18, name: "DR17 REQUESTED FOR STATEMENT OF ACCOUNT (SOA)" },
  { id: 19, name: "DR18 REQUESTED FOR COPY BILL" },
  { id: 20, name: "DR19 COLLECTED POSTDATED CHEQUES" },
  { id: 21, name: "DR20 OTHER REMARKS, PLEASE SPECIFY IN REMARK COLUMN" },
  { id: 22, name: "DR21 DOMESTIC USAGE (FOR COMMERCIAL ASSIGNMENT ONLY)" },
  { id: 23, name: "DR22 CLOSED" },
  { id: 24, name: "DR23 LEAVE MESSAGE TO CALL" },
  { id: 25, name: "DR25 TENANT CLAIM OWNER TO PAY" },
  { id: 26, name: "DR26 TENANT PAID TO OWNER" },
  { id: 27, name: "DR27 NEW TENANT/SAME OWNER" },
  { id: 28, name: "DR28 PAYMENT IS OUSTANDING BUT CLAIM PAID" },
  { id: 29, name: "DR29 STORE, WITHOUT SIGNBOARD" },
  { id: 30, name: "DR32 NO WATER METER" },
  { id: 31, name: "DR33 UNDER RECEIVERSHIP" },
  {
    id: 32,
    name: "DR34 COOLING TOWER USAGE  (FOR COMMERCIAL ASSIGNMENT ONLY)",
  },
  { id: 33, name: "DR35 OWNER CLAIM TENANT TO PAY" },
  { id: 34, name: "DR36 COMMERCIAL USAGE (FOR DOMESTIC ASSIGNMENT ONLY)" },
  { id: 35, name: "DR37 CUSTOMER DISPUTE ON CHARGES" },
  { id: 36, name: "DR38 NOBODY IN THE AREA TO REFER" },
  { id: 37, name: "DR39 CLAIM NO SERVICE PROVIDED" },
  { id: 38, name: "DR40 MANAGEMENT REFUSE TO COOPERATE" },
  {
    id: 39,
    name: "DR41 NEW OWNER REFUSE TO PAY PREVIOUS OWNER OUTSTANDING",
  },
  { id: 40, name: "DR42 CUSTOMER REFUSE TO MEET DCA" },
  { id: 41, name: "DR43 PROPERTY AGENT REFUSE TO COOPERATE" },
  {
    id: 42,
    name: "DR45 POLITICAL PARTIES ADVISE CUSTOMER NOT TO PAY IWK CHARGE",
  },
  { id: 43, name: "DR46 NOT RECEIVED BILL" },
  { id: 44, name: "DR47 CLAIM PAYED TO IST TANKER" },
  { id: 45, name: "DR48 PREMISE OCCUPY BY FOREIGNER-COMMUNICATION ISSUES" },
  { id: 46, name: "DR49 OWNER IS IN OVERSEAS" },
  { id: 47, name: "DR50 WELFARE SOCIETY-CANT AFFORD TO PAY" },
  { id: 48, name: "DR52 DROP" },
  { id: 49, name: "DR53 PROPERTY SOLD & CURRENT NAME UNDER OLD OWNER" },
  { id: 50, name: "DR54 PROPERTY UNDER GOVERNMENT" },
  // DR52 DROP.
];
export const drcode2 = [{id:"DR01 PAYMENT MADE AFTER DCA VISIT",name:"DR01 PAYMENT MADE AFTER DCA VISIT",value:"DR01 PAYMENT MADE AFTER DCA VISIT"},
{id:"DR03 REFUSE TO PAY",name:"DR03 REFUSE TO PAY",value:"DR03 REFUSE TO PAY"},
{id:"DR04 UNABLE TO PAY",name:"DR04 UNABLE TO PAY",value:"DR04 UNABLE TO PAY"},
{id:"DR05 VACANT PREMISE",name:"DR05 VACANT PREMISE",value:"DR05 VACANT PREMISE"},
{id:"DR06 VACANT LAND",name:"DR06 VACANT LAND",value:"DR06 VACANT LAND"},
{id:"DR07 ABANDON",name:"DR07 ABANDON",value:"DR07 ABANDON"},
{id:"DR09 DEMOLISHED",name:"DR09 DEMOLISHED",value:"DR09 DEMOLISHED"},
{id:"DR10 BUILDING BURNT",name:"DR10 BUILDING BURNT",value:"DR10 BUILDING BURNT"},
{id:"DR11 UNDER RENOVATION",name:"DR11 UNDER RENOVATION",value:"DR11 UNDER RENOVATION"},
{id:"DR12 PROP CANNOT BE LOCATED",name:"DR12 PROP CANNOT BE LOCATED",value:"DR12 PROP CANNOT BE LOCATED"},
{id:"DR13 UNDER LIQUIDATION",name:"DR13 UNDER LIQUIDATION",value:"DR13 UNDER LIQUIDATION"},
{id:"DR16 REQUESTED FOR INSTALLMENT PLAN",name:"DR16 REQUESTED FOR INSTALLMENT PLAN",value:"DR16 REQUESTED FOR INSTALLMENT PLAN"},
{id:"DR19 COLLECTED POSTDATED CHEQUES",name:"DR19 COLLECTED POSTDATED CHEQUES",value:"DR19 COLLECTED POSTDATED CHEQUES"},
{id:"DR21 DOMESTIC USAGE (FOR COMMERCIAL ASSIGNMENT ONLY)",name:"DR21 DOMESTIC USAGE (FOR COMMERCIAL ASSIGNMENT ONLY)",value:"DR21 DOMESTIC USAGE (FOR COMMERCIAL ASSIGNMENT ONLY)"},
{id:"DR22 HOUSE IS CLOSED",name:"DR22 HOUSE IS CLOSED",value:"DR22 HOUSE IS CLOSED"},
{id:"DR25 TENANT CLAIM OWNER TO PAY",name:"DR25 TENANT CLAIM OWNER TO PAY",value:"DR25 TENANT CLAIM OWNER TO PAY"},
{id:"DR26 TENANT PAID TO OWNER",name:"DR26 TENANT PAID TO OWNER",value:"DR26 TENANT PAID TO OWNER"},
{id:"DR27 NEW TENANT/SAME OWNER",name:"DR27 NEW TENANT/SAME OWNER",value:"DR27 NEW TENANT/SAME OWNER"},
{id:"DR29 STORE, WITHOUT SIGNBOARD",name:"DR29 STORE, WITHOUT SIGNBOARD",value:"DR29 STORE, WITHOUT SIGNBOARD"},
{id:"DR30 BANKRUPT/ PROPERTY UNDER COURT CASE",name:"DR30 BANKRUPT/ PROPERTY UNDER COURT CASE",value:"DR30 BANKRUPT/ PROPERTY UNDER COURT CASE"},
{id:"DR32 NO WATER METER",name:"DR32 NO WATER METER",value:"DR32 NO WATER METER"},
{id:"DR33 UNDER RECEIVERSHIP",name:"DR33 UNDER RECEIVERSHIP",value:"DR33 UNDER RECEIVERSHIP"},
{id:"DR35 OWNER CLAIM TENANT TO PAY",name:"DR35 OWNER CLAIM TENANT TO PAY",value:"DR35 OWNER CLAIM TENANT TO PAY"},
{id:"DR36 COMMERCIAL USAGE (FOR DOMESTIC ASSIGNMENT ONLY)",name:"DR36 COMMERCIAL USAGE (FOR DOMESTIC ASSIGNMENT ONLY)",value:"DR36 COMMERCIAL USAGE (FOR DOMESTIC ASSIGNMENT ONLY)"},
{id:"DR40 MANAGEMENT REFUSE TO COOPERATE",name:"DR40 MANAGEMENT REFUSE TO COOPERATE",value:"DR40 MANAGEMENT REFUSE TO COOPERATE"},
{id:"DR41 NEW OWNER REFUSE TO PAY PREVIOUS OWNER OUTSTANDING",name:"DR41 NEW OWNER REFUSE TO PAY PREVIOUS OWNER OUTSTANDING",value:"DR41 NEW OWNER REFUSE TO PAY PREVIOUS OWNER OUTSTANDING"},
{id:"DR42 CUSTOMER REFUSE TO MEET DCA",name:"DR42 CUSTOMER REFUSE TO MEET DCA",value:"DR42 CUSTOMER REFUSE TO MEET DCA"},
{id:"DR45 POLITICAL PARTIES ADVISE CUSTOMER NOT TO PAY IWK CHARGE",name:"DR45 POLITICAL PARTIES ADVISE CUSTOMER NOT TO PAY IWK CHARGE",value:"DR45 POLITICAL PARTIES ADVISE CUSTOMER NOT TO PAY IWK CHARGE"},
{id:"DR46 NOT RECEIVED BILL",name:"DR46 NOT RECEIVED BILL",value:"DR46 NOT RECEIVED BILL"},
{id:"DR47 CLAIM PAID TO IST TANKER",name:"DR47 CLAIM PAID TO IST TANKER",value:"DR47 CLAIM PAID TO IST TANKER"},
{id:"DR48 PREMISE OCCUPY BY FOREIGNER-COMMUNICATION ISSUES",name:"DR48 PREMISE OCCUPY BY FOREIGNER-COMMUNICATION ISSUES",value:"DR48 PREMISE OCCUPY BY FOREIGNER-COMMUNICATION ISSUES"},
{id:"DR50 WELFARE SOCIETY-CANT AFFORD TO PAY",name:"DR50 WELFARE SOCIETY-CANT AFFORD TO PAY",value:"DR50 WELFARE SOCIETY-CANT AFFORD TO PAY"},
{id:"DR52 DROP",name:"DR52 DROP",value:"DR52 DROP"},
{id:"DR53 PROPERTY SOLD & CURRENT NAME UNDER OLD OWNER",name:"DR53 PROPERTY SOLD & CURRENT NAME UNDER OLD OWNER",value:"DR53 PROPERTY SOLD & CURRENT NAME UNDER OLD OWNER"},
{id:"DR54 PROPERTY UNDER GOVERNMENT",name:"DR54 PROPERTY UNDER GOVERNMENT",value:"DR54 PROPERTY UNDER GOVERNMENT"},]

import React, { useEffect, useRef, useState } from "react";
import "react-toastify/dist/ReactToastify.css"; // import first
import DetailView from "./tables/details/details";
import SiteView from "./tables/siteview";
import CallDetails from "./tables/callDetails";
import Instruction from "./tables/instruction";
import Layout from "../../components/layout/Navbar";
import axios from "../../axios";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  CircularProgress,
  Hidden,
  LinearProgress,
  Typography,
} from "@mui/material";
import { makeStyles } from "@material-ui/core";
import ReactPaginate from "react-paginate";
import SearchInput from "../../components/input/searchInput";
import { useNavigate } from "react-router-dom";
import FilterListIcon from "@mui/icons-material/FilterList";
import DisplayImage from "../../components/layout/displayImage";
import BasicSelect from "../../components/customCore/select";
import BasicSelect2 from "../../components/customCore/jobNameDroupDown";
import {
  CallStatusCM,
  categoryTypeAll,
  UpdateType,
} from "../../api/detailsApi";
import moment from "moment";
import RiderSelect from "../../components/customCore/riderDroupDown";
import { Form } from "devextreme-react";
import { confirmAlert } from "react-confirm-alert";
import AlertBox from "./multiStaus";
// import {  axios } from "axios";

const useStyles = makeStyles((theme) => ({
  atag: {
    display: "block !important",
    textDecoration: "none !important",
    color: "inherit !important",
  },
  Progress: {
    margin: "9px",
    width: "24px !important",
    height: "24px !important",
  },
  update: {
    backgroundColor: "#32c232",
    padding: "6px",
    borderRadius: "12px",
    color: "#fff",
    width: "120px",
  },

  notupdate: {
    backgroundColor: "#e21717",
    padding: "6px",
    borderRadius: "12px",
    color: "#fff",
    width: "120px",
  },

  formMain: {
    display: "flex",
    justifyContent: "space-around",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  select1: {
    margin: "12px",
  },
  select3: {
    width: "200px",
    margin: "12px",
    [theme.breakpoints.down("md")]: {
      width: "300px",
    },
  },

  subDiv: {
    display: "flex",
    justifyContent: "space-between",
    maxWidth: "900px",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      width: "300px",
      margin: "0 auto",
    },
  },
  label: {
    paddingLeft: "40px",
    fontSize: "16px",
    color: "#00000A",
    opacity: "0.6",
    fontWeight: 600,
    paddingBottom: "4px",
  },
  filter: {
    width: "140px",
    margin: "12px",
    border: "1px solid #ccc",
    height: "45px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "16px",
    fontFamily: "Poppins",
    borderRadius: "50px",
    [theme.breakpoints.down("md")]: {
      width: "300px",
      flexDirection: "column",
    },
    backgroundColor: "#501abf",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#fff",
      color: "#501abf",
      border: "2px solid #501abf",
      fontWeight: "600",
      "& span": {
        color: "#501abf !important",
      },
    },
    "& span": {
      color: "#fff !important",
    },
  },
  mainSearchDiv: {
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
    flexWrap: "wrap",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      flexDirection: "column",
    },
  },
  SearchDiv: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    width: "300px",
    [theme.breakpoints.down("xs")]: {
      width: "75%",
    },
  },
  table: {
    "& tr": {
      "&:hover": {
        transform: "scale(1.0001)",
        WebkitBoxShadow: "0px 15px 20px -12px rgba(0, 0, 0, 0.75)",
        MozBoxShadow: "0px 15px 20px -12px rgba(0, 0, 0, 0.75)",
        boxShadow: "0px 15px 20px -12px rgba(0, 0, 0, 0.75)",
        cursor: "pointer",
      },
    },
  },
  inputDiv: {
    position: "relative",
    width: "100%",
    marginTop: "17px",
    marginBottom: "17px",
  },
  InputDivInput: {
    width: "100%",
    height: "45px",
    border: "1px solid #ccc",
    borderRadius: "100px",
    paddingLeft: "22px",
    paddingRight: "22px",
    lineHeight: 1,
    boxSizing: "border-box",
    outline: "none",
    letterSpacing: "0px",
    color: " #afafaf",
    textAlign: "left",
    font: "normal 30px Poppins",
    fontSize: "14px !important",
    maxWidth: "100px",
    marginLeft: "12px",
  },
  select2: {
    minWidth: "300px",
    display: "flex",
  },
  inputiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
function CallManager() {
  const classes = useStyles();
  const [GetId, setGetId] = useState("");
  const [SearchWord, setSearchWord] = useState("");
  let navigate = useNavigate();
  const Details = useRef(null);

  const [SelectUserObject, setSelectUserObject] = useState(null);
  const [SelectInput, setSelectInput] = useState("Details");
  const [Category, setCategory] = React.useState([]);
  const [RiderData, setRiderData] = React.useState([]);
  const [UserSelectCategory, setUserSelectCategory] = React.useState("");
  const [Option, setOption] = useState("");
  const [UpdateValue, setUpdateValue] = useState("2");
  const [RiderStatus, setRiderStatus] = useState("2");
  const [RiderId, setRiderId] = useState("ALL");
  // setUpdateValue
  const [AllData, setAllData] = useState([]);
  const [Disabled, setDisabled] = React.useState(false);
  const [filterByJobnameAndCategorydata, setfilterByJobnameAndCategoryData] =
    useState([]);

  const AdminId = JSON.parse(localStorage.getItem("auth")).message.id || "";
  // paggnination
  const itemsPerPage = 25;

  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const [Loading, setLoading] = useState(false);

  const [To, setTo] = useState("");
  const [From, setFrom] = useState("");

  useEffect(() => {
    setDisabled(true);
    if (Option) {
      axios
        .get(`/jobname/${Option}/${AdminId}`)
        .then((res) => {
          setDisabled(false);
          axios
            .get("/rider")
            .then((res) => {
              let data = res.data;
              let filterData =
                data.filter((item) => item.adminId === AdminId) || [];
              setRiderData(filterData);
            })
            .catch((err) => {
              console.log("error");
            });
          setCategory(res.data);
        })
        .catch((err) => {
          setDisabled(false);
          setCategory([]);
        });
    }
  }, [Option, AdminId]);
  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(
      filterByJobnameAndCategorydata.slice(itemOffset, endOffset)
    );
    setPageCount(
      Math.ceil(filterByJobnameAndCategorydata.length / itemsPerPage)
    );
  }, [itemOffset, itemsPerPage, filterByJobnameAndCategorydata]);

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % filterByJobnameAndCategorydata.length;
    setItemOffset(newOffset);
  };
  const handleInputChange = (e) => {
    const { value } = e.target;
    setSelectInput(value);
  };

  const findUser = (e) => {
    setGetId(e.id);
    setSelectUserObject(e);
    navigate("#details");
  };
  // const handleFiltering = () => {
  //   if (From != "" && To != "") {
  //     handleFilterings();
  //   } else {
  //     console.log(From, To);
  //   }
  // };
  // https://api.apancollections.com/riderdata/filterByJobnameAndCategoryCallManager/commercials/602/2/All/All/2000/2010
  const handleFiltering = () => {
    // Disabled
    setLoading(false);
    setfilterByJobnameAndCategoryData([]);
    setDisabled(true);
    // if (parsedDetails) {
    //   axios
    //     .get(
    //       `riderdata/filterByJobnameAndCategoryCallManager/${parsedDetails.category}/${parsedDetails.jobId}/${UpdateValue}/${RiderStatus}/${RiderId}/${parsedDetails.dataFrom}/${parsedDetails.dataTo}`
    //     )
    //     .then((res) => {
    //       console.log(res.data);
    //       setDisabled(false);
    //       setfilterByJobnameAndCategoryData(res.data);
    //       setAllData(res.data);

    //       setLoading(true);
    //     })
    //     .catch((err) => {
    //       setDisabled(false);
    //       setCategory([]);
    //       setLoading(true);
    //     });
    // } else {
    axios
      .get(
        `riderdata/filterByJobnameAndCategoryCallManager/${Option}/${UserSelectCategory}/${UpdateValue}/${RiderStatus}/${RiderId}/${From}/${To}`
      )
      .then((res) => {
        console.log(res)
        setDisabled(false);
        setfilterByJobnameAndCategoryData(res.data);
        setAllData(res.data);

        setLoading(true);
      })
      .catch((err) => {
        setDisabled(false);
        setCategory([]);
        setLoading(true);
      });
    // }
  };
  const searchHandle = (e) => {
    setSearchWord(e.target.value);
    const searching =
      AllData.filter(
        (item) =>
          String(item.SAN).startsWith(e.target.value) ||
          String(item.id).startsWith(e.target.value)
      ) || [];
    setfilterByJobnameAndCategoryData(searching);
  };
  const onSelectValue = (value, event) => {
    setDisabled(false);
    setOption(value);
  };
  const onSelectValue2 = (value, event) => {
    setUserSelectCategory(value);
  };
  const onSelectValue3 = (value, event) => {
    setUpdateValue(value);
  };
  const onSelectValue4 = (value, event) => {
    setRiderStatus(value);
  };
  const onSelectValue5 = (value, event) => {
    setRiderId(value);
  };
  // onSelectValue5
  const removeZ = (str) => {
    // var b = "e";
    // var removeLastChar = "";
    // removeLastChar = str.slice(0, str.length - 1);
    // return removeLastChar;
    const updatedAt = new Date(str);
    updatedAt.setMinutes(updatedAt.getMinutes());
    const formattedDate = `${updatedAt.getFullYear()}-${(
      updatedAt.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${updatedAt
      .getDate()
      .toString()
      .padStart(2, "0")} ${updatedAt.toLocaleTimeString()}`;
    return formattedDate;
  };

  const rowDetails = localStorage.getItem("riderDetails");
  const parsedDetails = JSON.parse(rowDetails);

  useEffect(() => {
    if (parsedDetails?.dataFrom) {
      setFrom(parsedDetails?.dataFrom);
    }
  }, []);

  useEffect(() => {
    if (parsedDetails?.dataTo) {
      setTo(parsedDetails?.dataTo);
    }
  }, []);

  // useEffect(() => {
  //   if (jobCategoryType) {
  //     setOption(jobCategoryType);
  //   }
  // });

  useEffect(() => {
    if (parsedDetails?.jobId) {
      setUserSelectCategory(parsedDetails?.jobId);
    }
  }, []);

  const [selectedRows, setSelectedRows] = useState([]);

  const handleRowSelection = (rowId) => {
    setSelectedRows((prevSelectedRows) => {
      if (prevSelectedRows.includes(rowId)) {
        return prevSelectedRows.filter((id) => id !== rowId);
      } else {
        return [...prevSelectedRows, rowId];
      }
    });
  };

  const getStatusOfSelectedRows = () => {
    const selectedStatus = selectedRows.map((rowId) => {
      const selectedRow = currentItems.find((row) => row.id === rowId);
      return selectedRow.status;
    });

    return selectedStatus;
  };

  const [numberValue, setNumberValue] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const handleSelectField = (e) => {
    setNumberValue(e.target.value);
    if (selectedRows.length > 0) {
      setIsButtonDisabled(false);
    }
  };

  const handleSubmitStatus = () => {
    let data = {
      updateStatus: numberValue,
      idList: selectedRows,
    };
    axios
      .put(`${Option}/updateMultipleStatus`, data)
      .then((res) => {
        setSelectedRows([]);
        setIsButtonDisabled(true);
        handleFiltering();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const changeStatus = (e) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        let apiCompleted = false;

        const handleConfirm = () => {
          handleSubmitStatus();
          apiCompleted = true;
          onClose();
        };

        return <AlertBox onClose={onClose} onClick={handleConfirm} />;
      },
    });
  };

  return (
    <Layout>
      <div style={{ width: "300px" }}></div>
      <br />
      <div className={classes.mainSearchDiv}>
        <div className="rider-info">Data Manager</div>
        <div className={classes.SearchDiv}>
          <SearchInput
            icon={<SearchIcon />}
            onChange={(e) => searchHandle(e)}
            value={SearchWord}
            placeholder="Search By Account No"
          />
        </div>
      </div>
      <br />
      <div className={classes.subDiv}>
        <div className={classes.select1}>
          <BasicSelect
            options={categoryTypeAll}
            value={parsedDetails?.category || ""}
            onSelectValue={onSelectValue}
            width="180px"
          />
        </div>
        <div className={classes.select1}>
          <BasicSelect2
            options={Category}
            value
            onSelectValue={onSelectValue2}
          />
        </div>
        <div className={classes.inputiv}>
          <input
            type="number"
            className={classes.InputDivInput}
            placeholder="From"
            value={From}
            onChange={(e) => setFrom(e.target.value)}
          />
        </div>
        <div className={classes.inputiv}>
          <input
            type="number"
            className={classes.InputDivInput}
            placeholder="To"
            value={To}
            onChange={(e) => setTo(e.target.value)}
          />
        </div>
        <button
          type="submit"
          onClick={handleFiltering}
          className={classes.filter}
          disabled={Disabled}
          style={{ minWidth: "80px" }}
        >
          {Disabled ? (
            <CircularProgress className={classes.Progress} />
          ) : (
            <div>
              <FilterListIcon />
            </div>
          )}
        </button>
      </div>
      <div className={classes.subDiv} hidden={true}>
        <div className={classes.select3}>
          <RiderSelect
            options={RiderData}
            value
            onSelectValue={onSelectValue5}
          />
        </div>

        <div className={classes.select2}>
          <div className={classes.select3}>
            <BasicSelect
              options={UpdateType}
              value
              onSelectValue={onSelectValue3}
            />
          </div>
          <div className={classes.select3}>
            <BasicSelect
              options={CallStatusCM}
              value
              onSelectValue={onSelectValue4}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          overflowX: "auto",
          marginTop: "2rem",
        }}
        className="table-telecaller"
      >
        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            borderSpacing: 0,
          }}
          className={classes.table}
        >
          {Option === "vacants" && (
            <tbody>
              <tr
                style={{
                  pointerEvents: "none",
                }}
              >
                <th></th>
                <th
                  style={{
                    textAlign: "left",
                    padding: "10px !important",
                    width: "100px",
                  }}
                >
                  <Typography variant="body">ID</Typography>
                </th>
                <th>
                  <span variant="body">SEWACC</span>
                </th>
                <th>
                  <span variant="body">Owner Name</span>
                </th>
                <th>
                  <span variant="body">Address</span>
                </th>
                <th>
                  <span variant="body">Water Meter Number</span>
                </th>
                <th>
                  <span variant="body">Water Image</span>
                </th>
                <th>
                  <span variant="body">Multi Images</span>
                </th>

                <th>
                  <span variant="body">updated Time</span>
                </th>
                <th>
                  <span variant="body">Fullname</span>
                </th>
                <th>
                  <span variant="body">Status</span>
                </th>
              </tr>
            </tbody>
          )}
          {Option === "vacants" && (
            <tbody>
              {currentItems &&
                currentItems.length > 0 &&
                currentItems.map((data, i) => (
                  <tr>
                    <td>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                          style={{
                            cursor: "pointer",
                            width: "15px",
                            height: "15px",
                          }}
                          checked={selectedRows.includes(data.id)}
                          onChange={() => handleRowSelection(data.id)}
                        />
                      </div>
                    </td>
                    <td onClick={() => findUser(data)} key={i}>
                      <a href="#details" className={classes.atag}>
                        <Typography variant="body">{data.excelId}</Typography>
                      </a>
                    </td>
                    <td onClick={() => findUser(data)} key={i}>
                      <a href="#details" className={classes.atag}>
                        <Typography variant="body">{data.SEWACC}</Typography>
                      </a>
                    </td>
                    <td onClick={() => findUser(data)} key={i}>
                      <a href="#details" className={classes.atag}>
                        <p
                          style={{
                            textAlign: "left",
                            margin: "0px",
                            paddingLeft: "16px",
                          }}
                        >
                          {data.OWNER_NAME}
                        </p>
                      </a>
                    </td>
                    <td onClick={() => findUser(data)} key={i}>
                      <a href="#details" className={classes.atag}>
                        <p
                          style={{
                            textAlign: "left",
                            margin: "0px",
                            paddingLeft: "16px",
                          }}
                        >
                          {data.PROP_ADD}
                        </p>
                      </a>
                    </td>
                    <td onClick={() => findUser(data)} key={i}>
                      <a href="#details" className={classes.atag}>
                        <p
                          style={{
                            textAlign: "left",
                            margin: "0px",
                            paddingLeft: "16px",
                          }}
                        >
                          {data.waterMeterNumber}
                        </p>
                      </a>
                    </td>
                    <DisplayImage image={data.image} Option={Option} />

                    <DisplayImage image={data.multiImage} Option={Option} />

                    <td onClick={() => findUser(data)} key={i}>
                      <a
                        href="#details"
                        className={classes.atag}
                        style={{ minWidth: "100px" }}
                      >
                        <Typography variant="body">
                          {data.updatedTime ? removeZ(data.updatedTime) : ""}
                        </Typography>
                      </a>
                    </td>

                    <td onClick={() => findUser(data)} key={i}>
                      <a href="#details" className={classes.atag}>
                        <Typography variant="body">{data.fullName}</Typography>
                      </a>
                    </td>

                    <td onClick={() => findUser(data)} key={i}>
                      <a href="#details" className={classes.atag}>
                        <div
                          className={
                            data.updateStatus === 0
                              ? classes.notupdate
                              : classes.update
                          }
                        >
                          {data.updateStatus === 0 ? "Not Updated" : "Updated"}
                        </div>
                      </a>
                    </td>
                  </tr>
                ))}
            </tbody>
          )}
          {Option != "vacants" && (
            <tbody>
              <tr
                style={{
                  pointerEvents: "none",
                }}
              >
                <th></th>
                <th
                  style={{
                    textAlign: "left",
                    padding: "10px !important",
                    width: "100px",
                  }}
                >
                  <Typography variant="body">ID</Typography>
                </th>
                <th>
                  <span variant="body">Account Number</span>
                </th>
                <th>
                  <span variant="body">DCA Name</span>
                </th>
                <th>
                  <span variant="body">Debtor Name</span>
                </th>
                <th>
                  <span variant="body">Range</span>
                </th>
                <th>
                  <span variant="body">Arrears</span>
                </th>
                <th>
                  <span variant="body">Water Meter Number</span>
                </th>
                <th>
                  <span variant="body">Address</span>
                </th>
                <th>
                  <span variant="body">Images</span>
                </th>
                <th>
                  <span variant="body">Meter Image</span>
                </th>
                <th>
                  <span variant="body">Date</span>
                </th>
                <th>
                  <span variant="body">FullName</span>
                </th>
                <th>
                  <span variant="body">Status</span>
                </th>
              </tr>
            </tbody>
          )}
          <tbody>
            {!Loading && Disabled && (
              <tr
                style={{
                  borderCollapse: "collapse",
                  padding: "0px !important",
                }}
              >
                <td
                  style={{
                    textAlign: "center",
                    verticalAlign: "middle",
                    padding: "0px !important",
                  }}
                  colSpan="22"
                >
                  <Box sx={{ width: "100%" }}>
                    <LinearProgress />
                  </Box>
                </td>
              </tr>
            )}
          </tbody>
          <tbody>
            {currentItems &&
              currentItems.length === 0 &&
              !(!Loading && Disabled) && (
                <tr
                  style={{
                    borderCollapse: "collapse",
                    padding: "0px !important",
                    boxShadow: "none",
                    WebkitBoxShadow: "none",
                  }}
                >
                  <td
                    style={{
                      textAlign: "center",
                      verticalAlign: "middle",
                      padding: "0px !important",
                      WebkitBoxShadow: "none !important",
                      boxShadow: "none !important",
                    }}
                    colSpan="22"
                  >
                    <Box sx={{ width: "100%" }}>No Data</Box>
                  </td>
                </tr>
              )}
          </tbody>
          {Option != "vacants" && (
            <tbody>
              {currentItems &&
                currentItems.length > 0 &&
                currentItems.map((data, i) => (
                  <tr>
                    <td>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                          style={{
                            cursor: "pointer",
                            width: "15px",
                            height: "15px",
                          }}
                          checked={selectedRows.includes(data.id)}
                          onChange={() => handleRowSelection(data.id)}
                        />
                      </div>
                    </td>
                    <td onClick={() => findUser(data)} key={i}>
                      <a href="#details" className={classes.atag}>
                        <Typography variant="body">{data.excelId}</Typography>
                      </a>
                    </td>
                    <td onClick={() => findUser(data)} key={i}>
                      <a href="#details" className={classes.atag}>
                        <Typography variant="body">{data.SAN}</Typography>
                      </a>
                    </td>
                    <td onClick={() => findUser(data)} key={i}>
                      <a href="#details" className={classes.atag}>
                        <p
                          style={{
                            textAlign: "left",
                            margin: "0px",
                            paddingLeft: "16px",
                          }}
                        >
                          {data.DCAName}
                        </p>
                      </a>
                    </td>
                    <td onClick={() => findUser(data)} key={i}>
                      <a href="#details" className={classes.atag}>
                        <p
                          style={{
                            textAlign: "left",
                            margin: "0px",
                            paddingLeft: "16px",
                          }}
                        >
                          {data.Owner1}
                        </p>
                      </a>
                    </td>
                    <td onClick={() => findUser(data)} key={i}>
                      <a href="#details" className={classes.atag}>
                        <Typography variant="body">{data.Range}</Typography>
                      </a>
                    </td>
                    <td onClick={() => findUser(data)} key={i}>
                      <a href="#details" className={classes.atag}>
                        <Typography variant="body">{data.Arrears}</Typography>
                      </a>
                    </td>
                    <td onClick={() => findUser(data)} key={i}>
                      <a href="#details" className={classes.atag}>
                        <Typography variant="body">
                          {data.waterMeterNumber}
                        </Typography>
                      </a>
                    </td>
                    <td onClick={() => findUser(data)} key={i}>
                      <a href="#details" className={classes.atag}>
                        <Typography variant="body">
                          {data.MailAdd1} {data.MailAdd2} {data.MailAdd3} {data.MailAdd4}
                        </Typography>
                      </a>
                    </td>
                    <DisplayImage image={data.multiImage} Option={Option} />
                    <DisplayImage image={data.meterImage} Option={Option} />
                    <td onClick={() => findUser(data)} key={i}>
                      <a
                        href="#details"
                        className={classes.atag}
                        style={{ minWidth: "100px" }}
                      >
                        <Typography variant="body">
                          {data.updatedTime ? removeZ(data.updatedTime) : ""}
                        </Typography>
                      </a>
                    </td>
                    <td onClick={() => findUser(data)} key={i}>
                      <a href="#details" className={classes.atag}>
                        <Typography variant="body">{data.fullName}</Typography>
                      </a>
                    </td>

                    <td onClick={() => findUser(data)} key={i}>
                      <a href="#details" className={classes.atag}>
                        <div
                          className={
                            data.updateStatus === 0
                              ? classes.notupdate
                              : classes.update
                          }
                        >
                          {data.updateStatus === 0 ? "Not Updated" : "Updated"}
                        </div>
                      </a>
                    </td>
                  </tr>
                ))}
            </tbody>
          )}
        </table>
        <br />
        <ReactPaginate
          breakLabel="..."
          nextLabel="next >"
          onPageChange={handlePageClick}
          pageCount={pageCount}
          previousLabel="< previous"
          renderOnZeroPageCount={null}
          pageRangeDisplayed={3}
          marginPagesDisplayed={3}
          containerClassName={"pagination justify-content-end"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          activeClassName={"active"}
        />
      </div>
      {currentItems && currentItems.length > 0 && (
        <div className="row">
          <div className={classes.mainSearchDiv}>
            <div className="rider-info">Multiple Record Update</div>
          </div>
          <div className="col-md-2 mt-4">
            <select
              className="form-select mb-4"
              aria-label="Default select example"
              onChange={handleSelectField}
            >
              <option name="" value="" disabled selected>
                Select
              </option>
              <option name="updated" value="1">
                Updated
              </option>
              <option name="notupdated" value="0">
                Not Updated
              </option>
              <option name="reedo" value="2">
                Redo
              </option>
            </select>
          </div>
          <div className="col-md-2 mt-4">
            <button
              style={{
                color: "#fff",
                width: "170px",
                height: "45px",
                border: "1px solid #501abf",
                borderRadius: "44px",
                backgroundColor: "#501abf",
                opacity: isButtonDisabled ? 0.4 : 1,
              }}
              disabled={isButtonDisabled}
              onClick={changeStatus}
              className={isButtonDisabled ? "disabled" : ""}
            >
              Submit
            </button>
          </div>
        </div>
      )}
      <div id="details">
        {GetId !== "" && (
          <>
            <div
              style={{
                width: "200px",
                margin: "0 0 2rem auto",
                height: "45px",
              }}
              ref={Details}
            >
              {/* <select
                style={{
                  height: "100%",
                  color: "#501ABF",
                  border: "1px solid #501ABF",
                }}
                className="form-select"
                aria-label="Default select example"
                name=""
                onChange={handleInputChange}
              >
                <option name="Details" value="Details">
                  Details Veiw
                </option>
                <option name="Site" value="Site">
                  Site View Details
                </option>
                <option name="Call" value="Call">
                  Call Details
                </option>
                <option name="Instruction" value="Instruction">
                  Instruction
                </option>
              </select> */}
            </div>
            <div className="tab-content" id="pills-tabContent">
              {SelectInput === "Details" && (
                <DetailView
                  SelectUserObject={SelectUserObject}
                  Option={Option}
                />
              )}
              {SelectInput === "Site" && (
                <SiteView SelectUserObject={SelectUserObject} Option={Option} />
              )}
              {SelectInput === "Call" && (
                <CallDetails
                  SelectUserObject={SelectUserObject}
                  Option={Option}
                />
              )}
              {SelectInput === "Instruction" && (
                <Instruction
                  SelectUserObject={SelectUserObject}
                  Option={Option}
                />
              )}
            </div>
          </>
        )}
      </div>
    </Layout>
  );
}
export default CallManager;

import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import { createTheme } from "@mui/material/styles";
import axios from "../../axiosHeader";
import AddRider from "../../components/Modal/AddRiderPopup";
import DeleteIcon from "@mui/icons-material/Delete";
import Layout from "../../components/layout/Navbar";
import "./rider.css";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { Avatar } from "@mui/material";
import ReactPaginate from "react-paginate";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import EditIcon from "@mui/icons-material/Edit";
import AlertBox from "../../components/layout/alertBox";
import { useToasts } from "react-toast-notifications";
import RiderImage from "../../components/Modal/RiderImage";
import RiderPassword from "../../components/Modal/editPassword";
const theme = createTheme();

theme.typography.h3 = {
  typography: {
    fontFamily: ["Poppins"].join(","),
  },

  fontSize: "1.8rem",
  fontWeight: 400,
  fontStyle: "normal",

  "@media (max-width:850px)": {
    fontSize: "1.5rem",
  },
  "@media (max-width:400px)": {
    fontSize: "1rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.8rem",
  },
};
theme.typography.h1 = {
  typography: {
    fontFamily: ["Poppins"].join(","),
  },

  fontSize: "2.5rem",
  color: "#5016BF",
  "@media (max-width:800px)": {
    fontSize: "1.5rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "2.4rem",
  },
};

function Newrider() {
  const [openModel, setOpenModel] = useState(false);
  const [EditId, setEditId] = useState("");
  const [EditOpenModel, setEditOpenModel] = useState(false);
  const [editImageId, setEditImageid] = useState("");
  const [editImageOpenModel, setEditImageOpenModel] = useState(false);
  const [editPassword, setEditPassword] = useState("");
  const [editPassowrdOpenModel, setEditPassowrdOpenModel] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const Auth = localStorage.getItem("userInfor");
  const message = JSON.parse(Auth);
  // paggnination
  const itemsPerPage = 10;

  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  const token = message.message.token;
  const AdminId = message.message.id;
  const { addToast } = useToasts();

  useEffect(() => {
    axios
      .get("/rider/getByAdminId/" + AdminId)
      .then((res) => {
        setData(res.data);
        setLoading(true);
      })
      .catch((error) => {
        setLoading(true);
      });
  }, []);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(data.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, data]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % data.length;
    setItemOffset(newOffset);
  };

  const deleteHandle = (e) => {
    let id = e.id;

    axios
      .delete(`/rider/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setLoading(true);
        addToast("Successfully Deleted", {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 3000,
        });
        setTimeout(() => {
          window.location.href = "/rider";
        }, 2000);
      })
      .catch((error) => {
        setLoading(true);
        addToast("Something Went Wrong", {
          appearance: "error",
          autoDismiss: "true",
          autoDismissTimeout: 2000,
        });
      });
  };
  const Deleting = (e) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <AlertBox
            onClose={onClose}
            onClick={() => {
              deleteHandle(e);
            }}
          />
        );
      },
    });
  };
  const Editing = (e) => {
    setEditId(e);
    setEditOpenModel(true);
  };
  const EditImage = (e) => {
    setEditImageid(e);
    setEditImageOpenModel(true);
  };
  const EditPassword = (e) => {
    setEditPassword(e);
    setEditPassowrdOpenModel(true);
  };
  return (
    <Layout title="Rider">
      <div className="rider-tble">
        <div className="rider-head-details">
          <div className="rider-info">Human Resource</div>
          <button
            className="button-cus"
            onClick={() => {
              setOpenModel(true);
            }}
          >
            Add New
          </button>
        </div>
        <div className="table-rider">
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              borderSpacing: 0,
            }}
          >
            <tbody>
              <tr>
                <th>
                  <Typography variant="body">#</Typography>{" "}
                </th>
                <th>
                  {" "}
                  <Typography variant="body">ID</Typography>{" "}
                </th>
                <th>
                  <Typography variant="body">Image</Typography>{" "}
                </th>
                <th>
                  <Typography variant="body">Name</Typography>{" "}
                </th>
                <th>
                  <Typography variant="body">Email</Typography>{" "}
                </th>
                <th>
                  <Typography variant="body">Position</Typography>{" "}
                </th>
                <th>
                  <Typography variant="body">NRIC</Typography>{" "}
                </th>
                <th>
                  <Typography variant="body">Status</Typography>{" "}
                </th>
                <th>
                  <Typography variant="body">Edit</Typography>{" "}
                </th>
                <th>
                  <Typography variant="body">P.W</Typography>{" "}
                </th>
                <th>
                  <Typography variant="body">Image Edit</Typography>{" "}
                </th>
                <th>
                  <Typography variant="body">Delete</Typography>{" "}
                </th>
              </tr>
            </tbody>

            <tbody>
              {!Loading && (
                <tr
                  style={{
                    borderCollapse: "collapse",
                    padding: "0px !important",
                  }}
                >
                  <td
                    style={{
                      textAlign: "center",
                      verticalAlign: "middle",
                      padding: "0px !important",
                    }}
                    colSpan="12"
                  >
                    <Box sx={{ width: "100%" }}>
                      <LinearProgress />
                    </Box>
                  </td>
                </tr>
              )}
            </tbody>

            <tbody>
              {currentItems &&
                currentItems.length > 0 &&
                currentItems.map((data, i) => (
                  <tr key={i}>
                    <td>
                      <Typography variant="body">{i + 1}</Typography>
                    </td>
                    <td>
                      <Typography variant="body">{data.id}</Typography>
                    </td>
                    <td align="center">
                      <Avatar
                        sx={{
                          width: "40px",
                          height: "40px",
                          textTransform: "capitalize",
                          textAlign: "center",
                          margin: "0 auto",
                        }}
                        src={axios.defaults.baseURL + "/images/" + data.image}
                        alt="user"
                      >
                        {data.fullName[0]}
                      </Avatar>
                    </td>
                    <td>
                      <p
                        style={{
                          textAlign: "left",
                          margin: "0px",
                          paddingLeft: "16px",
                        }}
                      >
                        {data.fullName}
                      </p>
                    </td>
                    <td>
                      <p
                        style={{
                          textAlign: "left",
                          margin: "0px",
                          paddingLeft: "16px",
                        }}
                      >
                        {data.email}
                      </p>
                    </td>

                    <td>
                      <p
                        style={{
                          textAlign: "left",
                          margin: "0px",
                          paddingLeft: "16px",
                        }}
                      >
                        {data.position}
                      </p>
                    </td>
                    <td>
                      <p
                        style={{
                          textAlign: "left",
                          margin: "0px",
                          paddingLeft: "16px",
                        }}
                      >
                        {data.NRIC}
                      </p>
                    </td>
                    <td>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div
                          className={` dotStatus ${
                            data.status === "Active"
                              ? "d-Ractive"
                              : "d-Rinactive"
                          }`}
                        ></div>{" "}
                        <span
                          style={{
                            paddingLeft: "10px",
                          }}
                        >
                          {" "}
                          {data.status ? data.status : "Inactive"}
                        </span>
                      </div>
                    </td>

                    <td onClick={() => Editing(data)}>
                      <EditIcon
                        sx={{
                          color: "#008000c2",
                          opacity: "0.8",
                          ": &hover": { opacity: "1" },
                          cursor: "pointer",
                        }}
                      />
                    </td>
                    <td onClick={() => EditPassword(data)}>
                      <EditIcon
                        sx={{
                          color: "#008000c2",
                          opacity: "0.8",
                          ": &hover": { opacity: "1" },
                          cursor: "pointer",
                        }}
                      />
                    </td>
                    <td onClick={() => EditImage(data)}>
                      <EditIcon
                        sx={{
                          color: "#008000c2",
                          opacity: "0.8",
                          ": &hover": { opacity: "1" },
                          cursor: "pointer",
                        }}
                      />
                    </td>
                    <td onClick={() => Deleting(data)}>
                      <DeleteIcon
                        sx={{
                          color: "red",
                          opacity: "0.8",
                          ": &hover": { opacity: "1" },
                          cursor: "pointer",
                        }}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
            <tbody>
              {data.length === 0 && Loading && (
                <tr style={{ borderCollapse: "collapse" }}>
                  <td
                    style={{
                      textAlign: "center",
                      verticalAlign: "middle",
                    }}
                    colSpan="12"
                  >
                    <Typography variant="body">No data</Typography>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <br />

          <ReactPaginate
            breakLabel="..."
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageCount={pageCount}
            previousLabel="< previous"
            renderOnZeroPageCount={null}
            pageRangeDisplayed={1}
            marginPagesDisplayed={1}
            containerClassName={"pagination justify-content-end"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        </div>
      </div>

      {openModel && <AddRider closeModel={setOpenModel} />}
      {EditOpenModel && (
        <AddRider closeModel={setEditOpenModel} edit editId={EditId} />
      )}
      {editImageOpenModel && (
        <RiderImage
          closeModel={setEditImageOpenModel}
          editImageId={editImageId}
        />
      )}
      {editPassowrdOpenModel && (
        <RiderPassword
          closeModel={setEditPassowrdOpenModel}
          editPassword={editPassword}
        />
      )}
    </Layout>
  );
}

export default Newrider;

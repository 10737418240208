export const OccupiedOrVacantOthersArray = [
  {
    id: "Terbakar (Burnt)",
    name: "Terbakar (Burnt)",
  },
  {
    id: "Terbengkalai (Abandoned)",
    name: "Terbengkalai (Abandoned)",
  },
  {
    id: "Dirobhkan (Demolished)",
    name: "Dirobhkan (Demolished)",
  },
  {
    id: "Digunakan untuk sarang burung (Used for bird nesting)",
    name: "Digunakan untuk sarang burung (Used for bird nesting)",
  },
  {
    id: "Tanah kosong (Vacant Land)",
    name: "Tanah kosong (Vacant Land)",
  },
  {
    id: "Dalam pengubahsuaian (Under renovation)",
    name: "Dalam pengubahsuaian (Under renovation)",
  },
];
export const CommercialOrDomesticOthersArray = [
  {
    id: "Premis Kerajaan (Goverment Premise)",
    name: "Premis Kerajaan (Goverment Premise)",
  },
  {
    id: "Kuarters Kerajaan (Goverment Quarters)",
    name: "Kuarters Kerajaan (Goverment Quarters)",
  },
  {
    id: "Industri (Industry)",
    name: "Industri (Industry)",
  },
  {
    id: "Tempat ibadat (Place of worship)",
    name: "Tempat ibadat (Place of worship)",
  },
];
export const YesNo = [
  {
    id: "1",
    name: "Yes",
  },
  {
    id: "2",
    name: "No",
  },
];
export const NoOption = [
  {
    id: "No Option",
  },
];

export const Gender = [
  {
    id: "1",
    name: "Male",
  },
  {
    id: "2",
    name: "Female",
  },
];
export const Nationality = [
  {
    id: "Malaysian",
    name: "Malaysian",
  },
  {
    id: "Foreigner",
    name: "Foreigner",
  },
];
export const CallStatus = [
  {
    id: "New",
    name: "New",
  },
  {
    id: "PAYMENT MADE AFTER DCA CALL",
    name: "PAYMENT MADE AFTER DCA CALL",
  },
  {
    id: "PROMISED TO PAY (PTP)",
    name: "PROMISED TO PAY (PTP)",
  },
  {
    id: "REFUSE TO PAY (RTP)",
    name: "REFUSE TO PAY (RTP)",
  },
  {
    id: "UNABLE TO PAY (UTP)",
    name: "UNABLE TO PAY (UTP)",
  },
  {
    id: "FURTHER EXPLANATION & FOLLOW UP REQUIRED",
    name: "FURTHER EXPLANATION & FOLLOW UP REQUIRED",
  },
  {
    id: "REQUESTED FOR INSTALLMENT PLAN",
    name: "REQUESTED FOR INSTALLMENT PLAN",
  },
  {
    id: "REQUESTED FOR STATEMENT OF ACCOUNT (SOA)",
    name: "REQUESTED FOR STATEMENT OF ACCOUNT (SOA)",
  },
  {
    id: "REQUESTED FOR COPY BILL",
    name: "REQUESTED FOR COPY BILL",
  },
  {
    id: "LEAVE MESSAGE TO CALL",
    name: "LEAVE MESSAGE TO CALL",
  },
  {
    id: "TENANT CLAIM OWNER TO PAY",
    name: "TENANT CLAIM OWNER TO PAY",
  },
  {
    id: "TENANT PAID TO OWNER",
    name: "TENANT PAID TO OWNER",
  },
  {
    id: "NEW TENANT/SAME OWNER",
    name: "NEW TENANT/SAME OWNER",
  },
  {
    id: "PAYMENT IS OUSTANDING BUT CLAIM PAID",
    name: "PAYMENT IS OUSTANDING BUT CLAIM PAID",
  },
  {
    id: "Parital Paid",
    name: "Parital Paid",
  },
  {
    id: "OTHERS",
    name: "OTHERS",
  },
  {
    id: "Revisit",
    name: "Revisit",
  },
];
export const RiderStatus = [
  {
    id: "Updated",
    name: "Updated",
  },
  {
    id: "Not Updated",
    name: "Not Updated",
  },
  {
    id:"Redo",
    name:"Redo"
  }
];
// RiderStatus
export const typeofbusinesstype = [
  { id: 2, name: "13 24 HRS SUNDRY SHOP (7 ELEVEN)" },
  { id: 3, name: "28 ACCESSORIES SHOP" },
  { id: 4, name: "89 BAKERY" },
  { id: 5, name: "27 BANK" },
  { id: 6, name: "46 BARBER SHOP" },
  { id: 7, name: "96 BEAUTY CENTRE" },
  { id: 8, name: "24 BICYCLE SHOP / REPAIR" },
  { id: 9, name: "44 BOOK STORE / STATIONERY SHOP" },
  { id: 10, name: "76 BOUTIQUE" },
  { id: 11, name: "36 BRIDAL SHOP" },
  { id: 12, name: "85 BUS STATION" },
  { id: 13, name: "139 CAFE" },
  { id: 14, name: "40 CANDY SHOP" },
  { id: 15, name: "14 CANTEEN" },
  { id: 16, name: "21 CAR ACCESSORIES SHOP" },
  { id: 17, name: "23 CAR PARK" },
  { id: 18, name: "20 CAR SERVICE CENTRE" },
  { id: 19, name: "22 CAR WASH" },
  { id: 20, name: "39 CARPET SHOP" },
  { id: 21, name: "100 CASKET & FUNERAL SERVICES" },
  { id: 22, name: "107 CHALET" },
  { id: 23, name: "101 CHARITY HOME" },
  { id: 24, name: "80 CHINESE MEDICAL HALL" },
  { id: 25, name: "05 CHINESE RESTAURANT" },
  { id: 26, name: "18 CHURCH" },
  { id: 27, name: "78 CINEMA" },
  { id: 28, name: "145 CLEANING SERVICES" },
  { id: 29, name: "87 COMMERCIAL COMPLEX" },
  { id: 30, name: "132 COURIER SERVICE SHOP" },
  { id: 31, name: "79 CUSTOMER SERVICE CENTRE" },
  { id: 32, name: "83 DANCING SCHOOL" },
  { id: 33, name: "94 DAY CARE CENTRE" },
  { id: 34, name: "129 DEPARTMENTAL STORE (JAYA JUSCO,SOGO,ETC)" },
  { id: 35, name: "68 DRIVING SCHOOL" },
  { id: 36, name: "30 ELECTRICAL SHOP" },
  { id: 37, name: "124 ENGINEERING FACTORY" },
  { id: 38, name: "126 ENTERTAINMENT OUTLET (I.E.KAROAKE)" },
  { id: 39, name: "123  FACTORY" },
  { id: 40, name: "12 FAST FOOD RESTAURANT" },
  { id: 41, name: "81 FISHING EQUIPMENT" },
  { id: 42, name: "120 FITNESS CENTRE / GYMNASIUM" },
  { id: 43, name: "72 FLORIST" },
  { id: 44, name: "13 FOOD COURT" },
  { id: 45, name: "47 FRAME SHOP" },
  { id: 46, name: "55 FROZEN FOOD SHOP" },
  { id: 47, name: "82 FRUIT SHOP" },
  { id: 48, name: "98 FURNITURE SHOP / HOME FURNISHING" },
  { id: 49, name: "71 GALLERY" },
  { id: 50, name: "130 GAMING OUTLET (SPORTS TOTO,MAGNUM,ETC)" },
  { id: 51, name: "140 GAS SHOP" },
  { id: 52, name: "74 GOLDSMITH" },
  { id: 53, name: "99 GOLF CLUB" },
  { id: 54, name: "136 GOVT BODIES" },
  { id: 55, name: "115 GOVT CLINIC" },
  { id: 56, name: "117 GOVT HOSPITAL" },
  { id: 57, name: "110 GOVT SCHOOL" },
  { id: 58, name: "42 HANDICRAFT SHOP" },
  { id: 59, name: "45 HANDPHONE / TELECOMMUNICATION SHOP" },
  { id: 60, name: "01 HARDWARE SHOP" },
  { id: 61, name: "92 HEALTHCARE CENTRE" },
  { id: 62, name: "125 HOME DÉCOR" },
  { id: 63, name: "106 HOSTEL" },
  { id: 64, name: "105 HOTEL" },
  { id: 65, name: "128 HYPERMARKET" },
  { id: 66, name: "143 ICE SUPPLIER" },
  { id: 67, name: "06 INDIAN RESTAURANT" },
  { id: 68, name: "90 INSURANCE COMPANY" },
  { id: 69, name: "118 INTERNET CAFÉ" },
  { id: 70, name: "50 IT RELATED SHOP" },
  { id: 71, name: "09 JAPANESE RESTAURANT" },
  { id: 72, name: "60 JOB PLACEMENT AGENCY" },
  { id: 73, name: "112 KINDERGARTEN" },
  { id: 74, name: "10 KOREAN RESTAURANT" },
  { id: 75, name: "03 LAUNDRY / DRY CLEANING" },
  { id: 76, name: "61 LIBRARY" },
  { id: 77, name: "29 LIGHTING SHOP" },
  { id: 78, name: "31 LIQUORS SHOP" },
  { id: 79, name: "77 LOCKSMITH" },
  { id: 80, name: "137 LOGISTICS (SHIPPING/FLIGHT)" },
  { id: 81, name: "59 MAID AGENCY" },
  { id: 82, name: "04 MALAY RESTAURANT" },
  { id: 83, name: "65 MARKET" },
  { id: 84, name: "17 MASJID" },
  { id: 85, name: "08 MIDDLE EAST RESTAURANT" },
  { id: 86, name: "25 MOTORBIKE SHOP / REPAIR" },
  { id: 87, name: "135 MUSEUM" },
  { id: 88, name: "52 MUSIC CD SHOP" },
  { id: 89, name: "84 MUSIC SCHOOL" },
  { id: 90, name: "91 NATIONAL SECURITY" },
  { id: 91, name: "133 NEW CAR DEALER" },
  { id: 92, name: "26 OFFICE" },
  { id: 93, name: "102 OLD FOLK HOME" },
  { id: 94, name: "67 OPTICIAN" },
  { id: 95, name: "41 PAWN SHOP" },
  { id: 96, name: "48 PET SHOP" },
  { id: 97, name: "103 PETROL STATION" },
  { id: 98, name: "66 PHARMACY" },
  { id: 99, name: "51 PHOTO SHOP" },
  { id: 100, name: "63 PLANT NURSERY" },
  { id: 101, name: "70 POST OFFICE" },
  { id: 102, name: "33 POULTRY SHOP" },
  { id: 103, name: "34 PRAYER SHOP" },
  { id: 104, name: "37 PRINTING SHOP" },
  { id: 105, name: "114 PRIVATE CLINIC" },
  { id: 106, name: "73 PRIVATE CLUB" },
  { id: 107, name: "122 PRIVATE COLLEGE" },
  { id: 108, name: "116 PRIVATE HOSPITAL" },
  { id: 109, name: "109 PRIVATE SCHOOL" },
  { id: 110, name: "35 PROVISION SHOP" },
  { id: 111, name: "62 PUBLIC HALL" },
  { id: 112, name: "64 PUBLIC TOILET" },
  { id: 113, name: "119 PUBS / DISCO" },
  { id: 114, name: "121 RECORDING STUDIO" },
  { id: 115, name: "93 REHABILITATION CENTRE" },
  { id: 116, name: "111 RELIGIOUS SCHOOL" },
  { id: 117, name: "108 RESORT" },
  { id: 118, name: "138 SALOON / SPA CENTRE" },
  { id: 119, name: "53 SCRAP SHOP" },
  { id: 120, name: "49 SHOES SHOP" },
  { id: 121, name: "88 SHOWROOM" },
  { id: 122, name: "142 SNACK SHOP" },
  { id: 123, name: "57 SOUVENIR SHOP" },
  { id: 124, name: "141 SPICE SHOP" },
  { id: 125, name: "95 SPORTS CENTRE" },
  { id: 126, name: "75 STORE" },
  { id: 127, name: "32 SUNDRY SHOP" },
  { id: 128, name: "127 SUPERMARKET / MINIMARKET" },
  { id: 129, name: "15 SURAU" },
  { id: 130, name: "02 TAILOR SHOP" },
  { id: 131, name: "16 TEMPLE" },
  { id: 132, name: "43 TEXTILE / GARMENT SHOP" },
  { id: 133, name: "11 THAI RESTAURANT" },
  { id: 134, name: "146 THEME PARK" },
  { id: 135, name: "144 TODLER ITEMS SHOP" },
  { id: 136, name: "56 TOYS SHOP" },
  { id: 137, name: "104 TRANSPORTATION SERVICES" },
  { id: 138, name: "58 TRAVEL AGENCY" },
  { id: 139, name: "97 TUITION CENTRE" },
  { id: 140, name: "113 UNIVERSITY" },
  { id: 141, name: "69 USED CAR DEALER" },
  { id: 142, name: "54 VIDEO SHOP" },
  { id: 143, name: "134 WAREHOUSE" },
  { id: 144, name: "38 WATCH / CLOCK SHOP" },
  { id: 145, name: "07 WESTERN RESTAURANT" },
  { id: 146, name: "86 WHOLESALE OUTLET" },
  { id: 147, name: "19 WORKSHOP" },
];
export const propertyusagetype = [
  { id: 2, name: "Domestic" },
  { id: 3, name: "Commercial" },
  { id: 4, name: "Industrial" },
  { id: 5, name: "Government Quaters" },
  { id: 6, name: "Government Premise" },
];

export const drcodetype = [{id:"DR01 PAYMENT MADE AFTER DCA VISIT",name:"DR01 PAYMENT MADE AFTER DCA VISIT",value:"DR01 PAYMENT MADE AFTER DCA VISIT"},
{id:"DR03 REFUSE TO PAY",name:"DR03 REFUSE TO PAY",value:"DR03 REFUSE TO PAY"},
{id:"DR04 UNABLE TO PAY",name:"DR04 UNABLE TO PAY",value:"DR04 UNABLE TO PAY"},
{id:"DR05 VACANT PREMISE",name:"DR05 VACANT PREMISE",value:"DR05 VACANT PREMISE"},
{id:"DR06 VACANT LAND",name:"DR06 VACANT LAND",value:"DR06 VACANT LAND"},
{id:"DR07 ABANDON",name:"DR07 ABANDON",value:"DR07 ABANDON"},
{id:"DR09 DEMOLISHED",name:"DR09 DEMOLISHED",value:"DR09 DEMOLISHED"},
{id:"DR10 BUILDING BURNT",name:"DR10 BUILDING BURNT",value:"DR10 BUILDING BURNT"},
{id:"DR11 UNDER RENOVATION",name:"DR11 UNDER RENOVATION",value:"DR11 UNDER RENOVATION"},
{id:"DR12 PROP CANNOT BE LOCATED",name:"DR12 PROP CANNOT BE LOCATED",value:"DR12 PROP CANNOT BE LOCATED"},
{id:"DR13 UNDER LIQUIDATION",name:"DR13 UNDER LIQUIDATION",value:"DR13 UNDER LIQUIDATION"},
{id:"DR16 REQUESTED FOR INSTALLMENT PLAN",name:"DR16 REQUESTED FOR INSTALLMENT PLAN",value:"DR16 REQUESTED FOR INSTALLMENT PLAN"},
{id:"DR19 COLLECTED POSTDATED CHEQUES",name:"DR19 COLLECTED POSTDATED CHEQUES",value:"DR19 COLLECTED POSTDATED CHEQUES"},
{id:"DR21 DOMESTIC USAGE (FOR COMMERCIAL ASSIGNMENT ONLY)",name:"DR21 DOMESTIC USAGE (FOR COMMERCIAL ASSIGNMENT ONLY)",value:"DR21 DOMESTIC USAGE (FOR COMMERCIAL ASSIGNMENT ONLY)"},
{id:"DR22 HOUSE IS CLOSED",name:"DR22 HOUSE IS CLOSED",value:"DR22 HOUSE IS CLOSED"},
{id:"DR25 TENANT CLAIM OWNER TO PAY",name:"DR25 TENANT CLAIM OWNER TO PAY",value:"DR25 TENANT CLAIM OWNER TO PAY"},
{id:"DR26 TENANT PAID TO OWNER",name:"DR26 TENANT PAID TO OWNER",value:"DR26 TENANT PAID TO OWNER"},
{id:"DR27 NEW TENANT/SAME OWNER",name:"DR27 NEW TENANT/SAME OWNER",value:"DR27 NEW TENANT/SAME OWNER"},
{id:"DR29 STORE, WITHOUT SIGNBOARD",name:"DR29 STORE, WITHOUT SIGNBOARD",value:"DR29 STORE, WITHOUT SIGNBOARD"},
{id:"DR30 BANKRUPT/ PROPERTY UNDER COURT CASE",name:"DR30 BANKRUPT/ PROPERTY UNDER COURT CASE",value:"DR30 BANKRUPT/ PROPERTY UNDER COURT CASE"},
{id:"DR32 NO WATER METER",name:"DR32 NO WATER METER",value:"DR32 NO WATER METER"},
{id:"DR33 UNDER RECEIVERSHIP",name:"DR33 UNDER RECEIVERSHIP",value:"DR33 UNDER RECEIVERSHIP"},
{id:"DR35 OWNER CLAIM TENANT TO PAY",name:"DR35 OWNER CLAIM TENANT TO PAY",value:"DR35 OWNER CLAIM TENANT TO PAY"},
{id:"DR36 COMMERCIAL USAGE (FOR DOMESTIC ASSIGNMENT ONLY)",name:"DR36 COMMERCIAL USAGE (FOR DOMESTIC ASSIGNMENT ONLY)",value:"DR36 COMMERCIAL USAGE (FOR DOMESTIC ASSIGNMENT ONLY)"},
{id:"DR40 MANAGEMENT REFUSE TO COOPERATE",name:"DR40 MANAGEMENT REFUSE TO COOPERATE",value:"DR40 MANAGEMENT REFUSE TO COOPERATE"},
{id:"DR41 NEW OWNER REFUSE TO PAY PREVIOUS OWNER OUTSTANDING",name:"DR41 NEW OWNER REFUSE TO PAY PREVIOUS OWNER OUTSTANDING",value:"DR41 NEW OWNER REFUSE TO PAY PREVIOUS OWNER OUTSTANDING"},
{id:"DR42 CUSTOMER REFUSE TO MEET DCA",name:"DR42 CUSTOMER REFUSE TO MEET DCA",value:"DR42 CUSTOMER REFUSE TO MEET DCA"},
{id:"DR45 POLITICAL PARTIES ADVISE CUSTOMER NOT TO PAY IWK CHARGE",name:"DR45 POLITICAL PARTIES ADVISE CUSTOMER NOT TO PAY IWK CHARGE",value:"DR45 POLITICAL PARTIES ADVISE CUSTOMER NOT TO PAY IWK CHARGE"},
{id:"DR46 NOT RECEIVED BILL",name:"DR46 NOT RECEIVED BILL",value:"DR46 NOT RECEIVED BILL"},
{id:"DR47 CLAIM PAID TO IST TANKER",name:"DR47 CLAIM PAID TO IST TANKER",value:"DR47 CLAIM PAID TO IST TANKER"},
{id:"DR48 PREMISE OCCUPY BY FOREIGNER-COMMUNICATION ISSUES",name:"DR48 PREMISE OCCUPY BY FOREIGNER-COMMUNICATION ISSUES",value:"DR48 PREMISE OCCUPY BY FOREIGNER-COMMUNICATION ISSUES"},
{id:"DR50 WELFARE SOCIETY-CANT AFFORD TO PAY",name:"DR50 WELFARE SOCIETY-CANT AFFORD TO PAY",value:"DR50 WELFARE SOCIETY-CANT AFFORD TO PAY"},
{id:"DR52 DROP",name:"DR52 DROP",value:"DR52 DROP"},
{id:"DR53 PROPERTY SOLD & CURRENT NAME UNDER OLD OWNER",name:"DR53 PROPERTY SOLD & CURRENT NAME UNDER OLD OWNER",value:"DR53 PROPERTY SOLD & CURRENT NAME UNDER OLD OWNER"},
{id:"DR54 PROPERTY UNDER GOVERNMENT",name:"DR54 PROPERTY UNDER GOVERNMENT",value:"DR54 PROPERTY UNDER GOVERNMENT"},]
export const occupiertype = [
  { id: "Owner", name: "Owner" },
  { id: "Tenant", name: "Tenant" },
  { id: "Vacant", name: "Vacant" },
  { id: "Closed", name: "Closed" },
  { id: "Others", name: "Others" },
];
export var categoryType = [
  {
    id: "lnds",
    name: "Landed",
    value: "lnds",
  },
  {
    id: "commercials",
    name: "Commercials",
    value: "commercials",
  },
  {
    id: "highrises",
    name: "Highrises",
    value: "highrises",
  },
];
export const categoryTypeAll = [
  {
    id:"",
    name:"Select Category",
    value:"",
  },
  {
    id: "lnds",
    name: "Landed",
    value: "lnds",
  },
  {
    id: "Vacant",
    name: "Vacant",
    value: "vacants",
  },
  {
    id: "commercials",
    name: "Commercials",
    value: "commercials",
  },
  {
    id: "highrises",
    name: "Highrises",
    value: "highrises",
  },
];
export const UpdateType = [
  {
    id: "2",
    name: "All",
    value: "2",
  },
  {
    id: "Update",
    name: "Update",
    value: "1",
  },
  {
    id: "Not Update",
    name: "Not Update",
    value: "0",
  },
];
export const CallStatusCM = [
  {
    id: "All",
    name: "All",
    value: "All",
  },
  {
    id: "New",
    name: "New",
    value: "New",
  },
  {
    id: "PAYMENT MADE AFTER DCA CALL",
    name: "PAYMENT MADE AFTER DCA CALL",
    value: "PAYMENT MADE AFTER DCA CALL",
  },
  {
    id: "PROMISED TO PAY (PTP)",
    name: "PROMISED TO PAY (PTP)",
    value: "PROMISED TO PAY (PTP)",
  },
  {
    id: "REFUSE TO PAY (RTP)",
    name: "REFUSE TO PAY (RTP)",
    value: "REFUSE TO PAY (RTP)",
  },
  {
    id: "UNABLE TO PAY (UTP)",
    name: "UNABLE TO PAY (UTP)",
    value: "UNABLE TO PAY (UTP)",
  },
  {
    id: "FURTHER EXPLANATION & FOLLOW UP REQUIRED",
    name: "FURTHER EXPLANATION & FOLLOW UP REQUIRED",
    value: "FURTHER EXPLANATION & FOLLOW UP REQUIRED",
  },
  {
    id: "REQUESTED FOR INSTALLMENT PLAN",
    name: "REQUESTED FOR INSTALLMENT PLAN",
    value: "REQUESTED FOR INSTALLMENT PLAN",
  },
  {
    id: "REQUESTED FOR STATEMENT OF ACCOUNT (SOA)",
    name: "REQUESTED FOR STATEMENT OF ACCOUNT (SOA)",
    value: "REQUESTED FOR STATEMENT OF ACCOUNT (SOA)",
  },
  {
    id: "REQUESTED FOR COPY BILL",
    value: "REQUESTED FOR COPY BILL",
    name: "REQUESTED FOR COPY BILL",
  },
  {
    id: "LEAVE MESSAGE TO CALL",
    name: "LEAVE MESSAGE TO CALL",
    value: "LEAVE MESSAGE TO CALL",
  },
  {
    id: "TENANT CLAIM OWNER TO PAY",
    value: "TENANT CLAIM OWNER TO PAY",
    name: "TENANT CLAIM OWNER TO PAY",
  },
  {
    id: "TENANT PAID TO OWNER",
    name: "TENANT PAID TO OWNER",
    value: "TENANT PAID TO OWNER",
  },
  {
    id: "NEW TENANT/SAME OWNER",
    value: "NEW TENANT/SAME OWNER",
    name: "NEW TENANT/SAME OWNER",
  },
  {
    id: "PAYMENT IS OUSTANDING BUT CLAIM PAID",
    name: "PAYMENT IS OUSTANDING BUT CLAIM PAID",
    value: "PAYMENT IS OUSTANDING BUT CLAIM PAID",
  },
  {
    id: "Parital Paid",
    value: "Parital Paid",
    name: "Parital Paid",
  },
  {
    id: "OTHERS",
    name: "OTHERS",
    value: "OTHERS",
  },
  {
    id: "Revisit",
    value: "Revisit",
    name: "Revisit",
  },
];
export const categoryTypeForJobSheet = [
  {
    id: "lnds",
    name: "Landed",
    value: "lnds",
  },
  {
    id: "commercials",
    name: "Commercials",
    value: "commercials",
  },
  {
    id: "highrises",
    name: "Highrises",
    value: "highrises",
  },
];
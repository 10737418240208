import Layout from "../../../components/layout/Navbar";
import { makeStyles } from "@material-ui/core";
import { createStyles } from "@material-ui/core";
import React from "react";
import JobTable from "./table1";
import AssignmentTable from "./assignTable";
const useStyles = makeStyles(
  (theme) =>
    createStyles({
      Main: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        margin: "10rem 0 0 0 ",
        fontSize: "2rem",
        fontWeight: 500,
        [theme.breakpoints.down("sm")]: {
          fontSize: "1.5rem",
        },
      },
      cardmain: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "300px",
        padding: "20px",
        color: "#fff",
        background: "red",
        flexDirection: "column",
        borderRadius: "1rem",
      },
      cardHeading: {
        fontSize: "1.5rem",
      },
      cardNumber: {},
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
      },
      MainCardDiv: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        maxWidth: "1200px",
      },
      MainDiv: {
        display: "flex",
        maxWidth: "1200px",
        flexDirection: "column",
      },
      MainHeading: {
        fontSize: "24px",
        fontWeight: "700",
      },
    }),
  { withTheme: true }
);

function Payment() {
  const classes = useStyles();
  return (
    <Layout title="Dashboard">
      <div>
        <div className={classes.MainHeading}>Payment Report</div>
      </div>
      <br />
      <br />
      <div className={classes.MainDiv}>
        <JobTable />
        <br />
        <br />
        <AssignmentTable />
      </div>
    </Layout>
  );
}
// const ProtectedPayment = withPermission(Payment, "payment");
export default Payment;

import { makeStyles } from "@material-ui/core";
import { createStyles } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import Image from "../../assets/logo/notfound.svg";
import Layout from "../../components/layout/Navbar";
const useStyles = makeStyles(
  (theme) =>
    createStyles({
      Main: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        margin: "10rem 0 0 0 ",
        fontWeight: 500,
        maxWidth: "1200px",
        margin: "0 auto",
        marginTop: "150px",
        [theme.breakpoints.down("sm")]: {
          // backgroundColor: "red",
        },
      },
      MainSide: {
        width: "50%",
      },
      MainSidecontent: {
        color: "#5016BF",
        fontWeight: "700",
        fontSize: "3rem",
      },
      MainSidecontentPara: {
        fontSize: "1.5rem",
        color: "#AFAFAF",
        marginTop: "40px",
      },
      MainSidecontentBtn: {
        background: "#5016BF",
        borderRadius: "44px",
        color: "#fff",
        width: "250px",

        padding: "10px",
        textAlign: "center",
        marginTop: "25px",
        fontWeight: 600,
      },
      MainSidecontentBtnD: {
        background: "#FFF",
        borderRadius: "44px",
        color: "#5016BF",
        width: "250px",
        border: "2px solid #5016BF",
        padding: "10px",
        textAlign: "center",
        marginTop: "25px",
        fontWeight: 600,
      },
      btnStyD: {
        color: "#5016BF",
        textDecoration: "none",
        "&:hover": {
          color: "#5016BF !important",
        },
      },
      btnSty: {
        color: "#fff",
        textDecoration: "none",
        "&:hover": {
          color: "#fff !important",
        },
      },
    }),
  { withTheme: true }
);
// page not found function adding
function PageNotFoundLayout() {
  const classes = useStyles();
  return (
    <Layout>
    <div className={classes.Main}>
      <br />
      <br />
      <br />
      <div className={classes.MainSide}>
        <div className={classes.MainSidecontent}>page not found</div>
        <div className={classes.MainSidecontentPara}>
          I'm afraid you've found a page that doesn't exist on APAN Collections.
          That can happen when you follow a link to something that has since
          been deleted. Or the link was incorrect to begin with. Sorry about
          that. We've logged the error for review, in case it's our fault.
          {/* Contact Support */}
        </div>
        {/* <div className={classes.MainSidecontentBtn}>
          <Link to="/" className={classes.btnSty}>
            Go to the homepage
          </Link>
        </div>
        <div className={classes.MainSidecontentBtnD}>
          <Link to="/" className={classes.btnStyD}>
            Contact Support
          </Link>
        </div> */}
      </div>
      <div className={classes.MainSide}>
        <img style={{ width: "100%" }} src={Image} alt="404 " />
      </div>
    </div>
    </Layout>
  );
}
export default PageNotFoundLayout;

import React, { useState, useEffect } from "react";
import axios from "../../axios";
import "./AddRider.css";
import CloseIcon from "@mui/icons-material/Close";

import FileUploader from "../../components/buttons/FileUploader";
import { useToasts } from "react-toast-notifications";
import { Avatar } from "@mui/material";

function RiderPassword({ editPassword, closeModel }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();
  const [UserPic, setUserPic] = useState("");
  const [newPassword, setNewpassword] = React.useState("");
  const [confirmPassword, setConfirmpassword] = React.useState("");
  const [error, setError] = React.useState("");

  const handleUpdateApi = (e) => {
    if (newPassword == "" || confirmPassword == "") {
      setError("Fill all the fields");
    } else if (newPassword === confirmPassword) {
      // passwords match, do something
      e.preventDefault();
      setError("");

      const sendData = {
        password: confirmPassword,
      };

      axios
        .put("/rider/" + editPassword.id, sendData)
        .then((result) => {
          addToast("Password Successfully Edited", {
            appearance: "success",
            // autoDismiss: "true",
            // autoDismissTimeout: 2000,
          });
          setTimeout(() => {
            window.location.href = "/rider";
          }, 1000);
        })
        .catch((error) => {
          addToast("Something Went Wrong", {
            appearance: "error",
            autoDismiss: "true",
            autoDismissTimeout: 2000,
          });
        });
      setNewpassword("");
      setConfirmpassword("");
    } else {
      setError("Passwords do not match!");
    }
  };

  return (
    <div className="card-background" style={{ height: "70vh" }}>
      <div className="Addrider-card">
        <div className="card-head" style={{ display: "block" }}>
          <div className="icon-div" onClick={() => closeModel(false)}>
            <CloseIcon style={{ color: "#501ABF" }} />
          </div>
          <h1>Edit Password</h1>
        </div>

        <div className="frm" style={{ marginTop: "40px" }}>
          <input
            autoComplete="chrome-off"
            type="text"
            className="input"
            placeholder="New Password"
            value={newPassword}
            required
            onChange={(e) => setNewpassword(e.target.value)}
          />
          <input
            autoComplete="chrome-off"
            type="text"
            className="input"
            placeholder="Confirm Password"
            value={confirmPassword}
            required
            onChange={(e) => setConfirmpassword(e.target.value)}
            style={{ marginTop: "40px" }}
          />
          {error && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                maxWidth: "750px",
                color: "red",
              }}
            >
              {error}
            </div>
          )}
          <button className="login-submit" onClick={handleUpdateApi}>
            Update
          </button>
        </div>
      </div>
    </div>
  );
}

export default RiderPassword;

import React, { useState, useEffect } from "react";
import Layout from "../../components/layout/Navbar";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import humanSymbol from "../../assets/human1.png";
import axios from "axios";
import "./Eagleviews.css";

function Eagleview(props) {
  const [riderData, setRiderData] = useState([]);
  const [loading, setLoading] = useState(true);

  const humanSymbolIcon = {
    url: humanSymbol,
    scaledSize: new props.google.maps.Size(40, 40),
    labelOrigin: new props.google.maps.Point(0, -10),
  };

  const Auth = localStorage.getItem("userInfor");
  const message = JSON.parse(Auth);
  const AdminId = message.message.id;

  useEffect(() => {
    const fetchRiderData = async () => {
      try {
        const response = await axios.get(
          `https://api.s5.apancollections.com/findAllRiders/${AdminId}`
        );
        const data = response.data;
        setRiderData(data);
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    };
    fetchRiderData();
  }, []);

  const onMarkerClick = (marker, rider) => {
    const infoWindow = new props.google.maps.InfoWindow({
      content: `
      <div style="display: flex; flex-direction: column; align-items: center; justify-content: center; text-align: center; color:rgb(49, 0, 128);">
        <p style="margin: 0; font-weight: bold;">${rider.fullName}</p>
        <p style="margin: 0;">${rider.updatedAt} (GMT +8)</p>
      </div>
      `,
    });
    infoWindow.setPosition(marker.getPosition());
    infoWindow.open(props.map, marker);
  };

  const filterRiderData = riderData
    .filter((rider) => rider.latitude !== null && rider.longitude !== null)
    .map((rider) => {
      const updatedAt = new Date(rider.updatedAt);
      updatedAt.setMinutes(updatedAt.getMinutes() + 150);
      const formattedDate = `${updatedAt.getFullYear()}-${(
        updatedAt.getMonth() + 1
      )
        .toString() 
        .padStart(2, "0")}-${updatedAt
        .getDate()
        .toString()
        .padStart(2, "0")} ${updatedAt.toLocaleTimeString()}`;
      return { ...rider, updatedAt: formattedDate };
    });

  return (
    <Layout>
      <div className="map-container">
        {!loading && (
          <Map
            google={props.google}
            zoom={17}
            initialCenter={{
              lat: riderData[0]?.latitude,
              lng: riderData[0]?.longitude,
            }}
          >
            {filterRiderData.map((rider) => (
              <Marker
                key={rider.id}
                position={{ lat: rider.latitude, lng: rider.longitude }}
                icon={humanSymbolIcon}
                onClick={(props, marker) => onMarkerClick(marker, rider)}
              />
            ))}
          </Map>
        )}
      </div>
    </Layout>
  );
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyCf2_-AdQ8qXsA8bjUg3fYS9ehRu48TdR8",
  language: "en",
})(Eagleview);

import Login from "../pages/login/login";
import Dashboard from "../pages/dashboard/dashboard";
import Calendar from "../pages/calender/calendar";
import Images from "../pages/images/images";
import Export from "../pages/exportData/exportData";
import Test from "../pages/exportData/test";
import CallManager from "../pages/callManager/callmanager";
import ExtendSearch from "../pages/extendSearch/index";
import ImportManager from "../pages/importManager/importManger";
import ImportManagerTable from "../pages/importManager/importTable";

import ProductManager from "../pages/importManager/product Data/productManger";
import ProductManagerTable from "../pages/importManager/product Data/productTable";

import AssignData from "../pages/importManager/assignData/assignData";
import AssignedData from "../pages/importManager/assignedTable/assignedTable";
import Rider from "../pages/Riders/rider";
import Telecaller from "../pages/Telecaller/telecaller";
// import Setup from "../pages/Setup/PolicyS";
import SecuirtyP from "../pages/Setup/PolicyS";
import DataP from "../pages/Setup/PolicyD";
import NewP from "../pages/Setup/fristPolicy";
// import UserProfile from "../pages/userProfile/userprofile";
import SetupNew from "../pages/setupNew/comapanySetup/comapanySetupApi";
import SetupPolicy from "../pages/setupNew/policy/policyApi";
import Payment from "../pages/dashboard/payment/payment";
import PageNotFound from "../pages/pageNotFound/pageNotFound";
import ReportManager from "../pages/dashboard/reportManager/reportManager";
import WaterMark from "../pages/watermark/watermark";
import Sample from "../pages/dashboard/sample/sample"
import JobSheet from "../pages/Jobsheet/jobsheet";
import JobGenerator from "../pages/Jobsheet/Generator";
import JobSheetGenerator from "../pages/Jobsheet/jobSheetGenerotor";
import JobSheetExcel from "../pages/Jobsheet/jobSheetExcel";
import Highrise from "../pages/Jobsheet/highrise";
import Commercial from "../pages/Jobsheet/commercial";
import HighriseGenerator from "../pages/Jobsheet/highriseGenerator";
import CommercialGenerator from "../pages/Jobsheet/commercialGenerator";
import ChangePassword from "../pages/ChangePassword/changepassword";
import Eagleview from "../pages/EaglesView/Eagleview";
import PageNotFoundLayout from "../pages/pageNotFound/pageNotFound";
import Roles from "../pages/Riders/roles";
import EmployeeLogin from "../pages/login/employee";
// SetupPolicy

const permissionData = JSON.stringify(localStorage.getItem('permission'));
// console.log(permissionData)

export const publicRoutes = [
  {
    path: "/water-mark",
    component: WaterMark,
    exact: true,
  },
  {
    path: "/reportmanager",
    component: permissionData?.includes("reportManager") ? ReportManager : PageNotFoundLayout,
    exact: true,
  },
  {
    path: "/",
    component: Dashboard,
    exact: true,
  },
  {
    path: "/productivity",
    component: permissionData?.includes("productivity") ? Dashboard : PageNotFoundLayout,
    exact: true,
  },
  {
    path: "/payment",
    component: permissionData?.includes("payment") ? Payment : PageNotFoundLayout,
    exact: true,
  },

  {
    path: "/export-data",
    component: permissionData?.includes("exportManager") ? Export : PageNotFoundLayout,
    exact: true,
  },
  {
    path: "/login",
    component:Login,
    exact: true,
  },
  {
    path: "/call-manager",
    component: permissionData?.includes("dataManager") ? CallManager : PageNotFoundLayout,
    exact: true,
  },
  // // call-manager
  {
    path: "/extend-search",
    component: ExtendSearch,
    exact: true,
  },
  {
    path: "/dashboard",
    component: Dashboard,
    exact: true,
  },
  {
    path: "/calendar",
    component: permissionData?.includes("humanResources") ? Calendar : PageNotFoundLayout,
    exact: true,
  },
  {
    path: "/import-data-table",
    component: permissionData.includes("importManager") ? ImportManagerTable : PageNotFoundLayout,
    exact: true,
  },
  {
    path: "/import-data",
    component: permissionData?.includes("importManager") ? ImportManager : PageNotFoundLayout,
    exact: true,
  },

  // product
  {
    path: "/payment-list-table",
    component: permissionData?.includes("payment") ? ProductManagerTable : PageNotFoundLayout,
    exact: true,
  },
  {
    path: "/payment-list",
    component: permissionData?.includes("payment") ? ProductManager : PageNotFoundLayout,
    exact: true,
  },

  {
    path: "/assign-data",
    component: permissionData?.includes("assignManager") ? AssignData : PageNotFoundLayout,
    exact: true,
  },
  {
    path: "/assigned-data",
    component: permissionData?.includes("assignManager") ? AssignedData : PageNotFoundLayout,
    exact: true,
  },
  {
    path: "/rider",
    component: permissionData?.includes("humanResources") ? Rider : PageNotFoundLayout,
    exact: true,
  },

  {
    path: "/images",
    component: permissionData?.includes("visualManager") ? Images : PageNotFoundLayout,
    exact: true,
  },

  {
    path: "/telecaller",
    component: Telecaller,
    exact: true,
  },
  {
    path: "/setup",
    component: permissionData?.includes("setup") ? SetupNew: PageNotFoundLayout,
    exact: true,
  },
  {
    path: "/setup/company",
    component: permissionData?.includes("setup") ? SetupNew: PageNotFoundLayout,
    exact: true,
  },
  {
    path: "/setup/policy",
    component: permissionData?.includes("setup") ?  SetupPolicy : PageNotFoundLayout,
    exact: true,
  },
  {
    path: "/setup/securitypolicy",
    component: permissionData?.includes("setup") ? SecuirtyP : PageNotFoundLayout,
    exact: true,
  },
  {
    path: "/setup/datapolicy",
    component: permissionData?.includes("setup") ?  DataP : PageNotFoundLayout,
    exact: true,
  },
  {
    path: "/setup/newpolicy",
    component: permissionData?.includes("setup") ?  NewP : PageNotFoundLayout,
    exact: true,
  },
  //job sheet
  {
    path:"/jobSheet",
    component: permissionData?.includes("jobsheet") ? JobSheet : PageNotFoundLayout,
    exact: true
  },
  {
    path:"/jobGenerator",
    component:permissionData?.includes("jobsheet") ? JobGenerator: PageNotFoundLayout,
    exact:true
  },
  {
    path:"/jobSheetGenerator",
    component: permissionData?.includes("jobsheet") ? JobSheetGenerator : PageNotFoundLayout,
    exact:true
  },
  {
    path:"/jobSheetExcel",
    component:permissionData?.includes("jobsheet") ? JobSheetExcel : PageNotFoundLayout,
    exact: true
  },
  {
    path:"/jobHighrise",
    component:permissionData?.includes("jobsheet") ?  Highrise : PageNotFoundLayout,
    exact:true
  },
  {
    path:"/jobCommercial",
    component:permissionData?.includes("jobsheet") ? Commercial : PageNotFoundLayout,
    exact:true
  },
  {
    path:"/highriseGenerator",
    component:permissionData?.includes("jobsheet") ? HighriseGenerator : PageNotFoundLayout,
    exact:true
  },
  {
    path:"/commercialGenerator",
    component:permissionData?.includes("jobsheet") ? CommercialGenerator : PageNotFoundLayout,
    exact:true
  },
  {
    path:"/changepassword",
    component:ChangePassword,
    exact:true
  },
  {
    path:"/eaglesView",
    component: permissionData?.includes("riderManager") ? Eagleview : PageNotFoundLayout,
    exact:true
  },
  {
    path: "*",
    component: PageNotFound,
    exact: true,
  },
  {
    path: "/pageNotFound",
    component:PageNotFoundLayout,
    exact: true
  },
  {
    path:"/roles",
    component: permissionData?.includes("humanResources") ? Roles : PageNotFoundLayout,
    exact: true
  }
];

export const AuthenticationRoutes = [
  {
    path: "/login",
    component:Login,
    exact: true,
  },

  {
    path: "*",
    component: Login,
    exact: true,
  },
  {
    path:"/employeeLogin",
    component:EmployeeLogin,
    exact: true
  },
];
// support@agileadv.com / Apan@2211
import React, { useState, useEffect } from "react";
import { publicRoutes, AuthenticationRoutes } from "./route/route";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  const [IsUser, setIsUser] = useState(localStorage.getItem("auth"));
  useEffect(() => {
    async function checkUser() {
      if (localStorage.getItem("userInfor")) {
        setIsUser(true);
      }
    }
    checkUser();
  }, []);
  const publicRoute = (route, index) => {
    return (
      <Route
        key={index}
        path={route.path}
        element={<route.component />}
        exact={route.exact}
      />
    );
  };
  return (
    <BrowserRouter>
      <Routes>
        {IsUser
          ? publicRoutes.map((route, index) => publicRoute(route, index))
          : AuthenticationRoutes.map((route, index) =>
              publicRoute(route, index)
            )}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
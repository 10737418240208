import React, { useState } from "react";
import axios from "../../axios";
import "./AddRole.css";
import CloseIcon from "@mui/icons-material/Close";
import { useToasts } from "react-toast-notifications";

function AddRiderPopup({ closeModel, edit, editId }) {
  const [fullName, setFullName] = useState(editId ? editId.roles : "");
  const [error, setError] = React.useState("");
  const { addToast } = useToasts();

  const handleName = (e) => {
    setFullName(e.target.value);
  };
  const handleApi = () => {
    const AdminId = JSON.parse(localStorage.getItem("auth")).message.id || "";

    const data = {
      adminId: AdminId,
      roles: fullName,
    };
    if (fullName != "") {
      axios
        .post("employeeRoles", data)
        .then((result) => {
          addToast("Successfully Added", {
            appearance: "success",
            autoDismiss: "true",
            autoDismissTimeout: 2000,
          });
          setTimeout(() => {
            closeModel(false);
            window.location.reload(false);
          }, 2000);
        })
        .catch((error) => {
          // addToast("username or password is incorrcet", {
          //   appearance: "error",
          //   autoDismiss: "true",
          //   autoDismissTimeout: 2000,
          // });
          console.log(error);
        });
    } else {
      setError("Please fill the field");
    }
  };

  const handleUpdateApi = () => {
    const AdminId = JSON.parse(localStorage.getItem("auth")).message.id || "";

    const data = {
      adminId: AdminId,
      roles: fullName,
    };
    if (fullName != "") {
      axios
        .put(`employeeRoles/${editId.id}`, data)
        .then((result) => {
          addToast("Successfully Edited", {
            appearance: "success",
            autoDismiss: "true",
            autoDismissTimeout: 2000,
          });
          setTimeout(() => {
            closeModel(false);
            window.location.reload(false);
          }, 2000);
        })
        .catch((error) => {
          // addToast("username or password is incorrcet", {
          //   appearance: "error",
          //   autoDismiss: "true",
          //   autoDismissTimeout: 2000,
          // });
          console.log(error);
        });
    } else {
      setError("Please fill the field");
    }
  };

  return (
    <div
      className="card-background"
      style={{
        height: "60vh",
        width: "60vw",
        marginTop: "150px",
        marginRight: "150px",
      }}
    >
      <div className="Addrider-card">
        <div className="card-head" style={{ display: "block" }}>
          <div className="icon-div" onClick={() => closeModel(false)}>
            <CloseIcon style={{ color: "#501ABF" }} />
          </div>
          <h1>Add New Role</h1>
        </div>

        <div className="frm" style={{ marginTop: "50px" }}>
          <input
            autoComplete="chrome-off"
            type="text"
            className="input"
            placeholder="Role"
            value={fullName}
            onChange={handleName}
          />
          {error && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                maxWidth: "750px",
                color: "red",
              }}
            >
              {error}
            </div>
          )}
          {!edit && (
            <button className="login-submit" onClick={handleApi}>
              ADD
            </button>
          )}
          {edit && (
            <button className="login-submit" onClick={handleUpdateApi}>
              UPDATE
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default AddRiderPopup;

import { makeStyles } from "@material-ui/core";
import { createStyles } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import { createTheme } from "@mui/material/styles";
import axios from "axios";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import ReactPaginate from "react-paginate";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import "./Generator.css";
import * as XLSX from "xlsx";
import Layout from "../../components/layout/Navbar";
const theme = createTheme();

theme.typography.h3 = {
  typography: {
    fontFamily: ["Poppins"].join(","),
  },

  fontSize: "1.8rem",
  fontWeight: 400,
  fontStyle: "normal",

  "@media (max-width:850px)": {
    fontSize: "1.5rem",
  },
  "@media (max-width:400px)": {
    fontSize: "1rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.8rem",
  },
};
theme.typography.h1 = {
  typography: {
    fontFamily: ["Poppins"].join(","),
  },

  fontSize: "2.5rem",
  color: "#5016BF",
  "@media (max-width:800px)": {
    fontSize: "1.5rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "2.4rem",
  },
};
const useStyles = makeStyles(
  (theme) =>
    createStyles({
      Main: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        margin: "10rem 0 0 0 ",
        fontSize: "2rem",
        fontWeight: 500,
        [theme.breakpoints.down("sm")]: {
          fontSize: "1.5rem",
        },
      },
      cardmain: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "300px",
        padding: "20px",
        color: "#fff",
        background: "red",
        flexDirection: "column",
        borderRadius: "1rem",
      },
      cardHeading: {
        fontSize: "1.5rem",
      },
      cardNumber: {},
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
      },
      MainCardDiv: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        maxWidth: "1200px",
      },
      MainDiv: {
        display: "flex",
        maxWidth: "1200px",
        flexDirection: "column",
      },
      MainHeading: {
        fontSize: "24px",
        fontWeight: "700",
      },
    }),
  { withTheme: true }
);

function JobSheetExcel() {
  const classes = useStyles();

  const [Loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const itemsPerPage = 10;

  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  const Auth = localStorage.getItem("userInfor");
  const message = JSON.parse(Auth);
  const AdminId = message.message.id;
  useEffect(() => {
    if (AdminId) {
      axios
        .get("/dcaGetRiderData/" + AdminId)
        .then((res) => {
          setData(res.data[0]);
          setLoading(true);
        })
        .catch((error) => {
          setLoading(true);
        });
    }
  }, [AdminId]);
  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(data.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, data]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % data.length;
    setItemOffset(newOffset);
  };
  const Hr = () => (
    <div style={{ borderBottom: "2px solid #000", width: "30px" }}></div>
  );
  const handleInputChange = (e) => {
    const { value } = e.target;
  };
  const handleExcel = () => {
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(data);

    var today = new Date();
    var todate = today.toLocaleDateString() + " " + today.toLocaleTimeString();
    var fileName = "Assignment_Report " + todate + ".xlsx";

    XLSX.utils.book_append_sheet(wb, ws, "Assignment_Report");
    XLSX.writeFile(wb, fileName);
  };
  return (
    <Layout>
      <div>
        <div>
          <div className="Heading">Job Sheet Generator</div>
        </div>
        <br />
        <br />
        <div className={classes.MainDiv}>
          <div className={classes.tableMainDiv}>
            <div className={classes.tableDiv}>
              <div className="job-container">
                <button className="generateButton mt-4" onClick={handleExcel}>
                  Generate Job Sheet
                </button>
              </div>
            </div>
            <br />
            <br />
            <ReactPaginate
              breakLabel="..."
              nextLabel="next >"
              onPageChange={handlePageClick}
              pageCount={pageCount}
              previousLabel="< previous"
              renderOnZeroPageCount={null}
              pageRangeDisplayed={1}
              marginPagesDisplayed={1}
              containerClassName={"pagination justify-content-end"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              important
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
}
export default JobSheetExcel;

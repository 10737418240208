import { makeStyles } from "@material-ui/core";
import { createStyles } from "@material-ui/core";
import "./navbar.css";
import React from "react";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      heading: {
        fontSize: "18px",
        color: "#501abf",
        textAlign: "center",
        fontWeight: "600",
      },
      paraDiv: {
        marginTop: "1.5rem",
        maxWidth: "600px",
        width: "100%",
      },
      para: {
        color: "#afafad",
        margin: "0",
      },
      label: {
        paddingLeft: "22px",
        fontSize: "16px",
        color: "#00000A",
        opacity: "0.6",
        fontWeight: 600,
        paddingBottom: "4px",

        [theme.breakpoints.down("lg")]: {
          fontSize: "14px !important",
        },
      },
    }),
  { withTheme: true }
);

function AlertBoxImportManager(props) {
  const { onClose, onClick } = props;
  const [JobType, setJobType] = React.useState("");
  const [error, setError] = React.useState("");
  const classes = useStyles();

  const handleDelete = (JobType) => {
    if(!JobType){
      setError("Fill the field")
    } else{
      onClick(JobType)
    }
  };
  return (
    <div
      className="custom-ui"
      style={{
        background: "#fff",
        padding: "2rem",
        boxShadow: "rgb(130 131 133) 8px 11px 14px 4px",
        borderRadius: "22px",
      }}
    >
      <h1 className={classes.heading}>Are you sure ?</h1>
      <div className={classes.paraDiv}>
        <p className={classes.para}>Are you Sure to delete the Data ?</p>
        <p className={classes.para}>You can't undo this action</p>
      </div>
      <br />
      <div className="input-div mb-4">
        <input
          type="text"
          className="input-div-input"
          placeholder="Enter the Job Name"
          value={JobType}
          required
          onChange={(e) => setJobType(e.target.value)}
        />
        {error && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              maxWidth: "750px",
              color: "red",
            }}
          >
            {error}
          </div>
        )}
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <button
          onClick={onClose}
          className="btn btn-custom-new-curve custom-cancel"
        >
          Cancel
        </button>
        <button
          onClick={() => {
            handleDelete(JobType);
          }}
          className="btn btn-custom-new-curve custom-delete"
          style={{
            borderRadius: "12px !important",
            backgroundColor: "#501abf !important",
          }}
        >
          Delete Data
        </button>
      </div>
    </div>
  );
}
export default AlertBoxImportManager;

import Layout from "../../../components/layout/Navbar";
import { makeStyles } from "@material-ui/core";
import { createStyles } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { createTheme } from "@mui/material/styles";
import axios from "../../../axiosHeader";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import "./reportManager.css";
import Sample from "../sample/sample";
import RangeBill from "../rangeBill/rangeBill";
import AverageBill from "../averageBill/averageBill";
import RiderDailyReport from "../RiderDailyReport/RiderDailayReport";
const theme = createTheme();

theme.typography.h3 = {
  typography: {
    fontFamily: ["Poppins"].join(","),
  },

  fontSize: "1.8rem",
  fontWeight: 400,
  fontStyle: "normal",

  "@media (max-width:850px)": {
    fontSize: "1.5rem",
  },
  "@media (max-width:400px)": {
    fontSize: "1rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.8rem",
  },
};
theme.typography.h1 = {
  typography: {
    fontFamily: ["Poppins"].join(","),
  },

  fontSize: "2.5rem",
  color: "#5016BF",
  "@media (max-width:800px)": {
    fontSize: "1.5rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "2.4rem",
  },
};
const useStyles = makeStyles(
  (theme) =>
    createStyles({
      Main: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        margin: "10rem 0 0 0 ",
        fontSize: "2rem",
        fontWeight: 500,
        [theme.breakpoints.down("sm")]: {
          fontSize: "1.5rem",
        },
      },
      cardmain: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "300px",
        padding: "20px",
        color: "#fff",
        background: "red",
        flexDirection: "column",
        borderRadius: "1rem",
      },
      cardHeading: {
        fontSize: "1.5rem",
      },
      cardNumber: {},
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
      },
      MainCardDiv: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        maxWidth: "1200px",
      },
      MainDiv: {
        display: "flex",
        maxWidth: "1200px",
        flexDirection: "column",
      },
      MainHeading: {
        fontSize: "24px",
        fontWeight: "700",
      },
    }),
  { withTheme: true }
);

function ReportManager() {
  const classes = useStyles();

  const [Loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const itemsPerPage = 10;

  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const [optionSelectedOr, setOptionSelectedOr] = React.useState(null);
  const Auth = localStorage.getItem("userInfor");
  const message = JSON.parse(Auth);
  const AdminId = message.message.id;
  useEffect(() => {
    if (AdminId) {
      axios
        .get("/dcaGetRiderData/" + AdminId)
        .then((res) => {
          setData(res.data[0]);
          setLoading(true);
        })
        .catch((error) => {
          setLoading(true);
        });
    }
  }, [AdminId]);
  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(data.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, data]);

  const handleInputChange = (e) => {
    const { value } = e.target;
    setOptionSelectedOr(value);
  };
  return (
    <Layout title="Dashboard">
      <div>
        <div className={classes.MainHeading}>Report Manager</div>
      </div>
      <br />
      <br />
      <div className={classes.MainDiv}>
        <div className={classes.tableMainDiv}>
          <div className="row">
            <div className="col-md-4">
              <div className={classes.tableMainHeading}>Report Manager</div>
            </div>
            <div className="col-md-4"></div>
            <div className="col-md-4">
              <select
                className="form-select mb-4"
                aria-label="Default select example"
                name=""
                onChange={handleInputChange}
                defaultValue=""
              >
                <option name="" value="" disabled>
                  Select
                </option>
                <option name="LND" value="lnd">
                  Assignment Report
                </option>
                <option name="VACAT" value="vacants">
                  Range Bill Report
                </option>
                <option name="COMMERCIAL" value="commercials">
                  Average Bill Report
                </option>
                <option name="COMMERCIAL" value="rider">
                  Rider Daily Average Report
                </option>
              </select>
            </div>
          </div>
          <br />
          <div className={classes.MySelectDiv}>
            <div className={classes.lnds}>
              {optionSelectedOr === "lnd" && (
                <div>
                  <Sample />
                </div>
              )}

              {optionSelectedOr === "vacants" && (
                <div>
                  <RangeBill />
                </div>
              )}

              {optionSelectedOr === "commercials" && (
                <div>
                  <AverageBill />
                </div>
              )}

              {optionSelectedOr === "rider" && (
                <div>
                  <RiderDailyReport />
                </div>
              )}
            </div>
            <br />
          </div>
        </div>
      </div>
    </Layout>
  );
}
export default ReportManager;

import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css"; // import first
import { useToasts } from "react-toast-notifications";
import axios from "../../../axios";
import "./importManger.css";
import Layout from "../../../components/layout/Navbar";
import moment from "moment";
import { makeStyles } from "@material-ui/core";
import { CircularProgress } from "@mui/material";
import Datapicker from "../../../components/customCore/datapicker";

const useStyles = makeStyles((theme) => ({
  Progress: {
    width: "24px !important",
    height: "24px !important",
  },
  formMain: {
    display: "flex",
    justifyContent: "space-evenly",
    maxWidth: "800px",
    margin: "0 auto",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  label: {
    paddingLeft: "22px",
    fontSize: "16px",
    color: "#00000A",
    opacity: "0.6",
    fontWeight: 600,
    paddingBottom: "4px",
    width: "200px",
  },
  label1: {
    paddingLeft: "22px",
    fontSize: "16px",
    color: "#00000A",
    opacity: "0.6",
    fontWeight: 600,
    paddingBottom: "4px",
    width: "200px",
    marginTop: "25px",
  },
  lnds: {
    maxWidth: "500px",
  },
  btn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "200px !important",
    border: "2px solid #501abf !important",
    outline: "none",
    "&:hover": {
      color: "#501abf !important",

      "& svg": {
        color: "#501abf !important",
      },
      background: "#fff !important",
    },
    "& svg": {
      color: "#fff !important",
    },
  },
}));

export const TypeArray = [
  {
    id: "New",
    name: "New",
  },
  {
    id: "Re Assign",
    name: "Re Assign(Task Completed)",
  },
];
function Dashboard() {
  const { addToast } = useToasts();
  const classes = useStyles();

  //upload file
  const [Date, setDate] = React.useState("");

  const [Form, setForm] = React.useState("");
  const [TypeOfTask, setTypeOfTask] = React.useState("");
  const [To, setTo] = React.useState("");
  const [RiderId, setRiderId] = React.useState("");
  const [RiderData, setRiderData] = useState([]);
  const AdminId = JSON.parse(localStorage.getItem("auth")).message.id || "";
  const [Category, setCategory] = React.useState([]);
  const [UserSelectCategory, setUserSelectCategory] = React.useState("");
  const [UserSelectCategoryCDate, setUserSelectCategoryCDate] =
    React.useState("");
  const [Option, setOption] = useState("");
  const [Disabled, setDisabled] = React.useState(false);

  useEffect(() => {
    axios
      .get("/rider")
      .then((res) => {
        let data = res.data;
        let filterData = data.filter((item) => item.adminId === AdminId) || [];
        setRiderData(filterData);
      })
      .catch((err) => {
        console.log("error");
      });
  }, []);

  useEffect(() => {
    if (Option) {
      setDisabled(true);
      axios
        .get(`/jobname/${Option}/${AdminId}`)
        .then((res) => {
          setDisabled(false);
          setCategory(res.data);
        })
        .catch((err) => {
          setDisabled(false);
          setCategory([]);
        });
    }
  }, [Option, AdminId]);
  let handleSubmit = (event) => {
    setDisabled(true);
    event.preventDefault();

    const jsonData = {
      type: Option,
      dataFrom: Number(Form),
      dataTo: Number(To),
      riderId: RiderId,
      createdDate: "2022-10-14",
      dueDate: "2022-10-15",
      jobId: UserSelectCategory,
      typeOfTaskAssign: TypeOfTask,
      assignedDate: moment(Date).format("YYYY-MM-DD"),
    };

    axios
      // detailIndex
      .post(`/riderdata`, jsonData, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        // Some data already assigned to riders
        addToast(
          res.data.errorMessage === true
            ? "Some data already assigned to riders"
            : "Assign Data Successfully",
          {
            appearance: res.data.errorMessage === true ? "error" : "success",
            autoDismiss: "true",
            autoDismissTimeout: 2000,
          }
        );
        setTimeout(() => {
          window.location.href = "/assigned-data";
        }, 1000);
        setDisabled(false);
      })
      .catch((error) => {
        addToast("please select correct feild", {
          appearance: "error",
          autoDismiss: "true",
          autoDismissTimeout: 2000,
        });
        setDisabled(false);
      });
  };
  const handleStaffChange = (e) => {
    const { value } = e.target;
    setRiderId(value);
  };
  const handleTypeChange = (e) => {
    const { value } = e.target;
    setTypeOfTask(value);
  };
  const handleCategoryChange = (e) => {
    const { value } = e.target;
    const date =
      Category.find((item) => item.id == e.target.value).createdDate || "";
    setUserSelectCategory(value);
    setUserSelectCategoryCDate(moment(date).format("YYYY-MM-DD"));
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    setOption(value);
  };
  return (
    <Layout>
      <div className="newFile2  form">
        <div className="rider-info">Assign Data</div>
        <form onSubmit={handleSubmit}>
          <div className={classes.formMain}>
            <div className="row">
              <label className={classes.label}>Category</label>
              <select
                className="form-select"
                aria-label="Default select example"
                name=""
                onChange={handleInputChange}
                defaultValue=""
              >
                <option name="" value="" disabled>
                  Select
                </option>
                <option name="LND" value="lnds">
                  LANDED
                </option>
                <option name="VACAT" value="vacants">
                  VACANTS
                </option>
                <option name="COMMERCIAL" value="commercials">
                  COMMERCIAL
                </option>
                <option name="HIGHRISES" value="highrises">
                  HIGHRISES
                </option>
              </select>
              <br />

              <label className={classes.label1}>Job Name</label>
              <select
                className="form-select"
                aria-label="Default select example"
                onChange={handleCategoryChange}
                defaultValue=""
              >
                <option name="" value="" disabled>
                  Select
                </option>
                {Category &&
                  Category.length > 0 &&
                  Category.map((item, i) => (
                    <option key={i} name={item.createdAt} value={item.id}>
                      {item.jobName}
                    </option>
                  ))}
              </select>
              <br />
              <br />
              <br />
              <Datapicker
                name="date"
                value={Date}
                label="Date"
                onChange={(e) => setDate(e)}
                required
              />
              <br />
              <label className={classes.label1}>Staff Name</label>

              <select
                className="form-select"
                aria-label="Default select example"
                onChange={handleStaffChange}
                defaultValue=""
              >
                <option name="" value="" disabled>
                  Staff Name
                </option>
                {RiderData &&
                  RiderData.length > 0 &&
                  RiderData.map((item, i) => (
                    <option key={i} name={item.id} value={item.id}>
                      {item.fullName}
                    </option>
                  ))}
              </select>
            </div>
            <br />
            <hr />
            <br />
            <br />

            <div className="container1">
              <label className={classes.label}> Type Of Task Assign</label>

              <select
                className="form-select"
                aria-label="Default select example"
                onChange={handleTypeChange}
                defaultValue=""
              >
                <option name="" value="" disabled>
                  Select{" "}
                </option>
                {TypeArray &&
                  TypeArray.length > 0 &&
                  TypeArray.map((item, i) => (
                    <option key={i} name={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
              </select>
              <br />
              <label className={classes.label}>From</label>

              <div className="input-div">
                <input
                  type="text"
                  className="input-div-input"
                  placeholder="From"
                  value={Form}
                  onChange={(e) => setForm(e.target.value)}
                />
              </div>
              <br />
              <label className={classes.label}>To</label>

              <div className="input-div">
                <input
                  type="text"
                  className="input-div-input"
                  placeholder="To"
                  value={To}
                  onChange={(e) => setTo(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <button
              type="submit"
              className={`submit3  ${classes.btn} `}
              disabled={Disabled}
            >
              {" "}
              {Disabled ? (
                <CircularProgress className={classes.Progress} />
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </form>
      </div>
    </Layout>
  );
}
export default Dashboard;
